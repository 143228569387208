<template>
    <Head title="Sales Taxes" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Sales Taxes</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="sales-taxes" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <div class="flex my-4 items-center">
                        <div class="w-1/2">
                            <div class="my-2 mb-4">
                                <div class="input-group">
                                    <span class="input-group-item">
                                        <icon name="search" class="w-4 h-4 fill-current mr-2" />
                                    </span>

                                    <input type="text" v-model="form.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>
                        <div class="ml-auto my-2 mb-4">
                            <inertia-link :href="$route('tenant-settings.sales-taxes.create')" class="btn btn-orange">
                                <icon name="plus" class="inline text-white fill-current h-4 w-4 mr-2" /> New
                            </inertia-link>
                        </div>
                    </div>

                    <div v-if="salesTaxes.data.length">
                        <div class="my-2">
                            (Showing <span class="font-semibold text-gray-900">{{ salesTaxes.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ salesTaxes.total }}</span>)
                        </div>

                      <div class="overflow-x-auto">
                        <table class="table table-condensed ">
                          <thead>
                          <tr>
                            <sortable-table-header field="name" class="font-semibold" :filters="filters"
                                                   route-name="tenant-settings.sales-taxes.index">Name
                            </sortable-table-header>
                            <sortable-table-header field="default_tax_rate" class="font-semibold" :filters="filters"
                                                   route-name="tenant-settings.sales-taxes.index">Default Tax Rate
                            </sortable-table-header>
                            <sortable-table-header field="gl_code" class="font-semibold" :filters="filters"
                                                   route-name="tenant-settings.sales-taxes.index">GL Account
                            </sortable-table-header>
                            <th class="text-right font-semibold">Rate Overrides</th>
                            <th></th>
                          </tr>
                          </thead>

                          <tbody>
                          <tr v-for="salesTax in salesTaxes.data" :key="salesTax.id">
                            <td>{{ salesTax.name }}</td>
                            <td>{{ salesTax.default_rate }}%</td>
                            <td>{{ glAccountNames[salesTax.gl_code] || '-' }}</td>
                            <td class="text-right">{{ salesTax.rateOverrides.length }}</td>
                            <td class="text-right">
                                        <span class="inline-block">
                                            <inertia-link
                                                :href="$route('tenant-settings.sales-taxes.edit', [salesTax.id])"
                                                class="link text-lg" title="Edit Sales Tax">
                                                <icon name="edit" class="fill-current w-5 h-5"/>
                                            </inertia-link>
                                        </span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                        <pagination :links="salesTaxes.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="receipt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Sales Taxes Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
    import {throttle, pickBy} from 'lodash-es';
    import { router } from '@inertiajs/vue3';
    import { ref, reactive, inject, watch, onMounted } from 'vue';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SubNav from '../SubNav.vue';

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        salesTaxes: {
            type: Object,
            required: true
        },

        glAccountNames: {
            type: Object,
            required: true
        },

        filters: Object
    })

    // State
    const form = reactive({search: props.filters.search})
    const mounted = ref(false)

    // Mount
    onMounted(() => {
        mounted.value = true
    })

    // Watch
    watch(form, throttle(function() {
        let query = pickBy(form);
        query = Object.keys(query).length ? query : {remember: 'forget'};

        router.get(route('tenant-settings.sales-taxes.index'), query, {preserveState: true});
    }, 150), {deep: true})
</script>