<template>
    <Head title="Location Fees" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            Location Fees
        </nav>
    </Teleport>

    <form @submit.prevent id="location-fees-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4">
        <div class="col-span-6 md:col-span-3">
            <label for="search" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search" id="search" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 sm:col-span-2 lg:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('location-fees.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="locationFees.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ locationFees.from }} - {{ locationFees.to }} out of {{ locationFees.total }} Records</div>
        </div>

        <div>
            <div class="w-full overflow-x-auto">
                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Id</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Billing Style')" field="location_fees.billing_style" :filters="filters" route-name="location-fees.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Billing Style</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Header Label')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Header Label</th>
                            <th v-show="form.selected_headers.includes('Label')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Label</th>
                            <th v-show="form.selected_headers.includes('Start Date')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Start Date</th>
                            <th v-show="form.selected_headers.includes('End Date')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">End Date</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Frequency')" field="location_fees.frequency" :filters="filters" route-name="location-fees.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Frequency</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Amount')" scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Amount</th>
                            <th scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="locationFee in locationFees.data" :key="locationFee.id">
                            <td>
                                <inertia-link :href="$route('location-fees.edit', locationFee.id)" class="link" target="_blank">{{ locationFee.id }}</inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Billing Style')">
                                {{ locationFee.billing_style }}
                            </td>
                            <td v-show="form.selected_headers.includes('Header Label')">{{ locationFee.header_label }}</td>
                            <td v-show="form.selected_headers.includes('Label')">{{ locationFee.label }}</td>
                            <td v-show="form.selected_headers.includes('Start Date')">{{ locationFee.start_date }}</td>
                            <td v-show="form.selected_headers.includes('End Date')">
                                <span v-if="locationFee.end_date">{{ locationFee.end_date }}</span>
                                <span v-else class="font-400">Indefinite</span>
                            </td>
                            <td v-show="form.selected_headers.includes('Frequency')">
                                {{ locationFee.frequency }}
                            </td>
                            <td v-show="form.selected_headers.includes('Amount')" class="text-right">
                                {{ locationFee.billing_style === 'Fixed' ? $filters.format_money(locationFee.amount) : 'N/A' }}
                            </td>
                            <td class="text-right">
                                <div>
                                    <inertia-link :href="$route('location-fees.edit', locationFee.id)" class="link" title="Edit This Record">
                                        Edit
                                    </inertia-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="locationFees.links" />
        </div>

        <div v-if="!locationFees.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="file-signature" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Location Fees Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="location-fees-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.billing_styles" multiple>
                        <ComboboxLabel class="form-label" for="billing_styles">Billing Styles</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="billing_styles" name="billing_styles" class="form-input" @change="billingStyleComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="billingStyles.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="billingStyle in filteredBillingStyleOptions" :key="billingStyle.key" :value="billingStyle.key" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ billingStyle.text }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.billing_frequencies" multiple>
                        <ComboboxLabel for="types" class="form-label">Select a Billing Frequency</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput
                                name="frequencies"
                                id="frequencies"
                                class="form-input"
                                @change="billingFrequencyComboBoxQuery = $event.target.value"
                                :display-value="comboDisplayValue"
                            />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredBillingFrequencyOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredBillingFrequencyOptions" :key="option.key" :value="option.key" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ option.text }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

            </form>
        </template>
    </slide-over>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SlideOver from '@/Shared/SlideOver.vue';
    import {has_search_filters} from '@/Shared/Utils/Filters.js';
    import {throttle} from 'lodash-es';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
            Icon,
            LoadingButton,
            Pagination,
            SortableTableHeader,
            SearchInput,
            SelectInput,
            SlideOver,
            ColumnSelector,

            // Tailwind UI combobox.
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            locationFees: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },

            billingStyles: {
                type: Array,
                required : true
            },

            billingFrequencies: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    billing_styles: this.billingStyles.filter(billingStyle => this.filters.billing_styles.includes(billingStyle)),
                    billing_frequencies: this.billingFrequencies.filter(billingFrequency => this.filters.billing_frequencies.includes(billingFrequency)),
                    selected_headers: this.filters.selected_headers,
                },
                state: 'passive',
                billingStyleComboBoxQuery: '',
                billingFrequencyComboBoxQuery: '',
                headers: [],
                excludedHeaders: ['Id','Actions'],
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected'; 
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
                console.log(headers);
                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('location-fees.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form}
                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredBillingStyleOptions() {
                return this.billingStyleComboBoxQuery === ''
                    ? this.billingStyles
                    : this.billingStyles.filter((billingStyle) => {
                        return billingStyle.text.toLowerCase().includes(this.billingStyleComboBoxQuery.toLowerCase());
                    });
            },

            filteredBillingFrequencyOptions() {
                return this.billingFrequencyComboBoxQuery === ''
                ? this.billingFrequencies
                : this.billingFrequencies.filter((frequency) => {
                    return frequency.toLowerCase().includes(this.billingFrequencyComboBoxQuery.toLowerCase());
                });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.billing_styles,
                    form.billing_frequencies,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>

<style scoped>
    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }
    @keyframes search-animation {
        0%   { transform: translateY(0); }
        30%  { transform: translateY(-10px); }
        50%  { transform: translateY(0) translateX(5px); }
        100% { transform: translateY(0) translateX(0); }
    }
</style>