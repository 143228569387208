<template>
    <Head :title="`Edit Vendor Pricing Change - ${props.serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('services.show', props.serviceActivity.service_id)" class="breadcrumb-link">{{ props.serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Pricing Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
    </div>

    <div class="container mx-auto mt-8 mb-12">
        <div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <select-input :disabled="true" v-model="form.change_requester" :errors="errors['change_requester']" class="col-span-2 md:col-span-1" label="Who requested this change?" mark-as-required>
                    <option value="vendor">{{ props.serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                </select-input>

                <revert-input v-model="form.actual_date" class="col-span-3 md:col-span-1" label="When is this change effective?" mark-as-required>
                    <date-input :min="props.serviceCreationActivity.actual_date" v-model="form.actual_date" :errors="errors['actual_date']" />
                </revert-input>

                <revert-input v-model="form.change_reason" class="col-span-3 md:col-span-1" label="Why are you making this change?" mark-as-required>
                    <select-input v-model="form.change_reason">
                        <option>Annual Price Increase</option>
                        <option>Other</option>
                        <option>Savings</option>
                    </select-input>
                </revert-input>
            </div>

            <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
                <revert-input class="col-span-2 md:col-span-1" label="Vendor Base Charge" v-if="serviceHasVendorBaseCharge" v-model="form.vendor_base_charge">
                    <money-input v-model="form.vendor_base_charge" :defaultCurrency="currency" placeholder="Base Charge" :errors="errors['vendor_base_charge']" />
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Vendor Per Unit Charge" v-if="serviceHasVendorPerUnitCharge" v-model="form.vendor_per_unit_charge">
                    <money-input v-model="form.vendor_per_unit_charge" :defaultCurrency="currency" placeholder="Per Unit Charge" :errors="errors['vendor_per_unit_charge']" />
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Vendor Disposal Amount Per Ton" v-if="serviceHasVendorDisposalAmountPerTon" v-model="form.vendor_disposal_amount_per_ton">
                    <money-input v-model="form.vendor_disposal_amount_per_ton" :defaultCurrency="currency" placeholder="Disposal Amount Per Ton" :errors="errors['vendor_disposal_amount_per_ton']" />
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Vendor Per Haul Amount" v-if="serviceHasVendorPerHaulAmount" v-model="form.vendor_per_haul_amount">
                    <money-input v-model="form.vendor_per_haul_amount" :defaultCurrency="currency" placeholder="Per Haul Amount" :errors="errors['vendor_per_haul_amount']" />
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Tons Included Per Haul" v-if="serviceHasTonsIncludedPerHaul" v-model="form.tons_included_per_haul" mark-as-required>
                    <text-input  v-model="form.tons_included_per_haul" type="number" min="0" :errors="errors['tons_included_per_haul']" />
                </revert-input>
            </div>

            <revert-input v-model="form.internal_change_notes" class="col-span-2" label="Internal Change Notes">
                <textarea-input v-model="form.internal_change_notes" />
            </revert-input>

            <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">Submit Change</loading-button>
        </div>
    </div>
</template>

<script setup>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { format, startOfToday } from "date-fns";
    import TextInput from "@/Shared/TextInput.vue";
    import { Head, router } from '@inertiajs/vue3';
    import { inject, computed, onMounted, ref } from "vue";

    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        serviceActivity: {
            type: Object,
            required: true
        },

        serviceCreationActivity: {
            type: Object,
            required: true
        },
    })

    const route = inject('route')

    const form = ref({
        actual_date:                    props.serviceActivity.actual_date,
        change_reason:                  props.serviceActivity.reason,
        change_requester:               props.serviceActivity.change_requester,
        internal_change_notes:          props.serviceActivity.internal_change_notes,
        vendor_base_charge:             props.serviceActivity.serviceSnapshot.vendor_base_charge ?? null,
        vendor_per_unit_charge:         props.serviceActivity.serviceSnapshot.vendor_per_unit_charge ?? null,
        vendor_per_haul_amount:         props.serviceActivity.serviceSnapshot.vendor_per_haul_amount ?? null,
        vendor_disposal_amount_per_ton: props.serviceActivity.serviceSnapshot.vendor_disposal_amount_per_ton ?? null,
        tons_included_per_haul:         props.serviceActivity.serviceSnapshot.tons_included_per_haul ?? null,
    })

    const saving = ref(false);
    const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const mounted = ref(false);
    const currency = ref(props.serviceActivity.serviceSnapshot.vendor.currency);

    onMounted(() => {
        mounted.value = true;
    });

    function submitChange() {
        saving.value = true;

        // should we schedule this or fire it off immediately?
        // we can call different routes, or we could just handle in the controller... not sure which is easiest
        router.put(route('services.vendor-pricing-changes.update', [props.serviceActivity.service_id, props.serviceActivity.id]), {
            ...form.value,
        }, {
            onFinish: () => saving.value = false
        });
    }

    function deleteServiceChange() {
        if (window.confirm('Are you sure you want to delete this vendor pricing change?')) {
            router.delete(route('services.vendor-pricing-changes.destroy', {serviceId: props.serviceActivity.service_id, serviceActivityId: props.serviceActivity.id}));
        }
    }

    const serviceHasVendorBaseCharge = computed(() => {
        return ['Commercial', 'Device', 'Doorstep', 'Rental'].includes(props.serviceActivity.serviceSnapshot.type);
    });

    const serviceHasVendorPerUnitCharge = computed(() => {
        return ['Doorstep'].includes(props.serviceActivity.serviceSnapshot.type);
    });

    const serviceHasVendorPerHaulAmount = computed(() => {
        return ['Rolloff'].includes(props.serviceActivity.serviceSnapshot.type);
    });

    const serviceHasVendorDisposalAmountPerTon = computed(() => {
        return ['Rolloff'].includes(props.serviceActivity.serviceSnapshot.type);
    });

    const serviceHasTonsIncludedPerHaul = computed(() => {
        return ['Rolloff'].includes(props.serviceActivity.serviceSnapshot.type);
    });
</script>
