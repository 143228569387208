<template>
    <div>
        <div v-if="who === 'broker'" class="my-8"><b>You</b> are entering a <b>pickup days change</b>. Please fill in the details.</div>
        <div v-else class="my-8">The <b>{{ who }}</b> is requesting a <b>pickup days change</b>. Please fill in the details.</div>

        <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
            <date-input class="col-span-2 md:col-span-1" :min="earliestServiceActivity.actual_date" v-model="actual_date" label="When should this change happen?" :errors="errors['actual_date']" mark-as-required />
            
            <div class="col-span-2 md:col-span-1" >
                <p v-if="actual_date && actual_date < today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).</p>
                <p v-if="actual_date && actual_date > today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.</p>
            </div>

            <select-input class="col-span-2 md:col-span-1" label="Why are you making this change?" v-model="change_reason" mark-as-required>
                <option>Other</option>
            </select-input>

            <div />
        </div>

        <div class="col-span-2 md:col-span-1 flex flex-wrap">
            <span class="form-label">
                <span class="text-red-500">*</span>
                Pickup Schedule
            </span>
    
            <div class="m-2">
                {{ rrule_description }} <a href="#" class="" @click.prevent="showUpdatePickupRRuleModal"><icon name="edit" class="inline fill-current w-5 h-6"></icon></a>
            </div>
    
            <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500 text-sm">
                The pickup schedule is invalid
            </div>
        </div>

        <textarea-input class="col-span-2" v-model="internal_change_notes" label="Internal Change Notes" />

        <loading-button class="btn btn-orange mt-2" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">Submit Change</loading-button>

        <!-- Pickup RRule Modal -->
        <modal v-if="service.type === 'Commercial' || service.type === 'Doorstep' || service.type === 'Rolloff'" class="max-w-2xl" ref="updatePickupRRuleModal" tabindex="-1" role="dialog">
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Pickup Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6" />
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Average Pickups Per Week"
                        :start-with-schedule="true"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4 flex">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import DateInput from '@/Shared/DateInput.vue';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import Modal from '@/Shared/Modal.vue';
import RRuleInput from '@/Shared/RRuleInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import {rrulestr} from "rrule";
import {format, startOfToday} from "date-fns";

export default {
    components: {
        DateInput,
        Icon,
        LoadingButton,
        Modal,
        RRuleInput,
        SelectInput,
        TextInput,
        TextareaInput,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            saving: false,
            form: {
                average_pickups_per_week:       this.service.average_pickups_per_week ?? null,
                pickup_rrule:                   this.service.pickup_rrule ?? null,
            },
            actual_date:            format(startOfToday(), 'yyyy-MM-dd'),
            change_reason:          null,
            change_requester:       this.who,
            internal_change_notes:  null,
            today:                  format(startOfToday(), 'yyyy-MM-dd'),
            rrule_description: null,
        }
    },

    methods: {
        submitChange() {
            this.saving = true;

            // should we schedule this or fire it off immediately?
            // we can call different routes or we could just handle in the controller... not sure which is easiest
            this.$inertia.post(this.$route('services.pickup-days-changes.store', [this.service.id]), {
                ...this.form,
                'actual_date': this.actual_date,
                'change_reason': this.change_reason,
                'change_requester': this.who,
                'internal_change_notes': this.internal_change_notes,
            }, {
                onFinish: () => this.saving = false
            });
        },

        showUpdatePickupRRuleModal() {
            this.$refs.updatePickupRRuleModal.show();
        },
    },
}
</script>
