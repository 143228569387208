<template>
    <div>
        <div v-if="who === 'broker'" class="my-8"><b>You</b> are entering a <b>vendor pricing change</b>. Please fill in the details.</div>
        <div v-else class="my-8">The <b>{{ who }}</b> is requesting a <b>vendor pricing change</b>. Please fill in the details.</div>

        <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
            <date-input class="col-span-2 md:col-span-1" :min="earliestServiceActivity.actual_date" v-model="actual_date" label="When should this change happen?" :errors="errors['actual_date']" mark-as-required />
            
            <div class="col-span-2 md:col-span-1">
                <p v-if="actual_date && actual_date < today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).</p>
                <p v-if="actual_date && actual_date > today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.</p>
            </div>

            <select-input class="col-span-2 md:col-span-1" label="Why are you making this change?" v-model="change_reason" mark-as-required>
                <option>Annual Price Increase</option>
                <option>Other</option>
                <option>Savings</option>
            </select-input>

            <money-input v-if="serviceHasVendorBaseCharge" v-model="form.vendor_base_charge" :defaultCurrency="$page.props.currency" class="col-span-2 md:col-span-1" label="Base Charge" placeholder="Base Charge" :errors="errors['client_base_charge']" mark-as-required />

            <money-input v-if="serviceHasVendorPerUnitCharge" v-model="form.vendor_per_unit_charge" :defaultCurrency="$page.props.currency" class="col-span-2 md:col-span-1" label="Per Unit Charge" placeholder="Per Unit Charge" :errors="errors['vendor_per_unit_charge']" mark-as-required />

            <money-input v-if="serviceHasVendorPerHaulAmount" v-model="form.vendor_per_haul_amount" :defaultCurrency="$page.props.currency" class="col-span-2 md:col-span-1" label="Per Haul Amount" placeholder="Per Haul Amount" :errors="errors['vendor_per_haul_amount']" mark-as-required />

            <money-input v-if="serviceHasVendorDisposalAmountPerTon" v-model="form.vendor_disposal_amount_per_ton" :defaultCurrency="$page.props.currency" class="col-span-2 md:col-span-1" label="Disposal Amount Per Ton" placeholder="Disposal Amount Per Ton" :errors="errors['vendor_disposal_amount_per_ton']" mark-as-required />

            <text-input v-if="serviceHasTonsIncludedPerHaul" type="number" v-model="form.tons_included_per_haul" class="col-span-2 md:col-span-1" label="Tons Included Per Haul" :errors="errors['tons_included_per_haul']" />

            <textarea-input class="col-span-2" v-model="internal_change_notes" label="Internal Change Notes" />
        </div>

        <loading-button class="btn btn-orange mt-2" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">Submit Change</loading-button>
    </div>
</template>

<script>
import DateInput from '@/Shared/DateInput.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import Modal from '@/Shared/Modal.vue';
import MoneyInput from '@/Shared/MoneyInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import {format, startOfToday} from "date-fns";

export default {
    components: {
        DateInput,
        LoadingButton,
        Modal,
        MoneyInput,
        SelectInput,
        TextInput,
        TextareaInput,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            saving: false,
            form: {
                vendor_base_charge:             this.service.vendor_base_charge ?? null,
                vendor_per_unit_charge:         this.service.vendor_per_unit_charge ?? null,
                vendor_per_haul_amount:         this.service.vendor_per_haul_amount ?? null,
                vendor_disposal_amount_per_ton: this.service.vendor_disposal_amount_per_ton ?? null,
                tons_included_per_haul:         this.service.tons_included_per_haul ?? null,
            },
            actual_date:            format(startOfToday(), 'yyyy-MM-dd'),
            change_reason:          null,
            change_requester:       this.who,
            internal_change_notes:  null,
            today:                  format(startOfToday(), 'yyyy-MM-dd'),
        }
    },

    methods: {
        submitChange() {
            this.saving = true;

            // should we schedule this or fire it off immediately?
            // we can call different routes or we could just handle in the controller... not sure which is easiest
            this.$inertia.post(this.$route('services.vendor-pricing-changes.store', [this.service.id]), {
                ...this.form,
                'actual_date': this.actual_date,
                'change_reason': this.change_reason,
                'change_requester': this.who,
                'internal_change_notes': this.internal_change_notes,
            }, {
                onFinish: () => this.saving = false
            });
        },
    },

    computed: {
        serviceHasVendorBaseCharge() {
            return ['Commercial', 'Device', 'Doorstep', 'Rental'].includes(this.service.type);
        },

        serviceHasVendorPerUnitCharge() {
            return ['Doorstep'].includes(this.service.type);
        },

        serviceHasVendorPerHaulAmount() {
            return ['Rolloff'].includes(this.service.type);
        },

        serviceHasVendorDisposalAmountPerTon() {
            return ['Rolloff'].includes(this.service.type);
        },

        serviceHasTonsIncludedPerHaul() {
            return ['Rolloff'].includes(this.service.type);
        }
    }
}
</script>