<template>
    <Head title="Fiscal Periods" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Fiscal Periods</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="fiscal-periods" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <div class="flex my-4 items-center">
                        <div class="w-1/2">
                            <div>
                                <div class="input-group">
                                    <span class="input-group-item">
                                        <icon name="search" class="w-4 h-4 fill-current mr-2" />
                                    </span>

                                    <input type="number" v-model="searchForm.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>

                        <div class="ml-auto">
                            <a href="" class="btn btn-orange" @click.prevent="showNewFiscalPeriodModal">
                                New
                            </a>
                        </div>
                    </div>

                    <div v-if="fiscalPeriods.data.length">
                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div class="rounded-md bg-blue-50 p-4 my-3">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <!-- Heroicon name: solid/information-circle -->
                                    <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div class="ml-3 flex-1">
                                    <p class="text-sm text-blue-700">Please ensure the following conditions are met before closing a fiscal period:</p>

                                    <ul class="list-disc mt-3 text-sm  md:ml-6">
                                        <li v-for="check in fiscalPeriodClosingChecks">{{ check }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="flex my-6">
                            (Showing <span class="font-semibold text-gray-900 px-1">{{ fiscalPeriods.data.length }}</span> out of <span class="font-semibold text-gray-900 pl-1">{{ fiscalPeriods.total }}</span>)
                        </div>

                        <div class="overflow-x-auto">
                            <table class="table table-condensed">
                                <thead>
                                    <tr>
                                        <sortable-table-header field="year" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.fiscal-periods.index">Year</sortable-table-header>
                                        <sortable-table-header field="month" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.fiscal-periods.index">Month</sortable-table-header>
                                        <sortable-table-header field="is_open" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.fiscal-periods.index">Open/Closed</sortable-table-header>
                                        <th class="font-semibold"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="fiscalPeriod in fiscalPeriods.data" :key="fiscalPeriod.id">
                                        <td>{{ fiscalPeriod.year }}</td>
                                        <td>{{ fiscalPeriod.month }}</td>
                                        <td>
                                            <span v-if="fiscalPeriod.is_open" class="px-2 inline-flex leading-5 rounded-full bg-green-100 text-green-800">
                                                Open
                                            </span>
                                            <span v-else class="px-2 inline-flex leading-5 rounded-full bg-red-100 text-red-800">
                                                Closed
                                            </span>
                                        </td>
                                        <td>
                                            <button v-if="fiscalPeriod.is_open" href="" @click.prevent="closeFiscalPeriod(fiscalPeriod)" title="Close the fiscal period">
                                                <icon name="lock" class="inline fill-current text-red-600 hover:text-red-700 w-5 h-5 mx-2" />
                                            </button>
                                            <button v-else href="" @click.prevent="openFiscalPeriod(fiscalPeriod)" title="Open the fiscal period">
                                                <icon name="lock-open" class="inline fill-current text-green-600 hover:text-green-700 w-5 h-5 mx-2" />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <pagination :links="fiscalPeriods.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="envelope-open-dollar" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Fiscal Periods Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <!-- Create Fiscal Periods Modal -->
    <modal role="dialog" ref="fiscalPeriodModal" class="max-w-2xl" @closed="emptyModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl">
                    <span class='fa-plus'></span>
                        New Fiscal Period
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <form>
                    <div class="flex flex-wrap -mx-4 my-2">
                        <text-input v-model="form.year" label="Year" placeholder="2000 - 2099" type="number" min="2000" max="2099" :errors="modalErrors['year']" class="w-1/2 px-4" mark-as-required />

                        <select-input v-model="form.month" label="Month" :errors="modalErrors['month']" class="w-1/2 px-4" mark-as-required>
                            <option :value="null">Select a Month</option>

                            <option v-for="month in months" :value="month.number">
                                {{ month.number }} - {{ month.month}}
                            </option>
                        </select-input>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4">
                <div class="inline-block mr-2">
                    <button type="button" class="btn btn-gray" @click="close">Close</button>
                </div>

                <div class="inline-block">
                    <loading-button :loading="saving" class="inline btn btn-orange" @click="modalCallback">
                        Save
                    </loading-button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Modal from '@/Shared/Modal.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import {throttle} from 'lodash-es';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import SubNav from '../SubNav.vue';

    export default {
        components: {
            Head,
            Icon,
            ToggleSwitchInput,
            TextInput,
            SelectInput,
            TextareaInput,
            LoadingButton,
            Pagination,
            Modal,
            SortableTableHeader,
            SubNav
        },
        
        props: {
            fiscalPeriods: {
                type: Object,
                required: true
            },

            filters: Object,

            errors: {
                type: Object,
                default: () => ({}),
            }
        },

        data() {
            return {
                form: {
                    year: null,
                    month: null
                },
                months: [
                    {number:"01", month:"January"},
                    {number:"02", month:"February"},
                    {number:"03", month:"March"},
                    {number:"04", month:"April"},
                    {number:"05", month:"May"},
                    {number:"06", month:"June"},
                    {number:"07", month:"July"},
                    {number:"08", month:"August"},
                    {number:"09", month:"September"},
                    {number:"10", month:"October"},
                    {number:"11", month:"November"},
                    {number:"12", month:"December"},
                ],
                modalCallback: () => {},
                modalErrors: {},
                saving: false,
                searchForm: {
                    search: this.filters.search
                },
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            showNewFiscalPeriodModal() {
                this.modalCallback = this.createFiscalPeriod;
                this.form = {
                    year: null,
                    month: null
                };

                this.$refs.fiscalPeriodModal.show();
            },

            createFiscalPeriod() {
                this.saving = true;

                this.$inertia.post(this.$route('tenant-settings.fiscal-periods.store'), this.form, 
                    {
                        onSuccess: () => this.$refs.fiscalPeriodModal.close(),
                        onError: (errors) => this.modalErrors = errors,
                        onFinish: () => this.saving = false
                    }
                );
            },

            emptyModal() {
                this.form = {
                    year: null,
                    month: null
                };
                this.modalErrors = {};
            },

            closeFiscalPeriod(fiscalPeriod) {
                this.$inertia.patch(this.$route('tenant-settings.fiscal-periods.close', fiscalPeriod.id));
            },

            openFiscalPeriod(fiscalPeriod) {
                this.$inertia.patch(this.$route('tenant-settings.fiscal-periods.open', fiscalPeriod.id));
            },

            changeNavigation(e) {
                this.$inertia.get(e.target.value);
            },
        },

        watch: {
            searchForm: {
                handler: throttle(function() {
                    let query = { ...this.searchForm };
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route('tenant-settings.fiscal-periods.index'), query, {preserveState: true});
                }, 150),

                deep: true,
            },
        },

        computed: {
            fiscalPeriodClosingChecks() {
                let checks = [];

                if (this.$page.props.permissions.trackVendorInvoicePaymentStatuses) {
                    checks.push('All Vendor Invoices have been posted');
                } else {
                    checks.push('All Vendor Invoices have been approved');
                }

                if (this.$page.props.permissions.accessRevenueManagement) {
                    checks.push('All Client Line Items have been attached to Client Invoices or Client Credit Memos');
                    checks.push('All Client Invoices have been sent');
                    checks.push('All Client Credit Memos have been sent');
                }

                if (this.$page.props.permissions.trackVendorInvoicePaymentStatuses) {
                    checks.push('All Journal Entry Line Items have been synced to your external accounting system');
                }

                return checks;
            }
        }
    }
</script>