<template>
    <Head title="Rover Accounts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Rover Accounts</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="vendor-portal-accounts-search-form"
          class="grid grid-cols-6 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-6 sm:col-span-2">
            <label for="search-vendor-portal-accounts" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2"/>
                </span>

                <input type="search" name="search-vendor-portal-accounts" id="search-vendor-portal-accounts"
                       v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page"
                      placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('vendor-portal-accounts.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="w-full my-4">
        <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="vendorPortalAccounts.data.length"
                 class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">
                Showing {{ vendorPortalAccounts.from }} - {{ vendorPortalAccounts.to }} out of
                {{ vendorPortalAccounts.total }} Rover Accounts
            </div>
            <div
                class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-2 xl:col-end-5">
                <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!vendorPortalAccounts.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="relative overflow-x-auto">
                <div v-if="selectedVendorPortalAccounts.length > 0"
                     class="absolute bg-white top-1.5 left-12 flex h-8 items-center space-x-3 sm:left-16">
                    <button v-if="$can('bulkSubscribe')" type="button" @click="subscribeSelectedVendorPortalAccounts" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                        Subscribe
                    </button>
                    <button v-if="$can('bulkUnsubscribe')" type="button" @click="disableSelectedVendorPortalAccounts" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                        Unsubscribe
                    </button>
                </div>

                <table class="table table-condensed" ref="table">
                    <thead>
                    <tr>
                        <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                            <input type="checkbox"
                                   class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6"
                                   :checked="vendorPortalAccounts.data.length && (indeterminate || selectedVendorPortalAccounts.length === availableAccountsForSelection.length)"
                                   :indeterminate="indeterminate"
                                   :disabled="!availableAccountsForSelection.length"
                                   @change="selectedVendorPortalAccounts = $event.target.checked ? availableAccountsForSelection.map((vendorPortalAccount) => vendorPortalAccount.id) : []"
                                   v-if="availableAccountsForSelection.length > 0"
                                    />
                        </th>
                        <sortable-table-header field="account_number" :filters="filters" route-name="vendor-portal-accounts.index" scope="col" class="font-semibold">Rover Account</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Subscribed At')" field="subscribed_at" :filters="filters" route-name="vendor-portal-accounts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Subscribed At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Most Recent Invoice Date')" field="most_recent_invoice_date" :filters="filters" route-name="vendor-portal-accounts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Most Recent Invoice Date</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Vendor Account')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Vendor Account</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Status')" field="status" :filters="filters" route-name="vendor-portal-accounts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Status</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Archived At')" field="archived_at" :filters="filters" route-name="vendor-portal-accounts.index" scope="col" class="font-semibold">Archived At</sortable-table-header>
                        <th scope="col" class="w-36 px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="vendorPortalAccount in vendorPortalAccounts.data" :key="vendorPortalAccount.id">
                        <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                            <template v-if="vendorPortalAccount.status !== 'Initializing'">
                                <div v-if="selectedVendorPortalAccounts.includes(vendorPortalAccount.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                <input type="checkbox"
                                       class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6"
                                       :value="vendorPortalAccount.id" v-model="selectedVendorPortalAccounts"
                                        v-if="vendorPortalAccount.vendorPortalCredential.vendorPortal.enabled && !vendorPortalAccount.archived_at" />
                            </template>
                        </td>

                        <td>
                            <inertia-link v-if="$can('view', vendorPortalAccount)" :href="$route('vendor-portal-accounts.show', [vendorPortalAccount.id])" class="link mr-3" :title="`View Account ${vendorPortalAccount.account_number}`">
                                {{ vendorPortalAccount.account_number }}
                            </inertia-link>
                            <span v-else>{{ vendorPortalAccount.account_number }}</span>
                        </td>

                        <td v-show="form.selected_headers.includes('Subscribed At')">
                            <template v-if="vendorPortalAccount.subscribed_at">
                                {{ $filters.format_date_time(vendorPortalAccount.subscribed_at) }}
                            </template>

                            <template v-else>
                                Not Subscribed
                            </template>
                        </td>

                        <td v-show="form.selected_headers.includes('Most Recent Invoice Date')">
                            <template v-if="vendorPortalAccount.most_recent_invoice_date">
                                {{ $filters.format_date(vendorPortalAccount.most_recent_invoice_date) }}
                            </template>

                            <template v-else>
                                -
                            </template>
                        </td>

                        <td v-show="form.selected_headers.includes('Vendor Account')">
                        <span v-if="vendorPortalAccount.vendorAccount"><inertia-link
                            :href="$route('vendor-accounts.show', vendorPortalAccount.vendorAccount.id)"
                            class="link">{{ vendorPortalAccount.vendorAccount.account_number }}
                            </inertia-link></span>

                            <span v-else>
                            Unmatched
                        </span>
                        </td>

                        <td v-show="form.selected_headers.includes('Status')">
                            <div v-if="!vendorPortalAccount.archived_at" class="flex items-center gap-x-2" :title="vendorPortalAccount.status">
                                <div :class="[statusIndicators[vendorPortalAccount.status], 'flex-none rounded-full p-1']">
                                    <div class="h-1.5 w-1.5 rounded-full bg-current" />
                                </div>
                                {{ vendorPortalAccount.status }}
                            </div>
                            <div v-else class="italic">
                                Archived
                            </div>
                        </td>
                        <td v-show="form.selected_headers.includes('Archived At')">
                            <span v-if="vendorPortalAccount.archived_at" class="inline-block">
                                {{ $filters.format_date_time(vendorPortalAccount.archived_at) }}
                            </span>
                            <template v-else>-</template>
                        </td>
                        <td class="text-right">
                            <span class="inline-block">
                                <inertia-link v-if="$can('view', vendorPortalAccount)" :href="$route('vendor-portal-accounts.show', [vendorPortalAccount.id])" class="link mr-3" title="View This Rover Account">
                                    View
                                </inertia-link>

                                <inertia-link v-if="$can('update', vendorPortalAccount)"  :href="$route('vendor-portal-accounts.edit', [vendorPortalAccount.id])" class="link" title="Edit This Rover Account">
                                    Edit
                                </inertia-link>
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="vendorPortalAccounts.links"/>
        </div>

        <div v-if="!vendorPortalAccounts.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="file-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32"/>
            <span
                class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Rover Accounts Found</span>
        </div>

        <slide-over ref="searchFilterSlideOver">
            <template #header="{close}">
                <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                        <div class="ml-3 flex h-7 items-center">
                            <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span class="sr-only">Close panel</span>
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </template>

            <template #body>
                <form @submit.prevent id="vendor-contacts-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                    <select-input id="subscribed" name="subscribed" class="col-span-6 sm:col-span-1" v-model="form.subscribed"
                                  label="Subscribed">
                        <option value="except">Hide</option>
                        <option value="include">Include</option>
                        <option value="only">Only</option>
                    </select-input>

                    <select-input id="archived" name="archived" class="col-span-6 sm:col-span-1" v-model="form.archived"
                                  label="Archived">
                        <option value="except">Hide</option>
                        <option value="include">Include</option>
                        <option value="only">Only</option>
                    </select-input>

                    <label for="status" class="sr-only">Status</label>

                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="statuses" name="statuses" class="form-input"
                                           @change="statusComboBoxQuery = $event.target.value"
                                           :display-value="comboDisplayValue"/>

                            <ComboboxButton
                                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                          clip-rule="evenodd"/>
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="statuses.length > 0"
                                             class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="status in filteredStatusOptions" :key="status.id"
                                                :value="status.text" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ status.text }}
                            </span>

                                        <span v-if="selected"
                                              :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                <!-- Heroicon name: solid/check -->
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clip-rule="evenodd"/>
                                </svg>
                            </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </form>
            </template>
        </slide-over>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from "lodash-es";
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import SlideOver from "@/Shared/SlideOver.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            SlideOver,
            Head,
            Icon,
            LoadingButton,
            Pagination,
            SelectInput,
            SortableTableHeader,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
            ColumnSelector,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorPortalAccounts: {
                type: Object,
                required: true
            },

            statuses: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    per_page: this.filters.per_page,
                    search: this.filters.search,
                    statuses: this.filters.statuses,
                    subscribed: this.filters.subscribed,
                    archived: this.filters.archived,
                    selected_headers: this.filters.selected_headers,
                },
                saving: false,
                statusComboBoxQuery: '',
                selectedVendorPortalAccounts: [],
                headers: [],
                excludedHeaders: ['Rover Account', 'Actions'],
                mounted: false,
                statusIndicators: {
                    'Healthy': 'text-green-400 bg-green-400/10',
                    'Initializing': 'text-yellow-400 bg-yellow-400/10',
                    'Searching for Invoices Failed': 'text-rose-400 bg-rose-400/10',
                },
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.vendor-portal-accounts.index'), this.queryFilters, {
                    onFinish: () => {
                        this.saving = false;
                    }
                });
            },
            subscribeSelectedVendorPortalAccounts() {
                this.$inertia.post(this.$route('vendor-portal-accounts.subscribe-accounts'), {
                    vendor_portal_account_ids: this.selectedVendorPortalAccounts,
                }, {
                    onSuccess: () => this.selectedVendorPortalAccounts = []
                });
            },

            disableSelectedVendorPortalAccounts() {
                this.$inertia.post(this.$route('vendor-portal-accounts.unsubscribe-accounts'), {
                    vendor_portal_account_ids: this.selectedVendorPortalAccounts,
                }, {
                    onSuccess: () => this.selectedVendorPortalAccounts = []
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendor-portal-accounts.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = {...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.text.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            availableAccountsForSelection() {
                return this.vendorPortalAccounts.data.filter((vendorPortalAccount) => {
                    return vendorPortalAccount.vendorPortalCredential.vendorPortal.enabled
                        && !vendorPortalAccount.archived_at;
                });
            },

            indeterminate() {
                return this.selectedVendorPortalAccounts.length > 0 && this.selectedVendorPortalAccounts.length < this.availableAccountsForSelection.length;
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                    form.subscribed,
                    form.archived,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            },
        },
    }
</script>