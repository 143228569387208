<template>
    <Head :title="`Edit Vendor Account Change - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Account Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
    </div>

    <div class="container mx-auto mt-8 mb-12">
        <div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <revert-input v-model="form.change_requester" class="col-span-2 md:col-span-1" label="Who requested this change?" mark-as-required>
                    <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                        <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                        <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                        <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.actual_date" class="col-span-3 md:col-span-1" label="When is this change effective?" mark-as-required>
                    <date-input :min="serviceCreationActivity.actual_date" v-model="form.actual_date" :errors="errors['actual_date']" />
                </revert-input>

                <revert-input v-model="form.change_reason" class="col-span-3 md:col-span-1" label="Why are you making this change?" mark-as-required>
                    <select-input v-model="form.change_reason">
                        <option>Other</option>
                    </select-input>
                </revert-input>
            </div>

            <revert-input class="col-span-2 md:col-span-1" label="Vendor Account" v-model="form.vendor_account_id" mark-as-required>
                <div v-if="!vendorAccounts.length" class="py-2 text-gray-700">No accounts available for this vendor</div>
                <select-input-2 v-else v-model="form.vendor_account_id" :options="vendorAccounts" :errors="errors['vendor_account_id']">
                    <template #option-label="vendorAccount">{{ vendorAccount.account_number }} - {{ vendorAccount.status }}</template>
                </select-input-2>
            </revert-input>

            <revert-input v-model="form.internal_change_notes" class="col-span-2" label="Internal Change Notes">
                <textarea-input v-model="form.internal_change_notes" />
            </revert-input>

            <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">Submit Change</loading-button>
        </div>
    </div>
</template>

<script>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { format, startOfToday } from "date-fns";
    import { rrulestr } from "rrule";
    import Modal from "@/Shared/Modal.vue";
    import RRuleInput from "@/Shared/RRuleInput.vue";
    import TextInput from "@/Shared/TextInput.vue";
    import RevertibleBin from "@/Shared/Services/RevertibleBin.vue";
    import SelectInput2 from "@/Shared/SelectInput2.vue";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            SelectInput2,
            TextInput,
            RRuleInput, Modal,
            DateInput,
            Icon,
            LoadingButton,
            RevertInput,
            RevertibleBin,
            SelectInput,
            TextareaInput,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            serviceCreationActivity: {
                type: Object,
                required: true
            },

            vendorAccounts: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                form: {
                    actual_date:            this.serviceActivity.actual_date,
                    change_reason:          this.serviceActivity.reason,
                    change_requester:       this.serviceActivity.change_requester,
                    internal_change_notes:  this.serviceActivity.internal_change_notes,
                    vendor_account_id:      this.serviceActivity.serviceSnapshot.vendor_account_id,
                },
                saving: false,
                today: format(startOfToday(), 'yyyy-MM-dd'),
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitChange() {
                this.saving = true;

                // should we schedule this or fire it off immediately?
                // we can call different routes or we could just handle in the controller... not sure which is easiest
                this.$inertia.put(this.$route('services.vendor-account-changes.update', [this.serviceActivity.service_id, this.serviceActivity.id]), {
                    ...this.form,
                }, {
                    onFinish: () => this.saving = false
                });
            },

            parseRRule(rRule) {
                return rRule.rfcstring ? rrulestr(rRule.rfcstring).toText() : '';
            },

            showUpdatePickupRRuleModal() {
                this.$refs.updatePickupRRuleModal.show();
            },

            deleteServiceChange() {
                if (window.confirm('Are you sure you want to delete this vendor account change?')) {
                    this.$inertia.delete(this.$route('services.vendor-account-changes.destroy', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id}));
                }
            },
        },
    }
</script>