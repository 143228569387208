<template>
    <fieldset>
        <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
            <revert-input class="col-span-2 md:col-span-1" v-model="form.quantity" label="Quantity" :markAsRequired="fieldsAreRequired">
                <text-input v-model="form.quantity" :errors="errors['bin.quantity']" type="number" />
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.size" label="Size" :markAsRequired="fieldsAreRequired">
                <text-input v-model="form.size" :errors="errors['bin.size']" type="number" />
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.size_metric" label="Size metric" :markAsRequired="fieldsAreRequired">
                <select-input v-model="form.size_metric" :errors="errors['bin.size_metric']">
                    <option value="yards">Yards</option>
                    <option value="gallons">Gallons</option>
                    <option value="foot">Feet</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.material_type" label="Material Type" :markAsRequired="fieldsAreRequired">
                <select-input v-model="form.material_type" :errors="errors['bin.material_type']">
                    <option v-for="material_type in materialTypes" :value="material_type.name" :key="material_type.name">{{ material_type.name }}</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.disposal_method" label="Disposal Method" :markAsRequired="fieldsAreRequired">
                <select-input v-model="form.disposal_method" :errors="errors['bin.disposal_method']">
                    <option value="Composted">Composted</option>
                    <option value="Incineration">Incineration</option>
                    <option value="Landfill">Landfill</option>
                    <option value="Recycling">Recycling</option>
                    <option value="Reuse">Reuse</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.type" label="Bin Type" :markAsRequired="fieldsAreRequired">
                <select-input v-model="form.type" :errors="errors['bin.type']">
                    <option v-for="bin_type in binTypes" :value="bin_type.name" :key="bin_type.name">{{ bin_type.name }}</option>
                </select-input>
            </revert-input>
        </div>
    </fieldSet>

    <fieldset class="mt-4">
        <legend  class="block font-semibold text-l text-d-blue-600 my-5">
            <button v-if="collapsed" @click="changeCollapse">
                Show Advanced Bin Options
                <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="collapsed ? 'chevron-right' : 'chevron-down'" />
            </button>
            <button v-else @click="changeCollapse">
                Hide Advanced Bin Options
                <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="collapsed ? 'chevron-right' : 'chevron-down'" />
            </button>
        </legend>

        <div v-if="!collapsed" class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <revert-input class="col-span-2 md:col-span-1" v-model="form.is_compacted_or_baled" label="Is Compacted or Baled">
                <select-input v-model="form.is_compacted_or_baled" :errors="errors['bin.is_compacted_or_baled']">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.compaction_ratio" label="Compaction Ratio">
                <text-input class="col-span-2 md:col-span-1" v-model="form.compaction_ratio" :errors="errors['bin.compaction_ratio']" :disabled="form.is_compacted_or_baled !== true" type="number" @change="recalculatePoundsPerWeight" />
            </revert-input>

	        <revert-input v-if="$page.props.small_units === 'kilograms'" class="col-span-2 md:col-span-1" v-model="form.kilograms_per_yard" label="Kilograms Per Yard">
		        <text-input :disabled="!form.material_type" v-model="form.kilograms_per_yard" min="0" type="number" :errors="errors['bin.kilograms_per_yard']"/>
		        <p class="text-xs pt-2 text-green-600" v-if="form.compaction_ratio > 1">A {{ form.compaction_ratio }}-to-1 compaction ratio has automatically been applied to the kilograms per yard.</p>
		        <p class="text-xs pt-2 text-green-600" v-if="form.size_metric !== 'yards'">Please note our system will handle unit conversions from <span class="font-bold">{{ form.size_metric }} to cubic yards.</span></p>
            </revert-input>

            <revert-input v-else class="col-span-2 md:col-span-1" v-model="form.pounds_per_yard" label="Pounds Per Yard">
                <text-input :disabled="!form.material_type" v-model="form.pounds_per_yard" min="0" type="number" :errors="errors['bin.pounds_per_yard']"/>
                <p class="text-xs pt-2 text-green-600" v-if="form.compaction_ratio > 1">A {{ form.compaction_ratio }}-to-1 compaction ratio has automatically been applied to the pounds per yard.</p>
                <p class="text-xs pt-2 text-green-600" v-if="form.size_metric !== 'yards'">Please note our system will handle unit conversions from <span class="font-bold">{{ form.size_metric }} to cubic yards.</span></p>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.has_casters" label="Has Casters">
                <select-input v-model="form.has_casters" :errors="errors['bin.has_casters']">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.enclosure" label="Enclosure">
                <select-input v-model="form.enclosure" :errors="errors['bin.enclosure']">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.requires_overhead_instructions" label="Overhead Instructions">
                <select-input v-model="form.requires_overhead_instructions" :errors="errors['bin.requires_overhead_instructions']">
                    <option :value="true">Required</option>
                    <option :value="false">Not Required</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.container_access_times" label="Container Access Times">
                <text-input v-model="form.container_access_times" :errors="errors['bin.container_access_times']" />
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.configuration" label="Configuration">
                <select-input v-model="form.configuration" :errors="errors['bin.configuration']">
                    <option>Flat top</option>
                    <option>Slant top</option>
                    <option>Slotted</option>
                    <option>Other</option>
                    <option>Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.owner" label="Asset Owner">
                <select-input v-model="form.owner" :errors="errors['bin.owner']">
                    <option v-for="asset_owner in assetOwners" :value="asset_owner.id" :key="asset_owner.id">{{ asset_owner.name }}</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.keys" label="Requires Keys">
                <select-input v-model="form.keys">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.has_lids" label="Lids">
                <select-input v-model="form.has_lids" :errors="errors['bin.has_lids']">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.has_lock" label="Has Lock">
                <select-input v-model="form.has_lock" :errors="errors['bin.has_lock']">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.secured_access" label="Secured Access">
                <select-input v-model="form.secured_access" :errors="errors['bin.secured_access']">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.push_out_service" label="Push out Service">
                <select-input v-model="form.push_out_service" :errors="errors['bin.push_out_service']">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.side_door_to_bin" label="Side Door to Bin">
                <select-input v-model="form.side_door_to_bin" :errors="errors['bin.side_door_to_bin']">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                    <option :value="null">Unsure</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-2 md:col-span-1" v-model="form.compaction_method" label="Compaction Method">
                <select-input v-model="form.compaction_method" :errors="errors['bin.compaction_method']">
                    <option value="na">N/A</option>
                    <option value="auger">Auger</option>
                    <option value="hydraulic">Hydraulic</option>
                </select-input>
            </revert-input>

            <revert-input class="col-span-4 md:col-span-2" v-model="form.area_description" label="Area Description" >
                <textarea-input v-model="form.area_description" :errors="errors['bin.area_description']" />
            </revert-input>
        </div>
    </fieldset>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';

	export default {
        components: {
            DateInput,
            Icon,
            RevertInput,
            SelectInput,
            TextInput,
            TextareaInput,
        },

		props: {
            propertyType: {
                required: false,
                default() {
                    return 'Commercial'
                }
            },

			modelValue: {
				required: true
            },

            materialTypes: {
                type: Array,
                required: true
            },

            binTypes: {
                type: Array,
                required: true
            },

            assetOwners: {
                type: Array,
                required: true
            },

            fieldsAreRequired: {
                type: Boolean,
                required: false,
                default: false
            },

            errors: {
                type: Object,
                default: () => ({})
            },
		},

		data() {
            let collapsed = true;
			return {
                collapsed:collapsed,
				form: { ...this.modelValue },
			}
		},

        methods: {
            materialType() {
                return this.materialTypes.filter((materialType) => materialType.name === this.form.material_type)[0];
            },

            recalculatePoundsPerWeight() {
	            if (this.$page.props.small_units === 'kilograms') {
		            if (this.propertyType === 'Multi-family') {
			            this.form.kilograms_per_yard = parseFloat((this.form.compaction_ratio * this.materialType().multifamily_kilograms_per_yard).toFixed(12));
		            } else if (this.propertyType === 'Commercial') {
			            this.form.kilograms_per_yard = parseFloat((this.form.compaction_ratio * this.materialType().commercial_kilograms_per_yard).toFixed(12));
		            }
	            } else {
		            if (this.propertyType === 'Multi-family') {
			            this.form.pounds_per_yard = parseFloat((this.form.compaction_ratio * this.materialType().multifamily_pounds_per_yard).toFixed(12));
		            } else if (this.propertyType === 'Commercial') {
			            this.form.pounds_per_yard = parseFloat((this.form.compaction_ratio * this.materialType().commercial_pounds_per_yard).toFixed(12));
		            }
	            }
            },
            changeCollapse() {
                this.collapsed = !this.collapsed;
            }
        },

		watch: {
            'form.type': {
                handler() {
                    let compactedOrBaledTypes = ['Compactor - Apartment', 'Compactor - Self-Contained', 'Compactor - Stationary', 'Compactor - Unknown', 'Compactor - Vertical Baler', 'RL Comp', 'FL Comp'];

                    this.form.is_compacted_or_baled = compactedOrBaledTypes.indexOf(this.form.type) !== -1;
                },
                deep: true
            },

            'form.material_type': {
                handler() {
                    this.form.disposal_method = this.materialType().default_disposal_method
                    this.form.compaction_ratio = this.form.is_compacted_or_baled ? (this.materialType().default_compaction_ratio ?? 2.6) : 1
                    this.recalculatePoundsPerWeight()
                },
                deep: true
            },

            'form.is_compacted_or_baled': {
                handler() {
                    this.form.compaction_ratio = this.form.is_compacted_or_baled ? (this.materialType().default_compaction_ratio ?? 2.6) : 1;
                    this.recalculatePoundsPerWeight();
                },
                deep: true
            },

            propertyType: {
                handler() {
                    if (this.form.material_type && this.form.compaction_ratio !== null) {
                        this.recalculatePoundsPerWeight();
                    }
                }
            },

			form: {
				handler() {
					this.$emit('update:modelValue', this.form)
                    this.$emit('change', this.form)
				},
				deep: true
			}
		}
	}
</script>
