<template>
    <div :class="$attrs.class" ref="comboBox">
        <slot name="label">
            <label v-if="label" class="form-label" :for="id">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
            </label>
        </slot>
        
        <div class="hidden relative mt-1 md:block">
            <input :id="id" 
                type="text" 
                class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm" 
                role="combobox" 
                aria-controls="options" 
                aria-expanded="false"
                ref="search"
                :value="calculatedDisplayValue"
                v-on:keydown.down="moveActiveDown"
                v-on:keydown.up="moveActiveUp"
                v-on:keydown.enter="selectActiveResult"
                v-on:keydown.tab="onTab"
                v-on:keydown.escape="closeComboBox"
                v-on:keydown.backspace="onBackspace"
                @input="handleInput"
                @blur="handleBlur"
            >
            
            <button @click.prevent="toggleComboBox" type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <!-- Heroicon name: solid/selector -->
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>

            <transition leave-active-class="transition duration-150">
                <div v-show="state === 'open'">
                    <ul v-if="filteredOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
                        <combo-box-option 
                            v-for="(option, index) in filteredOptions" 
                            :key="option[keyName]" 
                            :value="option" 
                            class="relative cursor-default select-none py-2 pl-3 pr-9"
                            :active="activeIndex === index"
                            :class="{'bg-blue-600 text-white' : activeIndex === index }"
                            @selected="selectOption(option)"
                            @unselected="unselectOption(option)"
                            @mouseenter="activeIndex = index"
                            @blur="handleBlur" 
                        />
                    </ul>
                </div>
            </transition>
        </div>
        
        <div v-if="errors" class="form-error">{{ errors}}</div>
    </div>
</template>

<script>
    import { v4 as uuid } from 'uuid';
    import { defineComponent, ref, computed, reactive, provide } from 'vue';
    import ComboBoxOption from '@/Shared/ComboBoxOption.vue';

    export default defineComponent({
        inheritAttrs: false,
        
        emits: ['update:modelValue'],
        
        props: {
            id: {
                type: String,
                default() {
                    return `select-input-${uuid()}`
                },
            },

            modelValue: {
                type: [String, Array],
                required: true,
            },

            displayValue: {
                type: Function,
                required: true,
            },

            label: String,

            options: {
                type: Array,
                required: true,
            },

            keyName: {
                type: String,
                default: 'id',
            },

            valueName: {
                type: String,
                default: 'value',
            },
            
            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },
        },

        components: {
            ComboBoxOption,
        },

        setup(props, context) {
            provide('keyName', props.keyName);
            provide('valueName', props.valueName);

            /** Data/Refs */
            const comboBox = ref(null);
            const state = ref('closed');
            const search = ref(null);
            const searchString = ref('');
            const activeIndex = ref(null);
            const calculatedDisplayValue = ref(null);

            const dataOptions = reactive(props.options.map(option => {
                let selected = false;

                if (typeof context.attrs.multiple !== undefined) {
                    if (is_primitive(option)) {
                        selected = props.modelValue.includes(option);
                    } else {
                        selected = props.modelValue.includes(option[props.keyName]);
                    }
                } else {
                    if (is_primitive(option)) {
                        selected = props.modelValue === option;
                    } else {
                        selected = props.modelValue === option[props.keyName];
                    }
                }

                if (is_primitive(option)) {
                    return { [props.keyName]: option, [props.valueName]: option, selected: selected };
                }

                return {...option, selected: selected};
            }));

            /** Computed */
            const filteredOptions = computed(() => {
                if (searchString.value === '') {
                    return dataOptions;
                } else {
                    return dataOptions.filter(option => {
                        let searchable = option[props.valueName];

                        // convert searching to a string if it's an integer
                        if (typeof searchable === 'number') {
                            searchable = searchable.toString();
                        }

                        return searchable.toLowerCase().includes(searchString.value.toLowerCase());
                    });
                }
            });

            const selectedOptions = computed(() => {
                if (typeof context.attrs.multiple !== undefined) {
                    return dataOptions.filter(option => option.selected === true);
                } else {
                    return dataOptions.find(option => option.selected === true);
                }
            });

            calculatedDisplayValue.value = props.displayValue(selectedOptions.value);
            
            /** Methods */
            function selectOption(option) {
                option.selected = true;
                calculatedDisplayValue.value = props.displayValue(selectedOptions.value);
                searchString.value = '';

                // If multiple isn't enabled, unselect all other options and close the combobox.
                if (typeof context.attrs.multiple === undefined) {
                    dataOptions.filter(dataOption => dataOption[props.keyName] !== option[props.keyName])
                        .forEach(dataOption => dataOption.selected = false);

                    closeComboBox();
                }

                context.emit('update:modelValue', dataOptions.filter(option => option.selected === true).map(option => option.value));
            };

            function unselectOption(option) {
                option.selected = false;
                calculatedDisplayValue.value = props.displayValue(selectedOptions.value);
                searchString.value = '';
                
                if (typeof context.attrs.multiple === undefined) {
                    closeComboBox();
                }

                context.emit('update:modelValue', dataOptions.filter(option => option.selected === true).map(option => option.value));
            };

            function closeComboBox() {
                console.info('closeComboBox');
                calculatedDisplayValue.value = '';
                calculatedDisplayValue.value = props.displayValue(selectedOptions.value);
                state.value = 'closed';
            }

            function openComboBox() {
                console.info('openComboBox');
                state.value = 'open';
                search.value.focus();

                if (!activeIndex.value) {
                    activeIndex.value = 0;
                }
            }

            function toggleComboBox() {
                console.info('toggling');
                state.value === 'open' ? closeComboBox() : openComboBox();
            }

            function handleInput(event) {
                console.info('handleInput');
                searchString.value = event.target.value;
                
                if (filteredOptions.value.length > 0) {
                    openComboBox();
                }
            }

            function handleBlur(e) {
                let target = e.relatedTarget;

                // this check isn't working when we actually do blur the element
                if (comboBox.value !== target && !comboBox.value.contains(target)) {
                    closeComboBox();
                } else {
                    console.info('handleBlur: target is inside comboBox');
                }
            }

            function moveActiveDown() {
                if (activeIndex === null) {
                    activeIndex.value = 0;
                } else if (activeIndex.value < dataOptions.length - 1) {
                    activeIndex.value++;
                }
            }

            function moveActiveUp() {
                if (activeIndex === null && dataOptions.length > 0) {
                    activeIndex.value = dataOptions.length - 1;
                } else if (activeIndex.value > 0) {
                    activeIndex.value--;
                }
            }

            function selectActiveResult() {
                if (activeIndex.value !== null) {
                    selectOption(dataOptions[activeIndex.value])
                }
            }

            function onTab(event) {
                if (state.value === 'open') {
                    selectActiveResult();
                    closeComboBox();
                }
            }

            function onBackspace(event) {
                console.info('backspace');
            }

            function is_primitive (value) {
                return (value !== Object(value));
            }

            return {
                // refs
                comboBox,
                search,

                // data
                dataOptions,
                state,
                searchString,
                activeIndex,

                // methods
                selectOption,
                unselectOption,
                toggleComboBox,
                closeComboBox,
                openComboBox,
                handleInput,
                handleBlur,
                moveActiveDown,
                moveActiveUp,
                selectActiveResult,
                onTab,
                onBackspace,

                // computed
                filteredOptions,
                selectedOptions,
                calculatedDisplayValue,
            };
        },
    });
</script>
