<template>
    <Head title="Excess and Contaminated Charges" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('reports.index')" class="breadcrumb-link">Reports</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Excess and Contaminated Charges</span>
        </nav>
    </Teleport>

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="reportData.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ reportData.from }} - {{ reportData.to }} out of {{ reportData.total }} Records</div>

            <div class="col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end">
                <div class="inline-block">
                    <loading-button :loading="state === 'Exporting'" class="btn btn-blue my-1" @click="exportData" :disabled="!reportData.data.length">
                        <icon name="file-export" class="inline text-white fill-current h-4 w-4 mr-2" />Export to CSV
                    </loading-button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="reportData.data.length !== 0">
        <reporting-table :reportData="reportData.data"></reporting-table>
        <pagination v-if="reportData" :links="reportData.links" />
    </div>
    <div v-else>
        <h5>No results found.</h5>
    </div>
</template>

<script>
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { debounce, pickBy } from 'lodash-es';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            DropdownLink,
            SelectInput,
            Pagination,
            LoadingButton,
            ReportingTable,
            Head,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: {
                    client_company_id: this.filters.client_company_id,
                },
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            exportData() {
                this.state = 'saving';

                this.$inertia.post(this.$route('csv.excess-and-contaminated-charges'), {...this.form}, {
                    onFinish: () => { this.state = 'passive'; }
                });
            }
        },

        watch: {
            form: {
                handler: debounce(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route("reports.excess-and-contaminated-charges"), query, {preserveState: true});
                }, 500),

                deep: true
            },
        }
    }
</script>