<template>
    <!--
        Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Active: "text-white bg-blue-600", Not Active: "text-gray-900"
    -->
    <li @click.prevent="toggleSelect" :class="active ? 'text-white bg-blue-600' : 'text-gray-900'" id="option-0" role="option" tabindex="-1">
        <!-- Selected: "font-semibold" -->
        <span class="block truncate" :class="{'font-semibold': value.selected}">
            {{ value[valueName] }}
        </span>

        <!--
        Checkmark, only display for selected option.

        Active: "text-white", Not Active: "text-blue-600"
        -->
        <span v-show="value.selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
            <!-- Heroicon name: solid/check -->
            <svg class="h-5 w-5" :class="{'text-white': active === true && value.selected === true}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
        </span>
    </li>

    <!-- More items... -->
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue';

export default defineComponent({
    inject: ['keyName', 'valueName'],
    
    props: {
        value: {
            type: Object,
            required: true,
        },

        active: {
            type: Boolean,
            required: true,
        },
    },

    setup(props, context) {
        let toggleSelect = function() {
            if (props.value.selected === true) {
                context.emit('unselected');
            } else {
                context.emit('selected');
            }
        };

        return {
            toggleSelect,
        }
    },
})
</script>
