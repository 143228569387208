<template>
    <Head title="Vendor Contracts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Contracts</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="vendor-contract-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4">
        <div class="col-span-9 sm:col-span-3">
            <label for="search-vendor-contracts" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-vendor-contracts" id="search-vendor-contracts" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-9 sm:col-span-2 lg:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('vendor-contracts.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="vendorContracts.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ vendorContracts.from }} - {{ vendorContracts.to }} out of {{ vendorContracts.total }} Vendor Contracts</div>

        <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
            <inertia-link :href="$route('vendor-contracts.create')" class="btn btn-orange mr-2">
                New
            </inertia-link>

            <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!vendorContracts.data.length">
                Export to CSV
            </loading-button>
        </div>
    </div>

    <div>
         <div class="overflow-x-auto">
             <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <sortable-table-header scope="col" field="contract_number" class="px-3 py-3.5 text-left font-semibold text-gray-900" :filters="filters" route-name="vendor-contracts.index">Vendor Contract Number</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Vendor Name')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Vendor Name</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Status')" field="status" :filters="filters" route-name="vendor-contracts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Status</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Effective Date')" field="effective_date" :filters="filters" route-name="vendor-contracts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Effective Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Expiration Date')" field="expiration_date" :filters="filters" route-name="vendor-contracts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Expiration Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Franchised')" field="is_franchised" :filters="filters" route-name="vendor-contracts.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Franchised</sortable-table-header>
                        <th scope="col" class="w-36 px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="vendorContract in vendorContracts.data" :key="vendorContract.id">
                        <td>
                            <inertia-link data-cy="vendor-contracts.index.show-link" :href="$route('vendor-contracts.show', [vendorContract.id])" class="link mr-3" :title="`View Contract ${vendorContract.display_id}`">
                                {{ vendorContract.display_id }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Vendor Name')">
                            <inertia-link v-if="vendorContract.vendor" :href="$route('vendors.edit', vendorContract.vendor.id)" class="link">
                                {{ vendorContract.vendor.name ?? '' }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Status')">
                            {{ vendorContract.status }}
                        </td>
                        <td v-show="form.selected_headers.includes('Effective Date')">
                            {{ $filters.format_date(vendorContract.effective_date) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Expiration Date')">
                            {{ $filters.format_date(vendorContract.expiration_date) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Franchised')">
                            {{ vendorContract.is_franchised ? 'Yes' : 'No' }}
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('vendor-contracts.show', [vendorContract.id])" class="link mr-3" title="View This Vendor Contract">
                                View
                            </inertia-link>
                            <inertia-link :href="$route('vendor-contracts.edit', [vendorContract.id])" class="link" title="Edit This Vendor Contract">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :links="vendorContracts.links" />
    </div>

    <div v-if="!vendorContracts.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="file-signature" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Vendor Contracts Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="vendor-contract-filter-form" name="vendor-contract-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel for="statuses" class="form-label">Select a Status</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput
                                id="statuses"
                                name="statuses" 
                                class="form-input" 
                                @change="statusComboBoxQuery = $event.target.value" 
                                :display-value="comboDisplayValue" 
                            />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredStatusOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredStatusOptions" :key="option" :value="option" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ option }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <label class="form-label w-1/2">Expires Between</label>
                
                    <date-input v-model="form.expiration_date_start" :errors="errors.expiration_date_start" />
                    
                    <span class="block text-center">thru</span>
                    
                    <date-input v-model="form.expiration_date_end" :errors="errors.expiration_date_end" />
                </div>
                
                <div class="col-span-1">
                    <label class="form-label">Franchised</label>

                    <select-input v-model="form.is_franchised">
                        <option :value="null">All</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select-input>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.service_account_types" multiple>
                        <ComboboxLabel for="account-types" class="form-label">Service Account Type</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput
                                id="account-types"
                                name="service_account_type"
                                class="form-input" 
                                @change="statusComboBoxQuery = $event.target.value" 
                                :display-value="comboDisplayValue" 
                            />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredAccountTypeOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredAccountTypeOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ option }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.service_schedule_types" multiple>
                        <ComboboxLabel for="schedule-types" class="form-label">Service Schedule Type</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput
                                id="schedule-types"
                                name="service_schedule_types"
                                class="form-input"
                                @change="statusComboBoxQuery = $event.target.value"
                                :display-value="comboDisplayValue"
                            />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredScheduleTypeOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredScheduleTypeOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ option }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SlideOver from '@/Shared/SlideOver.vue';
    import { throttle } from 'lodash-es';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
            DateInput,
            SlideOver,
            Icon,
            LoadingButton,
            Pagination,
            SelectInput,
            SortableTableHeader,
            ColumnSelector,

            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorContracts: {
                type: Object,
                required: true
            },

            statuses: Array,

            serviceAccountTypes: Array,
            serviceScheduleTypes: Array,

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    expiration_date_start: this.filters.expiration_date_start,
                    expiration_date_end: this.filters.expiration_date_end,
                    is_franchised: this.filters.is_franchised,
                    statuses: this.filters.statuses,
                    service_account_types: this.filters.service_account_types,
                    service_schedule_types: this.filters.service_schedule_types,
                    selected_headers: this.filters.selected_headers,
                },
                statusComboBoxQuery: '',
                accountTypeComboBoxQuery: '',
                scheduleTypeComboBoxQuery: '',
                saving: false,
                headers: [],
                excludedHeaders: ['Vendor Contract Number', 'Actions'],
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.vendor-contracts.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendor-contracts.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = {...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            filteredAccountTypeOptions() {
                return this.accountTypeComboBoxQuery === ''
                    ? this.serviceAccountTypes
                    : this.serviceAccountTypes.filter((accountType) => {
                        return accountType.toLowerCase().includes(this.accountTypeComboBoxQuery.toLowerCase());
                    });
            },

            filteredScheduleTypeOptions() {
                return this.scheduleTypeComboBoxQuery === ''
                    ? this.serviceScheduleTypes
                    : this.serviceScheduleTypes.filter((scheduleType) => {
                        return scheduleType.toLowerCase().includes(this.scheduleTypeComboBoxQuery.toLowerCase());
                    });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                    form.expiration_date_start,
                    form.expiration_date_end,
                    form.is_franchised,
                    form.service_account_types,
                    form.service_schedule_types,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>
