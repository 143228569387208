<template>
    <Head title="Employees" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Employees</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="employees" />

                <div class="lg:col-span-9 px-4 sm:px-6">
	                <horizontal-sub-nav current-tab="employees" />

                    <div class="flex flex-row items-center mb-6">
                        <div class="w-1/2">
                            <div>
                                <div class="input-group mt-0">
                                    <span class="input-group-item">
                                        <icon name="search" class="w-4 h-4 fill-current mr-2" />
                                    </span>

                                    <input type="text" v-model="searchForm.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>

                        <div class="ml-auto">
                            <a href="" class="btn btn-orange" @click.prevent="showInviteUserModal">
                                Invite New User
                            </a>
                        </div>
                    </div>

                    <div v-if="users.data.length">
                        <div class="my-6">
                            (Showing <span class="font-semibold text-gray-900">{{ users.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ users.total }}</span>)
                        </div>

                        <div class="flex flex-col">
                            <div class="overflow-x-auto">
                                <table class="table table-condensed">
                                    <thead>
                                        <tr>
                                            <th class="font-semibold tracking-wider"></th>
                                            <sortable-table-header field="first_name" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.users.index">First Name</sortable-table-header>
                                            <sortable-table-header field="last_name" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.users.index">Last Name</sortable-table-header>
                                            <sortable-table-header field="job_title" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.users.index">Job Title</sortable-table-header>
                                            <sortable-table-header field="two_factor_status" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.users.index">2FA Status</sortable-table-header>
                                            <sortable-table-header field="is_active" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.users.index">Active</sortable-table-header>
                                            <th class="w-1/6 font-semibold tracking-wider">Role</th>
                                            <th class="text-right font-semibold tracking-wider">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="user in users.data" :key="user.id">
                                            <td colspan="3">
                                                <div class="flex items-center">
                                                    <div class="flex-shrink-0 h-10 w-10">
                                                        <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline border border-gray-400 rounded-full mr-4 h-10 w-10" alt="User avatar" />

                                                        <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                                            <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                                                        </span>
                                                    </div>

                                                    <div class="ml-4">
                                                        <div class="leading-5">{{ user.first_name }} {{ user.last_name }}</div>
                                                        <div class="text-sm leading-5 text-gray-500">{{ user.email }}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{ user.job_title }}</td>
                                            <td>
                                                <span v-if="user.two_factor_status === 'confirmed'" class="px-2 inline-flex leading-5 rounded-full bg-green-100 text-green-800">
                                                    Active
                                                </span>
                                                <span v-else class="px-2 inline-flex leading-5 rounded-full bg-red-100 text-red-800">
                                                    Inactive
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="user.is_active" class="px-2 inline-flex leading-5 rounded-full bg-green-100 text-green-800">
                                                    Active
                                                </span>
                                                <span v-else class="px-2 inline-flex leading-5 rounded-full bg-red-100 text-red-800">
                                                    Inactive
                                                </span>
                                            </td>
                                            <td>{{ user.role ? user.role.name : 'N/A' }}</td>
                                            <td class="text-right">
                                                <a href="" @click.prevent="showEditUserModal(user)" class="link mr-3">
                                                    Edit
                                                </a>
                                                <a href="" @click.prevent="removeUser(user)" class="link">
                                                    Remove
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <pagination :links="users.links" />
                        </div>
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="users" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Employees Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <!-- User Modal !-->
    <modal role="dialog" ref="userModal" class="max-w-2xl" @closed="emptyModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl">
                    <span class="fal" :class="modalAction === 'Edit' ? 'fa-edit' : 'fa-plus'"></span>

                    <template v-if="modalAction === 'Invite'">
                        Invite New User
                    </template>
                    <template v-else>
                        Editing {{ userForm.first_name }} {{ userForm.last_name }}
                    </template>
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <form>
                    <div class="flex flex-wrap -mx-4 my-2">
                        <text-input v-if="modalAction === 'Invite'" v-model="userForm.email" label="Email" placeholder="Email" :errors="modalErrors.email" class="w-1/2 px-4" mark-as-required />

                        <select-input v-model="userForm.role.id" label="Role" :errors="modalErrors['role.id']" class="w-1/2 px-4" mark-as-required>
                            <option :value="null">Select a Role</option>

                            <option v-for="role in roles" :key="role.id" :value="role.id">
                                {{ role.name }}
                            </option>
                        </select-input>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4">
                <div class="inline-block mr-2">
                    <button type="button" class="btn btn-gray" @click="close">Close</button>
                </div>

                <div class="inline-block">
                    <loading-button :loading="saving" class="inline btn btn-orange" @click="modalCallback">
                        {{ (modalAction === 'Invite') ? 'Send Invitation' : 'Save' }}
                    </loading-button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Modal from '@/Shared/Modal.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import { throttle } from 'lodash-es';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import SubNav from '../SubNav.vue';
    import HorizontalSubNav from "../../TenantSettings/Users/HorizontalSubNav.vue";

    export default {
        components: {
	        HorizontalSubNav,
            Head,
            Icon,
            ToggleSwitchInput,
            TextInput,
            SelectInput,
            TextareaInput,
            LoadingButton,
            Pagination,
            Modal,
            SortableTableHeader,
            SubNav
        },
        
        props: {
            users: {
                type: Object,
                required: true
            },

            roles: Array,

            timeZones: Object,

            filters: Object,

            errors: {
                type: Object,
                default: () => ({}),
            }
        },

        data() {
            return {
                modalAction: '',
                modalCallback: () => {},
                modalErrors: {},
                saving: false,
                searchForm: {
                    search: this.filters.search
                },
                userForm: { email: null, role: { id: null } },
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            showInviteUserModal() {
                this.modalAction = 'Invite';

                this.userForm = { email: null, role: { id: null } }

                this.modalCallback = this.inviteUser;

                this.$refs.userModal.show();
            },

            inviteUser() {
                this.saving = true;

                this.$inertia
                    .post(this.$route('tenant-settings.invitations.store'), this.userForm, {
                        onSuccess: () => this.$refs.userModal.close(),
                        onError: (errors) => this.modalErrors = errors,
                        onFinish: () => this.saving = false
                    });
            },

            showEditUserModal(user) {
                this.modalAction = 'Edit';
                this.modalCallback = this.updateUser;
                this.userForm = { ...user };

                if (user.role) {
                    this.userForm.role = { ...this.roles.find((role) => role.id === user.role.id) };
                } else {
                    this.userForm.role = { id: null };
                }

                this.$refs.userModal.show();
            },

            updateUser() {
                this.saving = true;

                this.$inertia
                    .put(this.$route('tenant-settings.users.update', this.userForm.id), this.userForm, {
                        onSuccess: () => this.$refs.userModal.close(),
                        onError: (errors) => this.modalErrors = errors,
                        onFinish: () => this.saving = false
                    });
            },

            removeUser(user) {
                let confirmed = confirm("Are you sure you wish to perform this action?");

                if (confirmed) {
                    this.$inertia.delete(this.$route('tenant-settings.users.destroy', user.employeeTenantId));
                }
            },

            emptyModal() {
                this.userForm = { email: null, role: { id: null } };
                this.modalErrors = {};
            },

            changeNavigation(e) {
                this.$inertia.get(e.target.value);
            },
        },

        computed: {
            invalidPassword() {
                return this.userForm.password
                    && (/[A-Z]/.test(this.userForm.password) === false
                        || /[@$!%*#?&]/.test(this.userForm.password) === false
                        || /[0-9]/.test(this.userForm.password) === false);
            }
        },

        watch: {
            searchForm: {
                handler: throttle(function() {
                    let query = { ...this.searchForm };
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route('tenant-settings.users.index'), query, {preserveState: true});
                }, 150),

                deep: true,
            },
        }
    }
</script>