<template>
    <Head :title="`Edit Pickup Days Change - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Pickup Days Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
    </div>

    <div class="container mx-auto mt-8 mb-12">
        <div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <revert-input v-model="form.change_requester" class="col-span-2 md:col-span-1" label="Who requested this change?" mark-as-required>
                    <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                        <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                        <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                        <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.actual_date" class="col-span-3 md:col-span-1" label="When is this change effective?" mark-as-required>
                    <date-input :min="serviceCreationActivity.actual_date" v-model="form.actual_date" :errors="errors['actual_date']" />
                </revert-input>

                <revert-input v-model="form.change_reason" class="col-span-3 md:col-span-1" label="Why are you making this change?" mark-as-required>
                    <select-input v-model="form.change_reason">
                        <option>Other</option>
                    </select-input>
                </revert-input>
            </div>

            <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
                <revert-input v-model="form.pickup_rrule" class="col-span-2 md:col-span-1" label="Pickup Schedule" :mark-as-required="serviceHasPickupRrule && (serviceActivity.serviceSnapshot.service_schedule_type === 'Scheduled')">
                    <div v-if="serviceHasPickupRrule && (serviceActivity.serviceSnapshot.service_schedule_type === 'Scheduled')">
                        <div class="text-sm py-3 text-gray-800">
                            {{ rrule_description }} <a href="#" class="" @click.prevent="showUpdatePickupRRuleModal"><icon name="edit" class="inline fill-current w-4 h-4"></icon></a>
                        </div>

                        <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500 text-sm">
                            The pickup schedule is invalid
                        </div>
                    </div>
                    <div v-else class="py-3 font-gray-800 text-sm">
                        Cannot schedule {{ serviceActivity.serviceSnapshot.service_schedule_type.toLowerCase() ?? 'unscheduled '}} services
                    </div>
                </revert-input>
            </div>

            <revert-input v-model="form.internal_change_notes" class="col-span-2" label="Internal Change Notes">
                <textarea-input v-model="form.internal_change_notes" />
            </revert-input>

            <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">Submit Change</loading-button>
        </div>

        <!-- Pickup RRule Modal -->
        <modal v-if="serviceActivity.serviceSnapshot.type === 'Commercial' || serviceActivity.serviceSnapshot.type === 'Doorstep' || serviceActivity.serviceSnapshot.type === 'Rolloff'" class="max-w-2xl" ref="updatePickupRRuleModal" tabindex="-1" role="dialog">
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Pickup Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6" />
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Average Pickups Per Week"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { format, startOfToday } from "date-fns";
    import { rrulestr } from "rrule";
    import Modal from "@/Shared/Modal.vue";
    import RRuleInput from "@/Shared/RRuleInput.vue";
    import TextInput from "@/Shared/TextInput.vue";
    import RevertibleBin from "@/Shared/Services/RevertibleBin.vue";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            TextInput,
            RRuleInput,
            Modal,
            DateInput,
            Icon,
            LoadingButton,
            RevertInput,
            RevertibleBin,
            SelectInput,
            TextareaInput,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            serviceCreationActivity: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    actual_date:                this.serviceActivity.actual_date,
                    change_reason:              this.serviceActivity.reason,
                    change_requester:           this.serviceActivity.change_requester,
                    internal_change_notes:      this.serviceActivity.internal_change_notes,
                    pickup_rrule:               this.serviceActivity.serviceSnapshot.pickup_rrule,
                    average_pickups_per_week:   this.serviceActivity.serviceSnapshot.average_pickups_per_week,
                },
                saving: false,
                today: format(startOfToday(), 'yyyy-MM-dd'),
                mounted: false,
                rrule_description: null,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitChange() {
                this.saving = true;

                // should we schedule this or fire it off immediately?
                // we can call different routes or we could just handle in the controller... not sure which is easiest
                this.$inertia.put(this.$route('services.pickup-days-changes.update', [this.serviceActivity.service_id, this.serviceActivity.id]), {
                    ...this.form,
                }, {
                    onFinish: () => this.saving = false
                });
            },

            showUpdatePickupRRuleModal() {
                this.$refs.updatePickupRRuleModal.show();
            },

            deleteServiceChange() {
                if (window.confirm('Are you sure you want to delete this pickup days change?')) {
                    this.$inertia.delete(this.$route('services.pickup-days-changes.destroy', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id}));
                }
            },
        },

        computed: {
            serviceHasPickupRrule() {
                return ['Commercial', 'Device', 'Doorstep', 'Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },
        }
    }
</script>
