<template>
    <Head :title="`Edit Service Termination Change - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Service Termination Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
    </div>

    <div class="container mx-auto mt-8 mb-12">
        <div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <revert-input v-model="form.change_requester" class="col-span-3 md:col-span-1" label="Who requested this change?" mark-as-required>
                    <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                        <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                        <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                        <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.termination_date" class="col-span-3 md:col-span-1" label="When should this service be terminated?" mark-as-required>
                    <date-input :min="minimumActualDate" v-model="form.termination_date" :errors="errors['termination_date']" mark-as-required />
                </revert-input>

                <revert-input v-model="form.change_reason" class="col-span-3 md:col-span-1" label="Why are you making this change?" mark-as-required>
                    <select-input v-model="form.change_reason">
                        <option>Other</option>
                    </select-input>
                </revert-input>
            </div>

            <revert-input v-model="form.internal_change_notes" class="col-span-3" label="Internal Change Notes">
                <textarea-input v-model="form.internal_change_notes" />
            </revert-input>

            <hr v-if="showPriorPeriodAdjustment" class="col-span-2 my-4" />

            <div class="col-span-2 rounded-md bg-yellow-50 py-2 px-1" v-if="showPriorPeriodAdjustment">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-yellow-800">Prior Period Adjustment</h3>
                        <div class="mt-2 text-sm text-yellow-700">
                            <div class="pr-2">
                                You are changing the termination date on this service from
                                <span class="font-medium">{{ $filters.format_date(serviceActivity.serviceSnapshot.termination_date) }}</span> to
                                <span class="font-medium">{{ $filters.format_date(form.termination_date) }}</span>.
                                <span v-if="priorPeriodAdjustment.lastLineItemBilled">
                                    The service was already billed
                                    <span class="font-medium">{{ $filters.format_money(lastBilledClientLineItemDetails?.lastLineItemBilled.total_amount) }}</span>
                                    for fiscal period
                                    <span class="font-medium">{{ $filters.format_date(lastBilledClientLineItemDetails?.lastLineItemBilled.origin_date) }}</span>.
                                </span>
                                <p class="mt-1">If you wish to bill a prior period adjustment for this change, navigate to the prior period adjustments page after saving this change.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!form.termination_date || !form.change_reason">Submit Change</loading-button>
        </div>
    </div>
</template>

<script>
    /**
     * This page is a Wizard to help user along with service changes
    **/
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { format, startOfToday } from "date-fns";
    import RevertibleBin from "@/Shared/Services/RevertibleBin.vue";
    import axios from "axios";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            DateInput,
            Icon,
            LoadingButton,
            RevertInput,
            RevertibleBin,
            SelectInput,
            TextareaInput,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            serviceCreationActivity: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    change_reason:          this.serviceActivity.reason,
                    change_requester:       this.serviceActivity.change_requester,
                    internal_change_notes:  this.serviceActivity.internal_change_notes,
                    termination_date:       this.serviceActivity.serviceSnapshot.termination_date,
                },
                saving: false,
                lastBilledClientLineItemDetails: null,
                today: format(startOfToday(), 'yyyy-MM-dd'),
                mounted: false,
            }
        },

        methods: {
            submitChange() {
                this.saving = true;

                // should we schedule this or fire it off immediately?
                // we can call different routes or we could just handle in the controller... not sure which is easiest
                this.$inertia.put(this.$route('services.service-termination-changes.update', [this.serviceActivity.service_id, this.serviceActivity.id]), {
                    ...this.form,
                }, {
                    onFinish: () => this.saving = false
                });
            },

            deleteServiceChange() {
                if (window.confirm('Are you sure you want to delete this service termination change?')) {
                    this.$inertia.delete(this.$route('services.service-termination-changes.destroy', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id}));
                }
            },

            suggestPriorPeriodAdjustment() {
                if (!this.$page.props.permissions.accessRevenueManagement) {
                    return false;
                }

                if (this.serviceActivity.serviceSnapshot.is_pass_through) {
                    return false;
                }

                if (!['Monthly', 'Quarterly', 'Yearly'].includes(this.serviceActivity.serviceSnapshot.client_billing_frequency)) {
                    return false;
                }

                if (this.form.service_schedule_type === 'One Time') {
                    return false;
                }

                if (this.serviceActivity.serviceSnapshot.type === 'Rolloff') {
                    return false;
                }

                if (this.form.termination_date === this.serviceActivity.serviceSnapshot.termination_date) {
                    return false;
                }

                return true;
            },

            updateLastBilledClientLineItemDetails() {
                axios.get(`/json/services/${this.service.id}/last-billed-client-line-item-details?maxOriginDate=${this.form.termination_date}`).then(response => {
                    this.lastBilledClientLineItemDetails = response.data
                });
            },
        },

        computed: {
            minimumActualDate() {
                if (this.serviceActivity.serviceSnapshot.effective_date > this.serviceCreationActivity.actual_date) {
                    return this.serviceActivity.serviceSnapshot.effective_date;
                }

                return this.serviceCreationActivity.actual_date;
            },

            showPriorPeriodAdjustment() {
                return this.suggestPriorPeriodAdjustment() && this.lastBilledClientLineItemDetails && this.lastBilledClientLineItemDetails?.lastBillingPeriodEnd > this.form.termination_date
            }
        },

        watch: {
            'termination_date'() {
                if (this.suggestPriorPeriodAdjustment()) {
                    this.updateLastBilledClientLineItemDetails();
                }
            }
        },

        mounted() {
            if (this.suggestPriorPeriodAdjustment()) {
                this.updateLastBilledClientLineItemDetails();
            };

            this.mounted = true;
        }
    }
</script>
