<template>
    <Head :title="`Service - ${service.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ service.id }}</span>
        </nav>
    </Teleport>

    <div v-if="isRebuildingHistory" class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 rounded-md bg-blue-50 p-4 my-3">
        <div class="flex">
            <div class="flex-shrink-0">
                <!-- Heroicon name: solid/information-circle -->
                <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">
                    This service is currently rebuilding its history and current state. Please wait until the rebuild is finished before making any changes.
                </p>
            </div>
        </div>
    </div>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="lg:w-3/5 flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="dumpster" class="h-14 w-14"/>
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ service.id }}
                    <span v-if="service.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{
                            service.external_reference_id ?? 'EXT-123ABC'
                        }}</span>
                    <p v-if="service.deleted_at !== null" class=" text-2xl font-bold text-red-700">(Deleted)</p>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    {{ currentServiceSnapshot.description }}
                </p>
            </div>

            <div v-if="otherUsers.length > 0" class="flex -space-x-2 mb-1">
                <template v-for="user in otherUsers" :key="user.id">
                    <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.initials">
                    <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                        <span class="text-lg font-medium leading-none text-white uppercase">{{ user.initials }}</span>
                        </span>
                </template>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link :href="$route('services.edit', [service.id])" class="btn btn-gray ">Edit</inertia-link>

            <inertia-link :href="$route('incidents.create', {service_id: service.id})" class="btn btn-gray">File
                Incident
            </inertia-link>

            <inertia-link :href="$route('services.work-orders.create', [service.id])" class="btn btn-gray">Open Work
                Order
            </inertia-link>

            <button v-if="service.deleted_at === null" @click.prevent="deleteService" class="btn btn-red">Delete</button>
        </div>
    </div>

    <div
        class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="service-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="service-information-title" class="text-lg leading-6 font-medium text-gray-900">
                            Service Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Service status and details.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Type</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ currentServiceSnapshot.type }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd
                                    class="mt-1 text-sm"
                                    :class="[currentServiceSnapshot.status === 'Active' ? 'text-green-600' : 'text-gray-500']"
                                >
                                    {{ currentServiceSnapshot.status }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Effective Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_date(currentServiceSnapshot.effective_date) }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Termination Date</dt>
                                <dd
                                    v-if="currentServiceSnapshot.termination_date"
                                    class="mt-1 text-sm text-gray-900"
                                >
                                    {{ $filters.format_date(currentServiceSnapshot.termination_date) }}
                                </dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">Not Set</dd>
                            </div>

                            <div v-if="['Commercial', 'Rolloff', 'Doorstep'].includes(currentServiceSnapshot.type)"
                                 class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Service Account Type</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ currentServiceSnapshot.service_account_type }}
                                </dd>
                            </div>

                            <div v-if="['Commercial', 'Rolloff', 'Doorstep'].includes(currentServiceSnapshot.type)">
                                <dt class="text-sm font-medium text-gray-500">Service Schedule Type</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ currentServiceSnapshot.service_schedule_type }}
                                </dd>
                            </div>

	                        <div v-if="service.label" class="sm:col-span-1">
		                        <dt class="text-sm font-medium text-gray-500">Equipment Label</dt>
		                        <dd class="mt-1 text-sm text-wrap text-gray-900">
			                        {{ currentServiceSnapshot.label }}
		                        </dd>
	                        </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Asset Owner</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ currentServiceSnapshot.bin.owner ?? 'Not Set ' }}
                                </dd>
                            </div>

                            <div v-if="['Commercial', 'Rolloff'].includes(service.type)" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Material Type</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ currentServiceSnapshot.bin.material_type }}
                                </dd>
                            </div>

                            <div v-if="['Commercial', 'Rolloff'].includes(service.type)" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Disposal Method</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ currentServiceSnapshot.bin.disposal_method }}
                                </dd>
                            </div>

                            <div v-if="['Commercial', 'Rolloff', 'Doorstep'].includes(service.type)" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Size</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ currentServiceSnapshot.bin.size }}
                                    {{ currentServiceSnapshot.bin.size_metric }}
                                </dd>
                            </div>

                            <template v-if="service.type === 'Device'">
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Pioneer Compactor Monitor</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        <inertia-link
                                            v-if="service.compactorMonitor" class="link"
                                            :href="$route('compactor-monitors.overview', service.compactorMonitor.id)"
                                        >
                                            Manage
                                        </inertia-link>

                                        <template v-else>N/A</template>
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Monitored Service</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        <inertia-link
                                            v-if="compactorMonitor && compactorMonitor.monitored_service_id"
                                            class="link"
                                            :href="$route('services.show', [compactorMonitor.monitored_service_id])"
                                        >
                                            {{ compactorMonitor.monitored_service_id }}
                                        </inertia-link>
                                    </dd>
                                </div>
                            </template>

                            <div v-if="currentServiceSnapshot.location" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Location</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link
                                        class="link"
                                        :href="$route('locations.show', [currentServiceSnapshot.location.id])"
                                    >
                                        {{ currentServiceSnapshot.location.name }}
                                    </inertia-link>

                                    <span class="block">{{ service.location?.address?.full_address }}</span>
                                </dd>
                            </div>

                            <div v-if="service.type === 'Rolloff'" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Tons Included Per Haul</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ currentServiceSnapshot.tons_included_per_haul }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>

            <!-- Billing Details -->
            <section aria-labelledby="billing-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="billing-information-title" class="text-lg leading-6 font-medium text-gray-900">
                            Billing Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">The billing details for this Service.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <span
                                        class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                        <span class="text-lg font-medium leading-none text-white uppercase"></span>
                                    </span>
                                    <inertia-link class="link"
                                                  :href="$route('vendors.show', [currentServiceSnapshot.vendor_id])">
                                        {{ currentServiceSnapshot.vendor.name ?? '' }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor Account</dt>
                                <dd v-if="currentServiceSnapshot?.vendorAccount?.account_number"
                                    class="mt-1 text-sm text-gray-900">
                                    <inertia-link
                                        class="link"
                                        :href="$route('vendor-accounts.show', [currentServiceSnapshot.vendor_account_id])"
                                    >
                                        {{ currentServiceSnapshot.vendorAccount.account_number }}
                                    </inertia-link>
                                </dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">
                                    None
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor Contract</dt>
                                <dd v-if="currentServiceSnapshot.vendor_contract_id" class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link" :href="$route('vendor-contracts.show', [currentServiceSnapshot.vendor_contract_id])">
                                        {{ currentServiceSnapshot.vendorContract.display_id }}
                                    </inertia-link>
                                </dd>
                                <dd v-else-if="!currentServiceSnapshot.vendor_contract_id && currentServiceSnapshot.has_vendor_contract" class="mt-1 text-sm text-gray-900">
                                    Missing vendor contract
                                </dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">
                                    No vendor contract
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Contract</dt>
                                <dd v-if="currentServiceSnapshot.client_contract_id"
                                    class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link"
                                                  :href="$route('client-contracts.show', [currentServiceSnapshot.client_contract_id])">
                                        {{ currentServiceSnapshot.client_contract_id }}
                                    </inertia-link>
                                </dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">
                                    None
                                </dd>
                            </div>

                            <template
                                v-if="['Commercial', 'Device', 'Doorstep'].includes(currentServiceSnapshot.type)">
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Vendor Base Charge</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ $filters.format_money(currentServiceSnapshot.vendor_base_charge) }}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Client Base Charge</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ $filters.format_money(currentServiceSnapshot.client_base_charge) }}
                                    </dd>
                                </div>
                            </template>

                            <template v-if="currentServiceSnapshot.type === 'Doorstep'">
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Vendor Per Unit Charge</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ $filters.format_money(currentServiceSnapshot.vendor_per_unit_charge) }}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Client Per Unit Charge</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ $filters.format_money(currentServiceSnapshot.client_per_unit_charge) }}
                                    </dd>
                                </div>
                            </template>

                            <template v-else-if="currentServiceSnapshot.type === 'Rolloff'">
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Vendor Per Haul Amount</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ $filters.format_money(currentServiceSnapshot.vendor_per_haul_amount) }}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Vendor Disposal Amount per Ton
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{
                                            $filters.format_money(currentServiceSnapshot.vendor_disposal_amount_per_ton)
                                        }}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Client Per Haul Amount</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ $filters.format_money(currentServiceSnapshot.client_per_haul_amount) }}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Client Disposal Amount per Ton
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{
                                            $filters.format_money(currentServiceSnapshot.client_disposal_amount_per_ton)
                                        }}
                                    </dd>
                                </div>
                            </template>

                            <template v-if="currentServiceSnapshot.type === 'Rental'">
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Vendor Base Charge</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ $filters.format_money(currentServiceSnapshot.vendor_base_charge) }}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Client Base Charge</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ $filters.format_money(currentServiceSnapshot.client_base_charge) }}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Equipment Manufacturer</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ currentServiceSnapshot.equipment_manufacturer ?? "Unknown" }}
                                    </dd>
                                </div>
                            </template>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Pass Through</dt>
                                <dd>{{ currentServiceSnapshot.is_pass_through ? 'yes' : 'no' }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Billing Frequency</dt>
                                <dd>{{ currentServiceSnapshot.client_billing_frequency }}</dd>
                            </div>

                            <template v-if="currentServiceSnapshot.type === 'Commercial'">
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Avg. Pickups Per Week</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ currentServiceSnapshot.average_pickups_per_week }}
                                    </dd>
                                </div>
                            </template>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Account</dt>
                                <dd v-if="currentServiceSnapshot.location?.clientAccount"
                                    class="mt-1 text-sm text-gray-900">
                                    <inertia-link v-if="currentServiceSnapshot.location?.clientAccount ?? false"
                                                  :href="$route('client-accounts.edit', currentServiceSnapshot.location.clientAccount.id)"
                                                  class="link">
                                        #{{ currentServiceSnapshot.location.clientAccount.account_number }} <span
                                        class="font-semibold">{{
                                            currentServiceSnapshot.location.clientAccount.name
                                        }}</span>
                                    </inertia-link>
                                    <template v-else>No Client Account Set For This Location</template>
                                </dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">
                                    None
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Client Company</dt>
                                <dd v-if="currentServiceSnapshot.clientContract?.clientCompany"
                                    class="mt-1 text-sm text-gray-900">
                                    <inertia-link
                                        :href="$route('client-companies.show', currentServiceSnapshot.clientContract.client_company_id)"
                                        class="link">
                                        <span class="font-semibold">{{
                                                currentServiceSnapshot?.clientContract?.clientCompany.name
                                            }}</span>
                                    </inertia-link>
                                </dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">
                                    None
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <div class="lg:col-start-3 lg:col-span-1 ">
	        <section v-if="compactorMonitor" aria-labelledby="monitor-information-title" class="pb-1">
		        <div class="bg-white mx-auto  py-5 shadow sm:rounded-lg ">
			        <div class="px-4 py-4 sm:px-6">
			            <h2 id="timeline-title" class="text-lg leading-6 font-medium text-gray-900">Compactor Monitor</h2>
		            </div>
			        <div class="border-t border-gray-200 px-4 pb-4 sm:px-6">
                        <div class="mt-6 flow-root">
                            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div>
                                    <dt class="text-sm font-medium text-gray-500">Monitor</dt>
                                    <dd class="mt-1 text-sm link">
                                        <inertia-link :href="$route('compactor-monitors.overview', compactorMonitor.id)">{{ compactorMonitor.id }} </inertia-link>
                                    </dd>
                                </div>
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Current Fullness</dt>
                                    <dd>
                                        <div v-if="compactorMonitor.monitorConfiguration.percentage_full"
                                                  class="mt-1 text-sm text-gray-900">
                                            {{ compactorMonitor.monitorConfiguration.percentage_full }}%

                                            <span v-if="compactorMonitor.monitorConfiguration.tonnage_capacity"
                                                      class="mt-1 text-sm text-gray-900">
                                                ({{
                                                    ((compactorMonitor.monitorConfiguration.percentage_full / 100) * compactorMonitor.monitorConfiguration.tonnage_capacity).toFixed(1)
                                                }}t est.)
                                            </span>
                                        </div>

									        <div v-else class="mt-1 text-sm text-gray-900">
										        N/A
									        </div>
								        </dd>
							        </div>
							        <div class="sm:col-span-1">
								        <dt class="text-sm leading-5 font-medium text-gray-500">
									        <template
										        v-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'scheduled'">
										        Upcoming Scheduled Pickup
									        </template>

									        <template
										        v-else-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'requested'">
										        Upcoming Requested Pickup
									        </template>

									        <template v-else>
										        Next Forecasted Pickup
										        <template v-if="monitorSummary.projected_pickup_type === 'max_days'">
											        (Max Days)
										        </template>
									        </template>
								        </dt>
							            <dd class="mt-1 text-sm text-gray-900">
									        <div v-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'scheduled'" class="mt-1 text-sm text-gray-900">
										        {{ upcomingPickupDateFormatted }}
									        </div>

									        <div v-else-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'requested'" class="mt-1 text-sm text-gray-900">
										        {{ upcomingPickupDateFormatted }}
										        <span class="block text-sm text-gray-900">
											        {{ daysUntilUpcomingPickup }}
										        </span>
									        </div>

									        <div v-else-if="predictedPickupDateFormatted" class="mt-1 text-sm text-gray-900">
										        {{ predictedPickupDateFormatted }}
										        <span class="block text-sm text-gray-900">
											        {{ daysUntilPredictedPickup }}
										        </span>
									        </div>

									        <div v-else class="mt-1 text-sm text-gray-900">
										        Estimate unavailable
									        </div>
							            </dd>
						            </div>
							        <div class="sm:col-span-1 w-full">
								        <dt class="text-sm font-medium text-gray-500">Last Picked Up</dt>
								        <dd class="text-gray-900">
									        <div v-if="lastPickupWorkOrder">
										        <inertia-link :href="$route('work-orders.edit', lastPickupWorkOrder.id)" class="link text-sm">
											        {{ $filters.format_date(lastPickupWorkOrder.occurred_at) }}
										        </inertia-link>
										        <span class="block text-sm text-gray-900">
											        ({{ daysSinceLastPickup }} days ago)
										        </span>
									        </div>
									        <div v-else-if="compactorMonitor?.monitorConfiguration?.last_picked_up">
										        <span class="mt-1 text-sm text-gray-900">
											        {{
												        $filters.format_date(compactorMonitor.monitorConfiguration.last_picked_up)
											        }}
										        </span>
										        <span class="block mt-1 text-sm text-gray-900">
											        ({{ daysSinceLastPickup }} days ago)
										        </span>
									        </div>
									        <template v-else class="mt-1 text-sm text-gray-900">
										        No pickups recorded
									        </template>
								        </dd>
							        </div>

                            </dl>
                        </div>
			        </div>
		        </div>
	        </section>
            <section aria-labelledby="timeline-title">
                <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                    <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Scheduled Revisions</h2>
                    <p v-if="scheduledChanges.length > 0" class="mt-1 max-w-2xl text-sm text-gray-500">
                        Click a scheduled revision to view the details
                    </p>

                    <!-- Scheduled Revision Feed -->
                    <template v-if="scheduledChanges.length">
                        <div class="mt-6 flow-root">
                            <ul role="list" class="-mb-8">
                                <li v-for="(activity, index) in scheduledChanges" :key="activity.id">
                                    <div class="relative pb-8">
                                        <span v-if="index !== scheduledChanges.length - 1"
                                              class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                              aria-hidden="true"></span>

                                        <div class="relative flex space-x-3">
                                            <div>
                                                <span
                                                    class="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
                                                    <!-- Heroicon name: solid/user -->
                                                    <svg class="w-5 h-5 text-white"
                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                         fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                              clip-rule="evenodd"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                <div>
                                                    <inertia-link :href="$route('service-scheduled-changes.show', [activity.id])" class="text-sm text-gray-500 link">
                                                        {{ activity.change_type }}
                                                    </inertia-link>

                                                    <div class="text-xs text-gray-900">
                                                        {{ activity.scheduledByEmployee.name }}
                                                    </div>
                                                </div>

                                                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                                    <time datetime="2020-09-20">
                                                        {{ $filters.format_date(activity.scheduled_for) }}
                                                    </time>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </template>

                    <span class="text-sm text-gray-700"
                          v-else>There are no scheduled revisions for this service.</span>
                </div>
            </section>

            <section aria-labelledby="timeline-title">
                <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                    <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Timeline</h2>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Click to view changes to service
                    </p>
                    <!-- Revision Feed -->
                    <template v-if="serviceActivities.length">
                        <div class="mt-6 flow-root">
                            <ul role="list" class="-mb-8">
                                <li v-for="(activity, index) in serviceHistoriesPreview" :key="activity.id">
                                    <div class="relative pb-8">
                                        <span v-if="index !== serviceHistoriesPreview.length - 1"
                                              class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                              aria-hidden="true"></span>

                                        <div class="relative flex space-x-3">
                                            <div>
                                                <span
                                                    class="h-8 w-8 rounded-full bg-d-blue-500 flex items-center justify-center ring-8 ring-white">
                                                    <!-- Heroicon name: solid/user -->
                                                    <svg class="w-5 h-5 text-white"
                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                         fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                              clip-rule="evenodd"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                <div>
                                                    <div class="text-sm text-gray-500">{{ activity.change_type }}
                                                        <div v-if="activity.changedBy" class="text-xs text-gray-900">
                                                            {{ activity.changedBy.name }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                                    <a @click.prevent="changeServiceSnapshot(activity.serviceSnapshot)"
                                                       class="text-d-blue-500 hover:text-blue-500 mr-3"
                                                       href=""
                                                       :title="`Recorded Date of Change - ${$filters.format_date_time(activity.recorded_at)} `"
                                                    >
                                                        <time>{{ $filters.format_date(activity.actual_date) }}</time>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="mt-6 flex flex-col justify-stretch">
                            <button @click.prevent="panelOpen = true" type="button"
                                    class="btn btn-gray inline-flex items-center justify-center">View More
                            </button>
                        </div>
                    </template>

                    <template v-else>No Revisions Made Yet</template>
                </div>
            </section>

            <!-- Sticky Note !-->
            <sticky-note
                :content="stickyNoteValue"
                :hideLabel="true"
                :savingRoute="$route('services.sticky-notes-changes.store', [service.id])"
                @saved="stickyNoteValue = $event"
            />
        </div>

        <!-- Service Documents !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-service-fees-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-service-fees-title" class="text-lg leading-6 font-medium text-gray-900">
                                Service Documents</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                Any documents that you wish to store for this service.
                            </p>
                        </div>

                        <div v-if="service.documents.length > 0" class="px-4 py-5 sm:px-6">
                            <text-input type="file" ref="filePendingDocuments"
                                        @change="addPendingFileUploads" multiple/>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="pendingFileUploads.length > 0">
                            <span class="block form-label">Pending Uploads</span>

                            <!-- This example requires Tailwind CSS v2.0+ -->
                            <div v-if="errors?.pendingFileUpload?.file" class="rounded-md bg-red-50 p-4 my-4">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <!-- Heroicon name: solid/x-circle -->
                                        <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                    </div>

                                    <div class="ml-3">
                                        <h3 class="text-sm font-medium text-red-800">There were errors with your
                                            uploads</h3>
                                        <div class="mt-2 text-sm text-red-700">
                                            <ul role="list" class="list-disc pl-5 space-y-1">
                                                <li>{{ errors?.pendingFileUpload?.file }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- This example requires Tailwind CSS v2.0+ -->
                            <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                <li v-for="(upload, index) in pendingFileUploads" :key="index"
                                    class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div class="w-full flex items-center justify-between p-6 space-x-6">
                                        <div class="flex-1 truncate">
                                            <div class="flex items-center space-x-3">
                                                <h3 class="text-gray-900 text-sm font-medium truncate">
                                                    {{ upload.file.name }}</h3>
                                                <span
                                                    class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{
                                                        $filters.format_file_size(upload.file.size)
                                                    }}</span>
                                            </div>
                                            <p class="mt-1 text-gray-500 text-sm truncate">{{
                                                    upload.file.type
                                                }}</p>
                                        </div>
                                    </div>

                                    <div class="w-full p-3">
                                        <textarea-input label="Notes" v-model="upload.notes"
                                                        :errors="errors?.pendingFileUpload?.notes"
                                                        :autosize="true"/>
                                    </div>

                                    <div>
                                        <div class="-mt-px flex divide-x divide-gray-200">
                                            <div class="w-0 flex-1 flex">
                                                <a href="" @click.prevent="storePendingFileUpload(upload)"
                                                   :loading="upload.uploading"
                                                   class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                                                    <icon v-if="upload.uploading" name="spinner"
                                                          class="inline fill-current text-gray-400 w-5 h-5 animate-spin"/>
                                                    <icon v-else class="inline fill-current w-4 h-4" name="upload"/>

                                                    <span class="ml-3">Upload</span>
                                                </a>
                                            </div>

                                            <div class="-ml-px w-0 flex-1 flex">
                                                <a href="" @click.prevent="removePendingFileUpload(file)"
                                                   class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                                                    <icon name="trash" class="w-4 h-4 fill-current"/>
                                                    <span class="ml-3">Remove</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div v-if="service.documents.length" class="flex flex-col justify-between">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                File Name
                                            </th>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Upload Date
                                            </th>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Notes
                                            </th>
                                            <th scope="col"
                                                class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="document in service.documents" :key="document.id">
                                            <td>
                                                <div class="flex">
                                                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400"
                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                         fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                                              clip-rule="evenodd"/>
                                                    </svg>
                                                    <a :href="$route('documents.preview', { path: document.file_path })"
                                                       class="ml-2 font-medium text-blue-600 hover:text-blue-500" target="_blank">
                                                        {{ document.file_name }}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>{{$filters.format_date_time(document.created_at)}}</td>
                                            <td class="truncate max-w-sm">{{ document.notes }}</td>
                                            <td>
                                                <inertia-link :href="$route('service-documents.edit', [document.id])"
                                                              class="link">
                                                    Edit
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="mt-6 text-center">
                            <label class="flex flex-col items-center justify-center px-4 py-2 cursor-pointer">
                                <icon name="file-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                                <h3 class="mt-2 text-sm font-medium text-gray-900">No documents available</h3>

                                <p class="mt-1 text-sm text-gray-500">Upload a document for this service.</p>

                                <input type="file" ref="filePendingDocuments" class="hidden"
                                       @change="addPendingFileUploads" multiple/>
                            </label>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Vendor Service Fees !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-service-fees-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-service-fees-title" class="text-lg leading-6 font-medium text-gray-900">
                                Vendor Service Fees</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                These are fees billed by the hauler for this service.
                                <!-- Adding these fees to your service can increase your auditing capabilities. -->
                            </p>
                        </div>

                        <div v-if="service.vendorServiceFees.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('services.vendor-service-fees.create', [service.id])"
                                          class="btn btn-orange">New
                            </inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="service.vendorServiceFees.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Name
                                            </th>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Percentage
                                            </th>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Amount
                                            </th>
                                            <th scope="col"
                                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                Restriction
                                            </th>
                                            <th scope="col"
                                                class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="fee in service.vendorServiceFees" :key="fee.id">
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                <inertia-link class="link" :href="$route('vendor-service-fees.edit', [fee.id])">
                                                    {{ fee.name }}
                                                </inertia-link>
                                            </td>
                                            <td :class="{'text-gray-500': !fee.percentage}">
                                                {{ fee.percentage ?? 'N/A' }}
                                            </td>
                                            <td :class="{'text-gray-500': !fee.amount}">
                                                {{ fee.amount ? $filters.format_money(fee.amount) : 'N/A' }}
                                            </td>
                                            <td class="">{{ fee.restriction }}</td>
                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <inertia-link :href="$route('vendor-service-fees.edit', [fee.id])" class="link">
                                                    Edit<span class="sr-only">, {{ fee.id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="badge-dollar" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No vendor service fees</h3>
                            <p class="mt-1 text-sm text-gray-500">Add a new vendor service fee.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('services.vendor-service-fees.create', [service.id])"
                                              class="btn btn-orange">
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    New Fee
                                </inertia-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Service Fees !-->
        <div v-if="$page.props.permissions.accessRevenueManagement" class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="client-service-fees-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="client-service-fees-title" class="text-lg leading-6 font-medium text-gray-900">
                                Client Service Fees</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                These are additional fees we bill the client.
                            </p>
                        </div>

                        <div v-if="service.clientServiceFees.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('services.client-service-fees.create', [service.id])"
                                          class="btn btn-orange">New
                            </inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="service.clientServiceFees.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Label</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Billing Style</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Category</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Start Date</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">End Date</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Frequency</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Amount</th>
                                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="fee in service.clientServiceFees" :key="fee.id">
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                <inertia-link class="link" :href="$route('client-service-fees.edit', [fee.id])">
                                                    {{ fee.label }}
                                                </inertia-link>
                                            </td>
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                {{ fee.billing_style }}
                                            </td>
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                {{ fee.category.name }}
                                            </td>
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                {{ $filters.format_date(fee.start_date) }}
                                            </td>
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                {{ $filters.format_date(fee.end_date) }}
                                            </td>
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                {{ fee.frequency }}
                                            </td>
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                {{ fee.billing_style === 'Fixed' ? $filters.format_money(fee.amount) : 'N/A' }}
                                            </td>
                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <inertia-link :href="$route('client-service-fees.edit', [fee.id])"
                                                              class="link">
                                                    Edit<span class="sr-only">, {{ fee.id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="badge-dollar" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No client service fees</h3>
                            <p class="mt-1 text-sm text-gray-500">Add a new client service fee.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('services.client-service-fees.create', [service.id])"
                                              class="btn btn-orange">
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    New Fee
                                </inertia-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Incidents !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="incidents-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="incidents-title" class="text-lg leading-6 font-medium text-gray-900">Incidents</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Recent Incidents for this service.</p>
                        </div>

                        <div v-if="incidents.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('incidents.create', {service_id: service.id})"
                                          class="btn btn-orange">New
                            </inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="incidents.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"
                                                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                                                    #
                                                </th>
                                                <th scope="col"
                                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                    Status
                                                </th>
                                                <th scope="col"
                                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                    Priority
                                                </th>
                                                <th scope="col"
                                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                    Reason
                                                </th>
                                                <th scope="col"
                                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                    Reported
                                                </th>
                                                <th scope="col"
                                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                    Source
                                                </th>
                                                <th scope="col"
                                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
                                                    Assigned To
                                                </th>
                                                <th scope="col"
                                                    class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="incident in incidents.slice(0, 12)" :key="incident.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    {{ incident.display_id }}
                                                </td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                    {{ incident.status }}
                                                </td>
                                                <td>{{ incident.priority }}</td>
                                                <td>{{ incident.reason.name }}</td>
                                                <td>{{ $filters.format_date_time(incident.reported_at) }}</td>
                                                <td>{{ incident.source }}</td>
                                                <td>
                                                    <template v-if="incident.assignedTo"
                                                              class="mt-1 text-sm text-gray-900">
                                                        <img v-if="incident.assignedTo?.avatar_thumbnail_url"
                                                             :src="incident.assignedTo?.avatar_thumbnail_url"
                                                             class="inline border border-gray-400 rounded-full mr-4 h-10 w-10"/>

                                                        <span v-else
                                                              class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                                                <span
                                                                    class="text-lg font-medium leading-none text-white uppercase">{{
                                                                        incident.assignedTo?.first_name[0]
                                                                    }}{{ incident.assignedTo?.last_name[0] }}</span>
                                                            </span>
                                                        <span>{{ incident.assignedTo?.name }}</span>
                                                    </template>
                                                    <template v-else>Unassigned</template>
                                                </td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('incidents.edit', [incident.id])"
                                                                  class="link">
                                                        Edit<span class="sr-only">, {{ incident.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="tasks" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No incidents</h3>
                            <p class="mt-1 text-sm text-gray-500">File an incident for this service.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('incidents.create', {service_id: service.id})"
                                              class="btn btn-orange">
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    File Incident
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="incidents.length">
                        <inertia-link :href="$route('incidents.index', {service_id: service.id, statuses: incidentStatuses})"
                                      class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                            View All
                        </inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Work Orders !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="work-orders-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="work-orders-title" class="text-lg leading-6 font-medium text-gray-900">Work
                            Orders</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Recent Work Orders for this service.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="workOrders.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Status
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Requested Action
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Location Name
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Service Address
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Requested By
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Scheduled For
                                            </th>
                                            <th scope="col"
                                                class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="workOrder in workOrders.slice(0, 12)" :key="workOrder.id">
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                {{ workOrder.decorated_status }}
                                            </td>
                                            <td>{{ workOrder.requested_action }}</td>
                                            <td>
                                                <inertia-link
                                                    :href="$route('locations.show', [service.location_id])"
                                                    class="link">
                                                    {{ service.location.name }}
                                                </inertia-link>
                                            </td>
                                            <td>{{ service.location.address.full_address }}</td>
                                            <td>
                                                <span v-if="workOrder.requestedBy"
                                                      class="font-medium">{{ workOrder.requestedBy.name }}</span>
                                                <span v-else class="font-400 italic">{{ workOrder.source }}</span>
                                            </td>
                                            <td>{{
                                                    $filters.format_date(workOrder.scheduled_with_vendor_date)
                                                }}
                                            </td>
                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <inertia-link :href="$route('work-orders.edit', [workOrder.id])"
                                                              class="link">
                                                    Edit<span class="sr-only">, {{ workOrder.display_id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="clipboard" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No work orders</h3>
                            <p class="mt-1 text-sm text-gray-500">Open a work order for this service.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('services.work-orders.create', [service.id])"
                                              class="btn btn-orange">
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    Open Work Order
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="workOrders.length">
                        <inertia-link :href="$route('work-orders.index', {search: service.id})"
                                      class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                            View All
                        </inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Pickups !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="pickup-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="pickups-title" class="text-lg leading-6 font-medium text-gray-900">Pickups</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Pickups for this service.</p>
                        </div>

                        <div v-if="pickups.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link
                                :href="$route('pickups.create', {
                                    service_id: service.id,
                                    location_id: service.location_id,
                                    material_type: service.bin.material_type,
                                    disposal_method: service.bin.disposal_method,
                                    container_size: `${service.bin.quantity * service.bin.size} ${service.bin.size_metric}`
                                })"
                                class="btn btn-orange"
                            >
                                New
                            </inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="pickups.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Source
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Start Date
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Weight Value
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Weight Unit
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Disposal Method
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Estimated Percent Full
                                            </th>
                                            <th scope="col"
                                                class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="pickup in pickups.slice(0, 12)" :key="pickup.id">
                                            <td>{{ pickup.source }}</td>
                                            <td>{{ $filters.format_date(pickup.start_date) }}</td>
                                            <td>{{ pickup.weight_value }}</td>
                                            <td>{{ pickup.weight_unit }}</td>
                                            <td>{{ pickup.disposal_method }}</td>
                                            <td>{{ pickup.estimated_percent_full }}</td>
                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <inertia-link :href="$route('pickups.show', [pickup.id])"
                                                              class="link mr-3">
                                                    View<span class="sr-only">, {{ pickup.display_id }}</span>
                                                </inertia-link>
                                                <inertia-link :href="$route('pickups.edit', [pickup.id])"
                                                              class="link">
                                                    Edit<span class="sr-only">, {{ pickup.display_id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="clipboard" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No pickups</h3>
                            <p class="mt-1 text-sm text-gray-500">Record a pickup for this service.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('pickups.create')"
                                              class="btn btn-orange">
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    Record Pickup
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="pickups.length">
                        <inertia-link :href="$route('pickups.index', {search: service.id})"
                                      class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                            View All
                        </inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Vendor Invoices !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-invoices-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-invoices-title" class="text-lg leading-6 font-medium text-gray-900">Vendor
                            Invoices</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Recent Vendor Invoices for this service.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="vendorInvoices.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Status
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Invoice Date
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Fiscal Period
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Invoice #
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Total
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Adjusted Total
                                            </th>
                                            <th scope="col"
                                                class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="vendorInvoice in vendorInvoices.slice(0, 12)"
                                            :key="vendorInvoice.id">
                                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                {{ vendorInvoice.status }}
                                            </td>
                                            <td>{{ $filters.format_date(vendorInvoice.invoice_date) }}</td>
                                            <td>{{ $filters.format_date(vendorInvoice.fiscal_period) }}</td>
                                            <td>{{ vendorInvoice.invoice_number }}</td>
                                            <td>{{ $filters.format_money(vendorInvoice.total_current_charges) }}</td>
                                            <td>{{ $filters.format_money(vendorInvoice.adjusted_total_current_charges) }}</td>
                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <a :href="$route('vendor-invoices.pdf', vendorInvoice.id)"
                                                   class="link" target="_blank">
                                                    <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5 mb-1 mr-3"/>
                                                </a>
                                                <inertia-link
                                                    :href="$route('vendor-invoices.edit', [vendorInvoice.id])"
                                                    class="link">
                                                    Edit<span class="sr-only">, {{ vendorInvoice.id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-invoice" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Vendor Invoices</h3>
                            <p class="mt-1 text-sm text-gray-500">
                                Discovery hasn't received any vendor invoices for this service yet.
                            </p>
                        </div>
                    </div>

                    <div v-if="vendorInvoices.length">
                        <inertia-link :href="$route('vendor-invoices.index', {search: service.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                            View All
                        </inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Line Items !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="client-line-items-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="client-line-items-title" class="text-lg leading-6 font-medium text-gray-900">
                            Client Line Items
                        </h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">
                            Recent Client Line Items for this service.
                        </p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="clientLineItems.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Document Date
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Document #
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Total
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Service Date
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Category
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Line Total
                                            </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">
                                                Document Status
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="lineItem in clientLineItems.slice(0, 12)" :key="lineItem.id">
                                            <td>
                                                <span v-if="lineItem.clientInvoice">{{
                                                        $filters.format_date(lineItem.clientInvoice.invoice_date)
                                                    }}</span>
                                                <span v-else-if="lineItem.clientCreditMemo">{{
                                                        $filters.format_date(lineItem.clientCreditMemo.document_date)
                                                    }}</span>
                                            </td>
                                            <td>
                                                <inertia-link v-if="lineItem.clientInvoice" class="link" :href="$route('client-invoices.show', [lineItem.clientInvoice.id])">
                                                    {{ lineItem.clientInvoice.invoice_number }}
                                                </inertia-link>
                                                <inertia-link v-else-if="lineItem.clientCreditMemo" class="link" :href="$route('client-credit-memos.edit', [lineItem.clientCreditMemo.id])">
                                                    {{ lineItem.clientCreditMemo.document_number }}
                                                </inertia-link>
                                            </td>
                                            <td>
                                                <span v-if="lineItem.clientInvoice">{{
                                                        $filters.format_money(lineItem.clientInvoice.total_amount_billed)
                                                    }}</span>
                                                <span v-else-if="lineItem.clientCreditMemo">{{
                                                        $filters.format_money(lineItem.clientCreditMemo.total_amount)
                                                    }}</span>
                                            </td>
                                            <td>{{ $filters.format_date(lineItem.service_date) }}</td>
                                            <td>{{ lineItem.category }}</td>
                                            <td>{{ $filters.format_money(lineItem.total_amount) }}</td>
                                            <td>
                                                <span v-if="lineItem.clientInvoice">{{
                                                        lineItem.clientInvoice.status
                                                    }}</span>
                                                <span v-else-if="lineItem.clientCreditMemo">{{
                                                        lineItem.clientCreditMemo.status
                                                    }}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-invoice" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Client Line Items</h3>
                            <p class="mt-1 text-sm text-gray-500">Discovery hasn't generated any client line items
                                for this service yet.</p>
                        </div>
                    </div>

                    <div v-if="clientLineItems.length">
                        <inertia-link :href="$route('client-invoices.index', {search: service.id})"
                                      class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                            View All
                        </inertia-link>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <TransitionRoot as="template" :show="panelOpen">
        <Dialog as="div" class="relative z-10" @close="panelOpen = false">
            <div class="fixed inset-0"/>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                                         enter="transform transition ease-in-out duration-500 sm:duration-700"
                                         enter-from="translate-x-full" enter-to="translate-x-0"
                                         leave="transform transition ease-in-out duration-500 sm:duration-700"
                                         leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-full max-w-3xl">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl overflow-y-scroll">
                                    <div class="flex-1 relative">
                                        <div class="bg-d-navy-500 py-6 px-4 sm:px-6 ">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-lg font-medium text-white">
                                                    Service Changes
                                                </DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                            class="rounded-md bg-d-blue-700 text-d-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            @click="open = false">
                                                        <span class="sr-only">Close panel</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-white w-3/4 lg:w-full">Click the dates to see the changes <span class="font-bold underline ">temporarily</span> reflected to the left</p>
                                            </div>
                                        </div>

                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 lg:px-0">
                                                <div class="space-y-6 p-4">
                                                    <toggle-switch-input label="Sort By Recorded Date" class="my-2" v-model="serviceChangesToggle"></toggle-switch-input>
                                                </div>
                                                <div class="space-y-6 pt-6 pb-5 relative overflow-auto">
                                                    <table class="table table-condensed">
                                                        <thead>
                                                        <tr>
                                                            <th title="Date that the activity actually occurred" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 truncate">Actual Date</th>
                                                            <th title="Date that the activity was recorded" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 truncate">Recorded Date</th>
                                                            <th title="Who initiated this activity" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Changed By</th>
                                                            <th title="Type of activity that occurred" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                                            <th></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody class="divide-y divide-gray-200 bg-white">
                                                        <tr v-for="history in serviceChanges" :key="history.id">
                                                            <td class="py-2">
                                                                <button
                                                                    @click.prevent="changeServiceSnapshot(history.serviceSnapshot)"
                                                                    class="btn btn-orange  mr-3"
                                                                    :title="`Actual Date of Change - ${$filters.format_date(history.actual_date)} `">
                                                                    {{ $filters.format_date(history.actual_date) }}
                                                                </button>
                                                            </td>
                                                            <td :title="$filters.format_date_time(history.recorded_at)">
                                                                {{ $filters.format_date_time(history.recorded_at) }}
                                                            </td>
                                                            <td class="truncate py-4">{{ getEmployeeName(history.changed_by_employee_id) }}</td>
                                                            <td class="truncate py-4">{{ history.change_type }}</td>
                                                            <td class="text-right">
                                                                <span v-if="history.change_type === 'Activation'">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.service-activation-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="['Creating Service', 'Service Created via Bulk Import'].includes(history.change_type)">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.service-creation-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="['Client Billing Change', 'Client annual price increase'].includes(history.change_type)">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.client-billing-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="['Client Contract Change', 'Attached to client contract'].includes(history.change_type)">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.client-contract-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="history.change_type === 'Level of Service Change'">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.level-of-service-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="history.change_type === 'Pickup Days Change'">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.pickup-days-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="history.change_type === 'Terminating Service'">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.service-termination-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="history.change_type === 'Termination Date Changed'">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.service-termination-date-changes.show', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray px-3 font-bold">View</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray px-3 disabled">View</button>
                                                                </span>
                                                                <span v-else-if="['Vendor account replacement', 'Vendor Account Change'].includes(history.change_type)">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.vendor-account-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="history.change_type === 'Vendor Contract Change'">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.vendor-contract-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="history.change_type === 'Vendor Pricing Change'">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.vendor-pricing-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                                                </span>
                                                                <span v-else-if="Object.keys(history.changes ?? {}).length">
                                                                    <inertia-link v-if="state === 'passive'" :href="$route('services.service-updates.show', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray px-3 font-bold">View</inertia-link>
                                                                    <button v-else disabled class="btn btn-gray px-3 disabled">View</button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                        <button type="button" class="btn btn-gray" @click="panelOpen = false">
                                            Close
                                        </button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { nextTick } from 'vue';
    import { cloneDeep } from 'lodash-es';

    // Components
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import StickyNote from '@/Shared/StickyNote.vue';

    // Headless UI Components
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import {differenceInCalendarDays, format, parseISO} from "date-fns";

    export default {
        components: {
            DateInput,
            Icon,
            TextInput,
            TextareaInput,
            LoadingButton,
            ToggleSwitchInput,
            Head,
            Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            StickyNote
        },

        props: {
            service: {
                type: Object,
                required: true
            },

            assetOwners: {
                type: Object,
                required: true
            },

            compactorMonitor: {
                type: Object,
                default: () => ({})
            },

	        lastPickupWorkOrder: {
				type: Object,
	        },

	        daysSinceLastPickup: {
				required: false
			},

	        monitorSummary: {
		        type: Object,
		        required: false
	        },


	        deviceService: {
                type: Object,
                default: () => ({})
            },

            isRebuildingHistory: {
                type: Boolean,
                required: true
            },

            scheduledChanges: {
                type: Array,
                required: true
            },

            serviceActivities: {
                type: Array,
                required: true
            },

            vendorInvoices: {
                type: Array,
                required: true,
            },

            clientLineItems: {
                type: Array,
                required: true,
            },

            workOrders: {
                type: Array,
                required: true,
            },

            employees: {
                type: Array,
                required: true,
            },

            incidents: {
                type: Array,
                required: true,
            },

            incidentStatuses: {
                type: Array,
                required: true,
            },

            pickups: {
                type: Array,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },
        },

        data() {
            return {
                currentServiceSnapshot: cloneDeep(this.service),
                monitorConfiguration: this.compactorMonitor ? {...this.compactorMonitor.monitorConfiguration} : null,
                monitorConfigurationForm: this.compactorMonitor ? cloneDeep(this.compactorMonitor.monitorConfiguration) : null,
                panelOpen: false,
                pendingFileUploads: [],
                serviceChangesToggle: false,
                serviceChangesSort: 'actual_date',
                serviceChanges: cloneDeep(this.serviceActivities),
                otherUsers: [],
                state: this.isRebuildingHistory ? 'rebuilding' : 'passive',
                savingNote: false,
                mounted: false,
                stickyNoteValue: this.service.sticky_notes,
            }
        },

        mounted() {
            Echo.join(`services.${this.service.id}`)
                .here((users) => {
                    this.otherUsers = users;
                })
                .joining((user) => {
                    this.otherUsers.push(user);
                })
                .leaving((user) => {
                    this.otherUsers = this.otherUsers.filter(otherUser => otherUser.id !== user.id);
                })
                .listen('.App\\Events\\Service\\ReplayServiceProjectorJobHasStarted', (e) => {
                    this.$toast.success('Rebuilding service history, please wait a moment.');
                    this.state = 'rebuilding';
                })
                .listen('.App\\Events\\Service\\ReplayServiceProjectorJobHasFinished', (e) => {
                    this.$toast.success('Service history has been rebuilt.');
                    this.state = 'passive';
                    this.$inertia.get(this.$route('services.show', this.service.id));
                });

            this.mounted = true;
        },

        unmounted() {
            Echo.leave(`services.${this.service.id}`);
        },

        methods: {
            addPendingFileUploads(e) {
                [...e.target.files].forEach(file => {
                    let pendingFileUpload = {
                        file: file,
                        name: file.name,
                        notes: '',
                        uploading: false
                    };

                    this.pendingFileUploads.push(pendingFileUpload);
                    this.$refs.filePendingDocuments.value = '';
                });
            },

            deleteService() {
                if (window.confirm('Are you sure you want to delete this service? This will delete all pickups associated with this service.')) {
                    this.$inertia.delete(this.$route('services.destroy', this.service.id));
                }
            },

            storePendingFileUpload(pendingDocument) {
                if (pendingDocument.uploading !== true) {
                    let formData = new FormData();
                    formData.append('file', pendingDocument.file);
                    formData.append('notes', pendingDocument.notes);
                    pendingDocument.uploading = true;

                    this.$inertia.post(this.$route('services.service-documents.store', this.service.id), formData, {
                        errorBag: 'pendingFileUpload',
                        preserveScroll: true,
                        onSuccess: () => {
                            this.pendingFileUploads.splice(this.pendingFileUploads.indexOf(pendingDocument), 1);
                        },
                        onFinish: () => pendingDocument.uploading = false,
                    });
                }
            },

            removePendingFileUpload(pendingFileUpload) {
                this.pendingFileUploads.splice(this.pendingFileUploads.indexOf(pendingFileUpload), 1);
            },

            removeDocument(serviceDocument) {
                let confirmed = confirm("Are you sure you wish to perform this action?");

                if (confirmed) {
                    this.$inertia
                        .delete(this.$route('service-documents.destroy', serviceDocument.id), {
                            preserveScroll: true,
                            onSuccess: () => {

                            },
                            onFinish: () => {

                            },
                        });
                }
            },

            getEmployeeName(id) {
                let name = 'System';
                this.employees.forEach(employee => {
                    if (employee.id === id) {
                        name = employee.first_name.slice(0,1) + '.' + employee.last_name;
                    }
                });
                return name;
            },

            changeServiceSnapshot(snapshot) {
                this.currentServiceSnapshot = snapshot;
                if (screen.width <= 760) {
                    window.scrollTo(0, 0);
                }
            },
        },

        computed: {
            monitoredServiceHasVendor() {
                return this.compactorMonitor?.monitoredService?.vendorContract?.vendor ?? false;
            },

            vendorHasWorkOrderEmailAddress() {
                return (
                    this.monitoredServiceHasVendor &&
                    this.compactorMonitor.monitoredService.vendorContract.vendor.work_order_request_email_address
                );
            },

            serviceHistoriesPreview() {
                return this.serviceActivities
                    .slice(0, 6);
            },

	        predictedPickupDateFormatted() {
		        if (!this.compactorMonitor.monitorConfiguration.projected_pickup_date) {
			        return '';
		        }

		        let predictedPickupDate = parseISO(this.compactorMonitor.monitorConfiguration.projected_pickup_date);

		        return `${format(predictedPickupDate, 'iii, MMM do')} \n `;
	        },

	        daysUntilPredictedPickup() {
		        if (!this.monitorSummary.projected_pickup_date) {
			        return '';
		        }
		        let predictedPickupDate = parseISO(this.compactorMonitor.monitorConfiguration.projected_pickup_date);


		        return `(${differenceInCalendarDays(predictedPickupDate, new Date())} days)`;
	        },

	        upcomingPickupDateFormatted() {
		        if (!this.monitorSummary.upcoming_pickup_date) {
			        return '';
		        }

		        return `${format(parseISO(this.monitorSummary.upcoming_pickup_date), 'iii, MMM do')}`;
	        },

	        daysUntilUpcomingPickup() {
		        if (!this.monitorSummary.upcoming_pickup_date) {
			        return '';
		        }

		        return `(${differenceInCalendarDays(parseISO(this.monitorSummary.upcoming_pickup_date), new Date())} days)`;
	        },
        },

        watch: {
            'monitorConfigurationForm.send_external_notifications': function (newValue) {
                if (this.vendorHasWorkOrderEmailAddress) {
                    this.monitorConfigurationForm.notify_vendor_notification_contacts = newValue;
                }
            },
            serviceChangesToggle: function (newValue) {
                if (newValue) {
                    this.serviceChangesSort = 'recorded_date';
                    this.serviceChanges.sort((a, b) => new Date(b.recorded_at) - new Date(a.recorded_at));
                } else {
                    this.serviceChangesSort = 'actual_date';
                    this.serviceChanges.sort((a, b) => (new Date(b.actual_date) - new Date(a.actual_date)));
                }
            },
        }
    }
</script>
