<template>
    <div>
	    <label v-if="label" class="form-label flex" :for="id" :aria-required="markAsRequired">
		    <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
		    <tooltip-icon v-if="tooltip" :tooltip="tooltip" :type="tooltipType"/>
	    </label>

        <p v-if="helpText" class="mt-2 text-sm text-gray-600">{{ helpText }}</p>

        <input :id="id" ref="input" v-bind="$attrs" class="form-input" :class="{ error: errors }" type="date" :value="modelValue" :disabled="disabled" @input="$emit('update:modelValue', $event.target.value)">
        
        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>
    import { v4 as uuid } from 'uuid';
    import Icon from "./Icon.vue";
    import TooltipIcon from "./TooltipIcon.vue";

    export default {
	    components: {TooltipIcon, Icon},
        props: {
            id: {
                type: String,
                default() {
                    return `date-input-${uuid()}`;
                },
            },

            modelValue: {
                required: true
            },

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            },

	        tooltip: {
		        default: ''
	        },

	        tooltipType: {
		        type: String,
		        default: 'text'
	        }
        },

        data () {
            return {

            };
        },

        methods: {
            focus() {
                this.$refs.input.focus()
            },

            select() {
                this.$refs.input.select()
            },
        },

        watch: {
            modelValue(newValue, oldValue) {
                this.$emit('change', newValue);
            }
        }
    }
</script>