<template>
    <Head title="Client Accounts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Client Accounts</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="client-account-search-form" class="grid grid-cols-6 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-6 sm:col-span-2">
            <label for="search-client-accounts" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-client-accounts" id="search-client-accounts" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('client-accounts.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="clientAccounts.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ clientAccounts.from }} - {{ clientAccounts.to }} out of {{ clientAccounts.total }} Client Accounts</div>

        <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
            <inertia-link data-cy="client-accounts.index.create-link" :href="$route('client-accounts.create')" class="btn btn-orange mr-2">
                New
            </inertia-link>

            <loading-button :loading="state === 'exporting'" class="btn btn-gray" @click="exportData" :disabled="!clientAccounts.data.length">
                Export to CSV
            </loading-button>
        </div>
    </div>

    <div>
        <div class="overflow-x-auto">
            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <sortable-table-header field="account_number" :filters="filters" route-name="client-accounts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Account Number</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Name')" field="name" :filters="filters" route-name="client-accounts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Client Company')" field="client_companies.name" :filters="filters" route-name="client-accounts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client Company</sortable-table-header>
                        <th scope="col" class="w-36 px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="clientAccount in clientAccounts.data" :key="clientAccount.id">
                        <td>
                            <inertia-link data-cy="client-accounts.index.show-link" :href="$route('client-accounts.show', [clientAccount.id])" class="link text-left">
                                {{ clientAccount.account_number }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Name')">
                            {{ clientAccount.name }}
                        </td>
                        <td v-show="form.selected_headers.includes('Client Company')">
                            <inertia-link :href="$route('client-companies.show', clientAccount.client_company_id)" class="link">
                                {{ clientAccount.clientCompany.name }}
                            </inertia-link>
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('client-accounts.show', [clientAccount.id])" class="link mr-3" title="View this Client Account">
                                View
                            </inertia-link>

                            <inertia-link :href="$route('client-accounts.edit', [clientAccount.id])" class="link" title="Edit this Client Account">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="clientAccounts.links" />
    </div>

    <div v-if="!clientAccounts.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="address-book" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Client Accounts Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="client-account-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.client_company_ids" multiple>
                        <ComboboxLabel class="form-label" for="clientCompanies">Client Companies</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="client_company_ids" name="client_company_ids" class="form-input" @change="clientCompanyComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="clientCompanies.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="clientCompany in filteredClientCompaniesOptions" :key="clientCompany.id" :value="clientCompany.id" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ clientCompany.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';
    import SlideOver from "@/Shared/SlideOver.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            Pagination,
            SortableTableHeader,
            CopyToClipboardButton,
            LoadingButton,
            SelectInput,
            Head,
            ColumnSelector,
            SlideOver,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            clientAccounts: {
                type: Object,
                required: true
            },

            clientCompanies: {
                type: Array,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    client_company_ids: this.filters.client_company_ids,
                    selected_headers: this.filters.selected_headers,
                },
                headers: [],
                excludedHeaders: ['Account Number', 'Actions'],
                state: 'passive',
                mounted: false,
                clientCompanyComboBoxQuery: '',
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            this.filtersInUse = this.getFiltersUsed;


            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.client-accounts.index'), this.queryFilters, {
                    onFinish: () => {
                        this.state = 'passive';
                    }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table;// Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
                this.headers = headers;
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('client-accounts.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredClientCompaniesOptions() {
                return this.clientCompanyComboBoxQuery === ''
                    ? this.clientCompanies
                    : this.clientCompanies.filter((clientCompany) => {
                        return clientCompany.toLowerCase().includes(this.clientCompanyComboBoxQuery.toLowerCase());
                    });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.client_company_ids,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>