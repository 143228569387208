<template>
    <Head title="Invitations" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Invitations</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="employees" />

                <div class="lg:col-span-9 px-4 sm:px-6">
	                <horizontal-sub-nav current-tab="invitations" />

                    <div class="flex flex-row items-center mb-6">
                        <div class="w-1/2">
                            <div>
                                <div class="input-group mt-0">
                                    <span class="input-group-item">
                                        <icon name="search" class="w-4 h-4 fill-current mr-2" />
                                    </span>

                                    <input type="text" v-model="searchForm.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>

                        <div class="ml-auto">
                            <a href="" class="btn btn-orange" @click.prevent="showInviteUserModal">
                                <icon name="plus" class="inline text-white fill-current h-4 w-4 mr-1" /> Invite New User
                            </a>
                        </div>
                    </div>

                    <div v-if="tenantInvitations.data.length">
                        <div class="my-6">
                            (Showing <span class="font-semibold text-gray-900">{{ tenantInvitations.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ tenantInvitations.total }}</span>)
                        </div>

                        <div class="overflow-x-auto">
                            <table class="table table-condensed">
                                <thead>
                                    <tr>
                                        <sortable-table-header field="created_at" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.invitations.index">Created At</sortable-table-header>
                                        <th class="font-semibold tracking-wider">Expires At</th>
                                        <sortable-table-header field="email" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.invitations.index">Email</sortable-table-header>
                                        <sortable-table-header field="employee_roles.name" class="font-semibold tracking-wider" :filters="filters" route-name="tenant-settings.invitations.index">Role</sortable-table-header>
                                        <th class="font-semibold tracking-wider">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="tenantInvitation in tenantInvitations.data" :key="tenantInvitation.id">
                                        <td>{{ $filters.format_date_time(tenantInvitation.created_at) }}</td>
                                        <td>{{ $filters.format_date_time(tenantInvitation.expires_at) }}</td>
                                        <td>{{ tenantInvitation.email }}</td>
                                        <td>{{ tenantInvitation.role.name }}</td>
                                        <td>
                                            <a href="" @click.prevent="removeInvitation(tenantInvitation)">
                                                <icon name="trash" class="inline fill-current text-red-500 hover:text-red-700 w-4 h-4" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <pagination :links="tenantInvitations.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="users" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Invitations Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <!-- User Modal !-->
    <modal role="dialog" ref="userModal" class="max-w-2xl" @closed="emptyModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl">
                    <span class="fal" :class="modalAction === 'Edit' ? 'fa-edit' : 'fa-plus'"></span>

                    <template>
                        Invite New User
                    </template>
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <form>
                    <div class="flex flex-wrap -mx-4 my-2">
                        <text-input v-model="userForm.email" label="Email" placeholder="Email" :errors="modalErrors.email" class="w-1/2 px-4" mark-as-required />

                        <select-input v-model="userForm.role.id" label="Role" :errors="modalErrors['role.id']" class="w-1/2 px-4" mark-as-required>
                            <option :value="null">Select a Role</option>

                            <option v-for="role in roles" :value="role.id">
                                {{ role.name }}
                            </option>
                        </select-input>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4">
                <div class="inline-block mr-2">
                    <button type="button" class="btn btn-gray" @click="close">Close</button>
                </div>

                <div class="inline-block">
                    <loading-button :loading="saving" class="inline btn btn-blue" @click="modalCallback">
                        Send Invitation
                    </loading-button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import Modal from '@/Shared/Modal.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import { throttle } from 'lodash-es';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import SubNav from '../SubNav.vue';
    import HorizontalSubNav from "../../TenantSettings/Users/HorizontalSubNav.vue";

    export default {
        components: {
	        HorizontalSubNav,
            Head,
            Icon,
            ToggleSwitchInput,
            TextInput,
            SelectInput,
            TextareaInput,
            LoadingButton,
            Pagination,
            Modal,
            SortableTableHeader,
            SubNav
        },
        
        props: {
            tenantInvitations: {
                type: Object,
                required: true
            },

            roles: Array,

            filters: Object,

            errors: {
                type: Object,
                default: () => ({}),
            }
        },

        data() {
            return {
                modalAction: '',
                modalCallback: () => {},
                modalErrors: {},
                saving: false,
                searchForm: {
                    search: this.filters.search
                },
                userForm: { email: null, role: { id: null } },
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            showInviteUserModal() {
                this.modalAction = 'Invite';

                this.userForm = { email: null, role: { id: null } }

                this.modalCallback = this.inviteUser;

                this.$refs.userModal.show();
            },

            inviteUser() {
                this.saving = true;

                this.$inertia
                    .post(this.$route('tenant-settings.invitations.store'), this.userForm, {
                        onSuccess: () => this.$refs.userModal.close(),
                        onError: (errors) => this.modalErrors = errors,
                        onFinish: () => this.saving = false
                    });
            },

            removeInvitation(tenantInvitation) {
                let confirmed = confirm("Are you sure you wish to perform this action?");

                if (confirmed) {
                    this.$inertia.delete(this.$route('tenant-settings.invitations.destroy', tenantInvitation.id));
                }
            },

            emptyModal() {
                this.userForm = { email: null, role: { id: null } };
                this.modalErrors = {};
            },

            changeNavigation(e) {
                this.$inertia.get(e.target.value);
            },
        },

        watch: {
            searchForm: {
                handler: throttle(function() {
                    let query = { ...this.searchForm };
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route('tenant-settings.invitations.index'), query, {preserveState: true});
                }, 150),

                deep: true,
            },
        }
    }
</script>