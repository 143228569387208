<template>
    <Head title="Work Orders" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Work Orders</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="work-order-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4">
        <div class="col-span-9 md:col-span-3">
            <label for="search-work-orders" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-work-orders" id="search-work-orders" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-9 sm:col-span-2 lg:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-9 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('work-orders.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="workOrders.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ workOrders.from }} - {{ workOrders.to }} out of {{ workOrders.total }} Work Orders</div>

            <div class="col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
                <loading-button :loading="state === 'exporting'" class="btn btn-gray" @click="exportData" :disabled="!workOrders.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

    <div v-if="workOrders.data.length">
        <div class="overflow-x-auto">
            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <sortable-table-header field="order_number" class="px-3 py-3.5 text-left font-semibold text-gray-900" :filters="filters" route-name="work-orders.index">Order Number</sortable-table-header>
	                    <th v-show="form.selected_headers.includes('Service')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">
		                        <span v-tippy="'This column displays an icon if the associated service is monitored by Pioneer.'">
				                    <icon name="info-circle" class="w-4 h-4 fill-current text-gray-400" />
                                    <span class="sr-only">Monitored by Pioneer</span>
			                    </span>
	                    </th>
                        <th v-show="form.selected_headers.includes('Service')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Service</th>
	                    <th v-show="form.selected_headers.includes('Equipment Label')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 truncate">Equipment Label</th>
	                    <sortable-table-header v-show="form.selected_headers.includes('Service Type')" field="services.type" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900" :filters="filters" route-name="work-orders.index">Service Type</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Source')" field="source" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900" :filters="filters" route-name="work-orders.index">Source</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Action')" field="requested_action" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900" :filters="filters" route-name="work-orders.index">Action</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Status')" field="status" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900" :filters="filters" route-name="work-orders.index">Status</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Location Name')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Location Name</th>
                        <th v-show="form.selected_headers.includes('Service Address')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Service Address</th>
                        <th v-show="form.selected_headers.includes('Requested By')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Requested By</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Scheduled For')" field="scheduled_with_vendor_date" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900" :filters="filters" route-name="work-orders.index">Scheduled For</sortable-table-header>
                        <th scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="workOrder in workOrders.data" :key="workOrder.id">
                        <td>
                            <inertia-link data-cy="work-orders.index.show-link" :href="$route('work-orders.show', workOrder.id)" class="link">
                                {{ workOrder.display_id }}
                            </inertia-link>
                        </td>
	                    <td v-show="form.selected_headers.includes('Service')">
		                    <pioneer-indicator v-if="workOrder.service?.compactorMonitor" :compactor-monitor="workOrder.service.compactorMonitor" />
	                    </td>
                        <td v-show="form.selected_headers.includes('Service')">
                            <inertia-link :href="$route('services.show', workOrder.service.id)" class="link" target="_blank">{{ workOrder.service.id }}</inertia-link>
                        </td>
	                    <td v-show="form.selected_headers.includes('Equipment Label')">
		                    {{ workOrder.service.label }}
	                    </td>
                        <td v-show="form.selected_headers.includes('Service Type')">
                            {{ workOrder.service.type }}
                        </td>
                        <td v-show="form.selected_headers.includes('Source')">
                            {{ workOrder.source }}
                        </td>
                        <td v-show="form.selected_headers.includes('Action')">
                            {{ workOrder.requested_action }}
                        </td>
                        <td v-show="form.selected_headers.includes('Status')">
                            {{ workOrder.decorated_status }}
                        </td>
                        <td v-show="form.selected_headers.includes('Location Name')">
                            <inertia-link :href="$route('locations.show', [workOrder.service.location_id])" class="link">
                                {{ workOrder.service.location.name }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Service Address')">
                            {{ workOrder.service.location.address.full_address }}
                        </td>
                        <td v-show="form.selected_headers.includes('Requested By')">
                            <span v-if="workOrder.requestedBy" class="font-medium">{{ workOrder.requestedBy.name }}</span>
                            <span v-else class="font-400 italic">{{ workOrder.source }}</span>
                        </td>
                        <td v-show="form.selected_headers.includes('Scheduled For')" v-if="workOrder.scheduled_with_vendor_date">
                            {{ $filters.format_date(workOrder.scheduled_with_vendor_date) }}
                        </td>
                        <td v-else v-show="form.selected_headers.includes('Scheduled For')">
                            Not scheduled
                        </td>
                        <td class="text-right">
                            <span class="inline-block">
                                <inertia-link :href="$route('work-orders.show', workOrder.id)" class="link mr-3" title="View This Work Order">
                                    View
                                </inertia-link>
                            </span>
                            <span class="inline-block">
                                <inertia-link :href="$route('work-orders.edit', workOrder.id)" class="link" title="Edit This Work Order">
                                    Edit
                                </inertia-link>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

            <pagination :links="workOrders.links" />
        </div>

        <div v-if="!workOrders.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="clipboard" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Work Orders Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="work-order-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <label for="status" class="sr-only">Status</label>

                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="statuses" name="statuses" class="form-input" @change="statusComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="statuses.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="status in filteredStatusOptions" :key="status.id" :value="status.id" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ status.text }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.requested_by" multiple>
                        <ComboboxLabel class="form-label" for="requested_by">Requested By</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="requested_by" name="requested_by" class="form-input" @change="searchClients($event)" :display-value="comboDisplayValue" />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <div v-if="state === 'searching-clients'" class="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white px-2 py-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <span class="mr-2">Searching...</span>
                                <icon name="search" class="w-3 h-3 fill-current inline search-icon search-animation" />
                            </div>

                            <ComboboxOptions v-if="filteredClientOptions.length > 0 && state === 'passive'" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="client in filteredClientOptions" :key="client.id" :value="client" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ client.name }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.sources" multiple>
                        <ComboboxLabel class="form-label" for="sources">Source</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="sources" name="sources" class="form-input" @change="sourceComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="sources.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="source in filteredSourceOptions" :key="source.key" :value="source.key" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ source.text }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.service_types" multiple>
                        <ComboboxLabel for="types" class="form-label">Select a Service Type</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput
                                name="types"
                                id="types"
                                class="form-input"
                                @change="serviceTypeComboBoxQuery = $event.target.value"
                                :display-value="comboDisplayValue"
                            />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredServiceTypeOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredServiceTypeOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ option }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <select-input id="missingHaulerCharge" name="missingHaulerCharge" class="col-span-1" v-model="form.missingHaulerCharge" label="Missing Hauler Charge">
                    <option value="include">Include</option>
                    <option value="except">Hide</option>
                    <option value="only">Only</option>
                </select-input>
            </form>
        </template>
    </slide-over>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SlideOver from '@/Shared/SlideOver.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle, debounce } from 'lodash-es';
    import axios from 'axios';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';
    import PioneerIndicator from "../../Shared/PioneerIndicator.vue";

    export default {
        components: {
	        PioneerIndicator,
            Head,
            Icon,
            LoadingButton,
            Pagination,
            SortableTableHeader,
            SearchInput,
            SelectInput,
            SlideOver,
            ColumnSelector,

            // Tailwind UI combobox.
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            workOrders: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },

            statuses: {
                type: Array,
                required : true
            },

            serviceTypes: {
                type: Array,
                required: true
            },

            sources: {
                type: Array,
                required : true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    statuses: this.statuses.filter(status => this.filters.statuses.includes(status)),
                    service_types: this.serviceTypes.filter(serviceType => this.filters.service_types.includes(serviceType)),
                    requested_by: this.filters.requested_by,
                    sources: this.sources.filter(source => this.filters.sources.includes(source.key)),
                    missingHaulerCharge: this.filters.missingHaulerCharge,
                    selected_headers: this.filters.selected_headers,
                },
                state: 'passive',
                clients: [],
                statusComboBoxQuery: '',
                serviceTypeComboBoxQuery: '',
                sourceComboBoxQuery: '',
                mounted: false,
                headers: [],
                excludedHeaders: ['Order Number', 'Actions', 'Monitored by Pioneer'],
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.work-orders.index'), this.queryFilters, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected'; 
            },

            searchClients: debounce(function(event) {
                if (event.target.value && this.state === 'passive') {
                    this.state = 'searching-clients';

                    axios.get(this.$route('json.clients.index', {search: event.target.value.toLowerCase()})).then(response => {
                        this.clients = response.data?.data.map(client => ({'id': client.id, 'name': `${client.first_name} ${client.last_name}`})).sort((a,b) => a.name.localeCompare(b.name));
                    })
                    .finally(() => this.state = 'passive');
                }
            }, 800),

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                this.headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('work-orders.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form}
                query.requested_by = query.requested_by.map(JSON.stringify);

                return has_search_filters(query) ? query : {remember: 'forget'};
            },
            
            filteredClientOptions() {
                let clients = [...this.clients, ...this.form.requested_by];

                return [...new Set(clients)];
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.text.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            filteredServiceTypeOptions() {
                return this.serviceTypeComboBoxQuery === ''
                ? this.serviceTypes
                : this.serviceTypes.filter((status) => {
                    return status.toLowerCase().includes(this.serviceTypeComboBoxQuery.toLowerCase());
                });
            },

            filteredSourceOptions() {
                return this.sourceComboBoxQuery === ''
                    ? this.sources
                    : this.sources.filter((source) => {
                        return source.text.toLowerCase().includes(this.sourceComboBoxQuery.toLowerCase());
                    });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                    form.service_types,
                    form.requested_by,
                    form.sources,
                    form.missingHaulerCharge,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>

<style scoped>
    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }
    @keyframes search-animation {
        0%   { transform: translateY(0); }
        30%  { transform: translateY(-10px); }
        50%  { transform: translateY(0) translateX(5px); }
        100% { transform: translateY(0) translateX(0); }
    }
</style>