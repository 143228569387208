<template>
    <Head title="Client Payments" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Revenue - Client Payments</span>
        </nav>
    </Teleport>

    <horizontal-sub-nav :permissions="$page.props.permissions" current-tab="payments" />

    <form @submit.prevent id="client-payment-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-9 sm:col-span-3">
            <label for="search-client-accounts" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-client-accounts" id="search-client-accounts" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-9 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>
        <div class="col-span-9 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('client-payments.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="clientPayments.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ clientPayments.from }} - {{ clientPayments.to }} out of {{ clientPayments.total }} Client Payments</div>

        <div class="flex col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
            <inertia-link :href="$route('client-payments.create')" class="btn btn-orange mr-2 my-1">
                 New Batch
            </inertia-link>

            <loading-button :loading="saving" class="btn btn-gray mr-2 my-1" @click="exportData" :disabled="!clientPayments.data.length">
                Export to CSV
            </loading-button>
        </div>
    </div>

    <div>
        <div class="overflow-x-auto">
            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <sortable-table-header field="client_companies.name" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client Company</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Check Number')" field="check_number" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Check Number</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Payment Method')" field="payment_method" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Payment Method</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Total Amount')" field="total_amount" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Total Amount</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Applied Amount')" field="applied_amount" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Applied Amount</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Entry Date')" field="entry_date" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Entry Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Receipt Date')" field="receipt_date" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Receipt Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Receipt Location')" field="receipt_location" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Receipt Location</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Remitter Name')" field="remitter_name" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Remitter Name</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Voided Date')" field="voided_date" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Voided Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Refunded At')" field="refunded_at" :filters="filters" route-name="client-payments.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Refunded At</sortable-table-header>
                        <th scope="col" class="w-36 px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="clientPayment in clientPayments.data" :key="clientPayment.id">
                        <td>
                            <inertia-link v-if="clientPayment.clientCompany" :href="$route('client-companies.edit', clientPayment.clientCompany.id)" class="link">
                                {{ clientPayment.clientCompany.name }}
                            </inertia-link>

                            <span v-else>
                                N/A
                            </span>
                        </td>
                        <td v-show="form.selected_headers.includes('Check Number')" class="text-left">
                            {{ clientPayment.check_number }}
                        </td>
                        <td v-show="form.selected_headers.includes('Payment Method')">
                            {{ clientPayment.payment_method }}
                        </td>
                        <td v-show="form.selected_headers.includes('Total Amount')" class="text-right">
                            {{ $filters.format_money(clientPayment.total_amount) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Applied Amount')" class="text-right">
                            {{ $filters.format_money(clientPayment.applied_amount) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Entry Date')">
                            {{ $filters.format_date(clientPayment.entry_date) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Receipt Date')">
                            {{ $filters.format_date(clientPayment.receipt_date) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Receipt Location')">
                            {{ clientPayment.receipt_location }}
                        </td>
                        <td v-show="form.selected_headers.includes('Remitter Name')">
                            {{ clientPayment.remitter_name }}
                        </td>
                        <td v-show="form.selected_headers.includes('Voided Date')">
                            <span :title="clientPayment.voided_reason">{{ $filters.format_date(clientPayment.voided_date) }}</span>
                        </td>
                        <td v-show="form.selected_headers.includes('Refunded At')">
                            {{ $filters.format_date(clientPayment.refunded_at) }}
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('client-payments.edit', clientPayment.id)" class="link" title="Edit This Vendor">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="clientPayments.links" />
    </div>

    <div v-if="!clientPayments.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="money-check-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Client Payments Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="client-payment-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
                <select-input v-model="form.payment_group" label="Show Payments" class="col-span-1">
                    <option :value="null">All</option>
                    <option value="Has Unapplied Amounts">Has Unapplied Amounts</option>
                </select-input>

                <date-input v-model="form.from_receipt_date" label="Receipt Date (from)" class="col-span-1" />

                <date-input v-model="form.to_receipt_date" label="Receipt Date (to)" class="col-span-1" />
            </form>
        </template>
    </slide-over>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import HorizontalSubNav from '../Revenue/HorizontalSubNav.vue';
    import SlideOver from '@/Shared/SlideOver.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import { format } from 'date-fns';
    import { Head } from '@inertiajs/vue3';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';

    export default {
        components: {
            DateInput,
            Icon,
            Pagination,
            SelectInput,
            SortableTableHeader,
            TextInput,
            LoadingButton,
            HorizontalSubNav,
            SlideOver,
            Head,
            ColumnSelector,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            clientPayments: {
                type: Object,
                required: true
            },

            filters: {
                Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    from_receipt_date: this.filters.from_receipt_date || format(new Date(2014, 0, 1), 'yyyy-LL-dd'),
                    to_receipt_date: this.filters.to_receipt_date || format(new Date, 'yyyy-LL-dd'),
                    payment_group: this.filters.payment_group || null,
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    selected_headers: this.filters.selected_headers,
                },
                headers: [],
                excludedHeaders: ['Client Company', 'Actions'],
                saving: false,
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
                this.filtersInUse = this.getFiltersUsed;
            }

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.client-payments.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('client-payments.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.from_receipt_date,
                    form.to_receipt_date,
                    form.payment_group,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>