<template>
    <Head :title="`Edit Rover Credential - ${vendorPortalCredential.username}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('vendor-portal-credentials.index')" class="breadcrumb-link">Rover Credentials</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('vendor-portal-credentials.show', vendorPortalCredential.id)" class="breadcrumb-link">{{ vendorPortalCredential.vendorPortal.vendor_name }} - {{ vendorPortalCredential.username }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Edit</span>
        </nav>
    </Teleport>

    <form id="edit-vendor-portal-credential-form" class="space-y-8" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Credential Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Login/Password</p>
                </div>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6">
                <text-input v-model="form.username" label="Username" :errors="errors.username" mark-as-required />
                <text-input type="password" v-model="form.password" label="Password" placeholder="Only fill if updating your password." :errors="errors.password" />
                <toggle-switch-input v-model="form.fetch_latest_invoice_when_subscribing_account" label="Fetch Latest Invoice When Subscribing Accounts?" />
            </div>


        </fieldset>
        <template v-if="availableSecurityQuestions">
            <div class="block relative">
                <div class="col-start-1 col-span-full">
                    This portal uses Security Questions. Please provide all of your security questions/answers to ensure we can pull your invoice data.
                </div>

                <div class="block my-4">
                    <select-input class="w-full lg:w-1/3" @change="securityQuestionSelected" v-model="selectedSecurityQuestion" label="Security Questions" :errors="errors.securityQuestions">
                        <option :value="null">Select a security question to add</option>
                        <option v-for="(securityQuestion,index) in sortedAvailableSecurityQuestions" :value="securityQuestion" :key="index">{{ securityQuestion }}</option>
                    </select-input>
                </div>

                <div class="block mb-2" v-for="(securityQuestion, index) in form.selectedSecurityQuestions">
                    <div class="flex">

                        <text-input class="w-full lg:w-1/3" :label="securityQuestion.question" v-model="form.selectedSecurityQuestions[index].answer" :errors="errors.securityQuestions" placeholder="Only fill if updating your answer" />
                        <button type="button" class="btn btn-red py-0 h-10 self-end ml-3" @click="removeSelectedSecurityQuestion(index)">
                            <span>Delete</span>
                        </button>
                    </div>
                </div>

            </div>
        </template>
        <div class="w-full flex flex-wrap">
            <loading-button :loading="state === 'saving'" class="btn btn-orange" @click="submitForm">Save Changes</loading-button>
        </div>

    </form>
</template>

<script setup>
    import { ref, inject, onMounted, computed } from 'vue';
    import { router } from '@inertiajs/vue3';

    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import SelectInput from "../../Shared/SelectInput.vue";

    // Injects
    const route = inject('route');
    const toast = inject('toast');

    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        vendorPortalCredential: {
            type: Object,
            required: true
        },

        securityQuestions: {
            type: Array,
            required: false
        },

        authenticationRequirements: {
            type: Object,
            required: false,
        }
    });

    const form = ref({
        username: props.vendorPortalCredential.username,
        password: null,
        fetch_latest_invoice_when_subscribing_account: props.vendorPortalCredential.fetch_latest_invoice_when_subscribing_account,
        selectedSecurityQuestions: props.securityQuestions,
    });
    const availableSecurityQuestions = ref(props.authenticationRequirements.securityQuestions);
    const selectedSecurityQuestion = ref(null);
    const state = ref('passive');
    const mounted = ref(false);

    onMounted(() => {
        mounted.value = true;

        cleanAvailableSecurityQuestions();
    });

    function cleanAvailableSecurityQuestions() {
        form.value.selectedSecurityQuestions.forEach((selectedQuestion) => {
            availableSecurityQuestions.value.splice(availableSecurityQuestions.value.indexOf(selectedQuestion.question), 1);
        });
    }

    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.put(route('vendor-portal-credentials.update', props.vendorPortalCredential.id), form.value, {
                onFinish: () => state.value = 'passive'
            });
        }
    }

    function securityQuestionSelected() {
        if (form.value.selectedSecurityQuestions.includes(selectedSecurityQuestion.value)) {
            return;
        }

        console.log(availableSecurityQuestions.value.indexOf(selectedSecurityQuestion.value));

        form.value.selectedSecurityQuestions.push({
            question: selectedSecurityQuestion.value,
            answer: ""
        });
        availableSecurityQuestions.value.splice(availableSecurityQuestions.value.indexOf(selectedSecurityQuestion.value), 1);
    }

    function removeSelectedSecurityQuestion(questionIndex) {
        let unselectedQuestion = form.value.selectedSecurityQuestions[questionIndex];
        form.value.selectedSecurityQuestions.splice(questionIndex, 1);
        availableSecurityQuestions.value.push(unselectedQuestion.question);
        selectedSecurityQuestion.value = null;
    }

    const sortedAvailableSecurityQuestions = computed( () => {
        let securityQuestions = props.authenticationRequirements?.securityQuestions ?? [];

        return securityQuestions.toSorted((a, b) => a.localeCompare(b));
    });
</script>