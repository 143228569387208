<style scoped>

</style>

<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <nav class="" aria-label="Sidebar">
        <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
        <inertia-link v-bind:href="$route('revenue.dashboard', [fiscalPeriod.id])" :class="[currentNav === 'dashboard' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900']" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md" aria-current="page">
            <span class="truncate"> Dashboard </span>

            <!-- Current: "bg-white", Default: "bg-gray-100 text-gray-600 group-hover:bg-gray-200" -->
            <!-- <span :class="[currentNav === 'dashboard' ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200']" class="ml-auto inline-block py-0.5 px-3 text-xs rounded-full"> 5 </span> -->
        </inertia-link>

        <div class="mt-8">
            <h3 class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
                Revenue Line Item Sources
            </h3>

            <inertia-link :href="$route('revenue.billable-expenses', [fiscalPeriod.id])" :class="[currentNav === 'billable-expenses' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900']" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                <span class="truncate">Billable Expenses</span>
    
                <span :class="[currentNav === 'billable-expenses' ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200']" class="ml-auto inline-block py-0.5 px-3 text-xs rounded-full">{{ numberOf.billableExpenses }}</span>
            </inertia-link>
            
            <inertia-link :href="$route('revenue.brokered-services', [fiscalPeriod.id])" :class="[currentNav === 'brokered-services' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900']" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                <span class="truncate">Service Charges</span>
    
                <span :class="[currentNav === 'brokered-services' ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200']" class="ml-auto inline-block py-0.5 px-3 text-xs rounded-full">{{ numberOf.brokeredServices }}</span>
            </inertia-link>
    
            <inertia-link :href="$route('revenue.client-service-fees', [fiscalPeriod.id])" :class="[currentNav === 'client-service-fees' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900']" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                <span class="truncate">Client Service Fees</span>
    
                <span :class="[currentNav === 'client-service-fees' ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200']" class="ml-auto inline-block py-0.5 px-3 text-xs rounded-full">{{ numberOf.clientServiceFees }}</span>
            </inertia-link>

            <inertia-link :href="$route('revenue.location-fees', [fiscalPeriod.id])" :class="[currentNav === 'location-fees' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900']" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                <span class="truncate">Location Fees</span>
    
                <span :class="[currentNav === 'location-fees' ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200']" class="ml-auto inline-block py-0.5 px-3 text-xs rounded-full">{{ numberOf.locationFees }}</span>
            </inertia-link>

            <inertia-link :href="$route('revenue.client-account-fees', [fiscalPeriod.id])" :class="[currentNav === 'client-account-fees' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900']" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                <span class="truncate">Client Account Fees</span>
    
                <span :class="[currentNav === 'client-account-fees' ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200']" class="ml-auto inline-block py-0.5 px-3 text-xs rounded-full">{{ numberOf.clientAccountFees }}</span>
            </inertia-link>
    
            <inertia-link :href="$route('revenue.prior-period-adjustments', [fiscalPeriod.id])" :class="[currentNav === 'prior-period-adjustments' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900']" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                <span class="truncate">Prior Period Adjustments</span>
    
                <span :class="[currentNav === 'prior-period-adjustments' ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200']" class="ml-auto inline-block py-0.5 px-3 text-xs rounded-full">{{ numberOf.priorPeriodAdjustments }}</span>
            </inertia-link>
    
            <inertia-link :href="$route('revenue.client-line-item-adjustments', [fiscalPeriod.id])" :class="[currentNav === 'client-line-item-adjustments' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900']" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                <span class="truncate">Client Line Item Adjustments</span>
                
                <span :class="[currentNav === 'client-line-item-adjustments' ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200']" class="ml-auto inline-block py-0.5 px-3 text-xs rounded-full">{{ numberOf.clientLineItemAdjustments }}</span>
            </inertia-link>
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        currentNav: {
            type: String,
            required: true,
        },

        fiscalPeriod: {
            type: Object,
            required: true,
        },

        numberOf: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            
        }
    },

    methods: {
    }
}
</script>