<template>
    <div>
        <div v-if="who === 'broker'" class="my-8"><b>You</b> are entering a <b>client contract change</b>. Please fill in the details.</div>
        <div v-else class="my-8">The <b>{{ who }}</b> is requesting a <b>client contract change</b>. Please fill in the details.</div>

        <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
            <date-input class="col-span-2 md:col-span-1" :min="earliestServiceActivity.actual_date" v-model="actual_date" label="When should this change happen?" :errors="errors['actual_date']" mark-as-required />
            
            <div class="col-span-2 md:col-span-1" >
                <p v-if="actual_date && actual_date < today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).</p>
                <p v-if="actual_date && actual_date > today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.</p>
            </div>

            <select-input class="col-span-2 md:col-span-1" label="Why are you making this change?" v-model="change_reason" mark-as-required>
                <option :value="null">Select a Reason</option>
                <option>Other</option>
                <option>Savings</option>
            </select-input>

            <select-input class="col-span-2 md:col-span-1" v-model="form.client_contract_id" label="Client Contract" :errors="errors['client_contract_id']" mark-as-required>
                <option v-if="clientContracts.length === 0" :value="null">No contracts available for this Client</option>
                <option v-else :value="null">Select a Contract</option>
                <option v-for="clientContract in clientContracts" :key="clientContract.id" :value="clientContract.id">{{ clientContract.id + " - Integration " + clientContract.integration_date ?? " - " }}</option>
            </select-input>

            <select-input v-model="form.is_pass_through" label="Is Pass Through?" class="col-span-2 md:col-span-1"  :errors="errors['is_pass_through']" mark-as-required>
                <option :value="true">Yes</option>
                <option :value="false">No</option>
            </select-input>

            <select-input :disabled="form.is_pass_through === true" v-model="form.client_billing_frequency" class="col-span-2 md:col-span-1"  label="Client Billing Frequency" placeholder="Client Billing Frequency" :errors="errors['client_billing_frequency']" mark-as-required>
                <option :value="null">Select a Frequency</option>
                <option v-for="clientBillingFrequency in clientBillingFrequencies" :key="clientBillingFrequency" :value="clientBillingFrequency">{{ clientBillingFrequency }}</option>
            </select-input>

            <money-input v-if="form.is_pass_through === false && serviceHasClientBaseCharge" :defaultCurrency="service.location.clientCompany.currency" v-model="form.client_base_charge" class="col-span-2 md:col-span-1"  label="Base Charge" placeholder="Base Charge" :errors="errors['client_base_charge']" mark-as-required />

            <money-input v-if="form.is_pass_through === false && serviceHasClientPerUnitCharge" :defaultCurrency="service.location.clientCompany.currency" v-model="form.client_per_unit_charge" class="col-span-2 md:col-span-1"  label="Per Unit Charge" placeholder="Per Unit Charge" :errors="errors['client_per_unit_charge']" mark-as-required />

            <money-input v-if="form.is_pass_through === false && serviceHasClientPerHaulAmount" :defaultCurrency="service.location.clientCompany.currency" v-model="form.client_per_haul_amount" class="col-span-2 md:col-span-1"  label="Per Haul Amount" placeholder="Per Haul Amount" :errors="errors['client_per_haul_amount']" mark-as-required />

            <money-input v-if="form.is_pass_through === false && serviceHasClientDisposalAmountPerTon" :defaultCurrency="service.location.clientCompany.currency" v-model="form.client_disposal_amount_per_ton" class="col-span-2 md:col-span-1"  label="Disposal Amount Per Ton" placeholder="Disposal Amount Per Ton" :errors="errors['client_disposal_amount_per_ton']" mark-as-required />

            <textarea-input class="col-span-2" v-model="internal_change_notes" label="Internal Change Notes" />
        </div>

        <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">Submit Change</loading-button>
    </div>
</template>

<script>
import SelectInput from '@/Shared/SelectInput.vue';
import MoneyInput from '@/Shared/MoneyInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import DateInput from '@/Shared/DateInput.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import {format, startOfToday} from "date-fns";

export default {
    components: {
        SelectInput,
        DateInput,
        TextareaInput,
        LoadingButton,
        TextInput,
        MoneyInput,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        clientBillingFrequencies: {
            type: Array,
            required: true
        },

        clientContracts: {
            type: Array,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            saving: false,
            form: {
                client_contract_id:             this.service.client_contract_id,
                is_pass_through:                this.service.is_pass_through,
                client_billing_frequency:       this.service.client_billing_frequency,
                client_base_charge:             this.service.client_base_charge ?? null,
                client_per_unit_charge:         this.service.client_per_unit_charge ?? null,
                client_per_haul_amount:         this.service.client_per_haul_amount ?? null,
                client_disposal_amount_per_ton: this.service.client_disposal_amount_per_ton ?? null,
            },
            actual_date:            format(startOfToday(), 'yyyy-MM-dd'),
            change_reason:          null,
            change_requester:       this.who,
            internal_change_notes:  null,
            today:                  format(startOfToday(), 'yyyy-MM-dd'),
        }
    },

    methods: {
        submitChange() {
            this.saving = true;

            // should we schedule this or fire it off immediately?
            // we can call different routes or we could just handle in the controller... not sure which is easiest
            this.$inertia.post(this.$route('services.client-contract-changes.store', [this.service.id]), {
                ...this.form,
                'actual_date': this.actual_date,
                'change_reason': this.change_reason,
                'change_requester': this.who,
                'internal_change_notes': this.internal_change_notes,
            }, {
                onFinish: () => this.saving = false
            });
        },
    },

    computed: {
        serviceHasClientBaseCharge() {
            return ['Commercial', 'Device', 'Doorstep', 'Rental'].includes(this.service.type);
        },

        serviceHasClientPerUnitCharge() {
            return ['Doorstep'].includes(this.service.type);
        },

        serviceHasClientPerHaulAmount() {
            return ['Rolloff'].includes(this.service.type);
        },

        serviceHasClientDisposalAmountPerTon() {
            return ['Rolloff'].includes(this.service.type);
        },
    },

    watch: {
        'form.is_pass_through'(newValue, oldValue) {
            if (newValue === true && this.service.type !== 'Rolloff') {
                this.form.client_billing_frequency = 'Per Vendor Invoice';
            } else {
                this.form.client_billing_frequency = this.service.client_billing_frequency;
            }
        }
    }
}
</script>