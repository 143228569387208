<template>
    <Head title="Services - Update" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav aria-label="breadcrumbs" class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>General Update</span>
        </nav>
    </Teleport>

    <div class="mt-8 mb-12">
        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
        </div>

        <div>
            <fieldset>
                <legend class="font-semibold text-xl text-blue-600">Service</legend>
                <div class="space-y-6">
                    <div class="grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-6 my-4">
                        <div>
                            <label class="form-label">Service Type</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.type }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['location_id']}" class="form-label">Location</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.location.name }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['effective_date']}" class="form-label">Effective Date</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.effective_date }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['termination_date']}" class="form-label">Termination Date</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.termination_date }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['external_reference_id']}" class="form-label">External Reference Id</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.external_reference_id }}</span>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="mt-4">
                <legend class="font-semibold text-xl text-blue-600">Vendor</legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['vendor_id']}" class="form-label">Vendor</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['vendor_account_id']}" class="form-label">Vendor Account</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.vendorAccount?.account_number }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['vendor_contract_id']}" class="form-label">Vendor Contract</label>
                        <span v-if="serviceActivity.serviceSnapshot.vendorContract" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.vendorContract.display_id }} - {{ serviceActivity.serviceSnapshot.vendorContract.status }} <span v-if="serviceActivity.serviceSnapshot.vendorContract.effective_date">- Effective {{ serviceActivity.serviceSnapshot.vendorContract.effective_date }}</span></span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['service_account_type']}" class="form-label">Service Account Type</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.service_account_type }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['service_schedule_type']}" class="form-label">Service Schedule Type</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.service_schedule_type }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['pickup_rrule']}" class="form-label">Pickup Schedule</label>
                        <div v-if="serviceActivity.serviceSnapshot.service_schedule_type === 'Scheduled'">
                            <div class="text-sm py-3 text-gray-800">
                                {{ serviceActivity.serviceSnapshot.pickup_rrule ? parseRRule(serviceActivity.serviceSnapshot.pickup_rrule) : 'No pickup schedule set' }}
                            </div>
                        </div>
                        <div v-else class="py-3 font-gray-800 text-sm">
                            Cannot schedule {{ serviceActivity.serviceSnapshot.service_schedule_type.toLowerCase() ?? 'unscheduled '}} services
                        </div>
                    </div>

                    <div v-if="hasField['vendor_base_charge']">
                        <label :class="{'text-red-600 text-lg': hasChanged['vendor_base_charge']}" class="form-label">Base Charge</label>
                        <span class="m-1 text-sm">{{ $filters.format_money(serviceActivity.serviceSnapshot.vendor_base_charge) }}</span>
                    </div>

                    <div v-if="hasField['vendor_per_unit_charge']">
                        <label :class="{'text-red-600 text-lg': hasChanged['vendor_per_unit_charge']}" class="form-label">Per Unit Charge</label>
                        <span class="m-1 text-sm">{{ $filters.format_money(serviceActivity.serviceSnapshot.vendor_per_unit_charge) }}</span>
                    </div>

                    <div v-if="hasField['vendor_disposal_amount_per_ton']">
                        <label :class="{'text-red-600 text-lg': hasChanged['vendor_disposal_amount_per_ton']}" class="form-label">Disposal Amount Per Ton</label>
                        <span class="m-1 text-sm">{{ $filters.format_money(serviceActivity.serviceSnapshot.vendor_disposal_amount_per_ton) }}</span>
                    </div>

                    <div v-if="hasField['vendor_per_haul_amount']">
                        <label :class="{'text-red-600 text-lg': hasChanged['vendor_per_haul_amount']}" class="form-label">Per Haul Amount</label>
                        <span class="m-1 text-sm">{{ $filters.format_money(serviceActivity.serviceSnapshot.vendor_per_haul_amount) }}</span>
                    </div>

                    <div v-if="hasField['tons_included_per_haul']">
                        <label :class="{'text-red-600 text-lg': hasChanged['tons_included_per_haul']}" class="form-label">Tons Included Per Haul</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.tons_included_per_haul }}</span>
                    </div>
                </div>
            </fieldset>

            <div v-if="['Commercial', 'Rental', 'Rolloff'].includes(serviceActivity.serviceSnapshot.type)">
                <fieldset class="mt-4">
                    <legend class="font-semibold text-xl text-blue-600">Bin</legend>
                    <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_quantity']}" class="form-label">Quantity</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.quantity }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_size']}" class="form-label">Size</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.size }} {{ serviceActivity.serviceSnapshot.bin.size }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_size_metric']}" class="form-label">Size Metric</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.size_metric }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_material_type']}" class="form-label">Material Type</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.material_type }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_disposal_method']}" class="form-label">Disposal Method</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.disposal_method }}</span>
                        </div>

                        <div>
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_type']}" class="form-label">Bin Type</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.type }}</span>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="mt-4">
                    <legend  class="block font-semibold text-l text-blue-600 my-5">
                        <button v-if="collapsed" @click="changeCollapse">
                            Show Advanced Bin Options
                            <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="collapsed ? 'chevron-right' : 'chevron-down'" />
                        </button>
                        <button v-else @click="changeCollapse">
                            Hide Advanced Bin Options
                            <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="collapsed ? 'chevron-right' : 'chevron-down'" />
                        </button>
                    </legend>

                    <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_is_compacted_or_baled']}" class="form-label">Is Compacted or Baled</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.is_compacted_or_baled !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.is_compacted_or_baled ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_compaction_ratio']}" class="form-label">Compaction Ratio</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.compaction_ratio }}</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_pounds_per_yard']}" class="form-label">Pounds per yard</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.pounds_per_yard }}</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_has_casters']}" class="form-label">Has Casters</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.has_casters !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.has_casters ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_enclosure']}" class="form-label">Enclosure</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.enclosure !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.enclosure ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_requires_overhead_instructions']}" class="form-label">Overhead Instructions</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.requires_overhead_instructions !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.requires_overhead_instructions ? 'Required' : 'Not Required' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_container_access_times']}" class="form-label">Container Access Times</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.container_access_times }}</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_configuration']}" class="form-label">Configuration</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.configuration }}</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_owner']}" class="form-label">Asset Owner</label>
                            <span class="m-1 text-sm">{{ assetOwner }}</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_keys']}" class="form-label">Requires Keys</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.keys !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.keys ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_has_lids']}" class="form-label">Lids</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.has_lids !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.has_lids ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_has_lock']}" class="form-label">Has Lock</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.has_lock !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.has_lock ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_secured_access']}" class="form-label">Secured Access</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.secured_access !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.secured_access ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_push_out_service']}" class="form-label">Push Out Service</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.push_out_service !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.push_out_service ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_side_door_to_bin']}" class="form-label">Side Door to Bin</label>
                            <span v-if="serviceActivity.serviceSnapshot.bin.side_door_to_bin !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.side_door_to_bin ? 'Yes' : 'No' }}</span>
                            <span v-else class="m-1 text-sm">Unsure</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_compaction_method']}" class="form-label">Compaction Method</label>
                            <span class="m-1 text-sm">{{ compactionMethod }}</span>
                        </div>

                        <div v-if="!collapsed">
                            <label :class="{'text-red-600 text-lg': hasChanged['bin_area_description']}" class="form-label">Area Description</label>
                            <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.area_description }}</span>
                        </div>
                    </div>
                </fieldset>
            </div>

            <fieldset v-if="serviceActivity.serviceSnapshot.type === 'Doorstep'" class="my-4">
                <legend class="block font-semibold text-xl text-blue-600">Bin</legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['bin_quantity']}" class="form-label">Quantity</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.quantity }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['bin_size']}" class="form-label">Size</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.size }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['bin_size_metric']}" class="form-label">Size Metric</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.size_metric }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['bin_material_type']}" class="form-label">Material Type</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.material_type }}</span>
                    </div>
                </div>
            </fieldset>

            <fieldset v-if="$page.props.permissions.accessRevenueManagement" class="mt-8">
                <legend class="font-semibold text-xl text-blue-600">Client</legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
                    <div :class="{'bg-red-600 text-green-400': hasChanged['client_contract_id']}" style="font: 'comic-sans'" >
                        <label class="form-label">Client Contract</label>
                        <span v-if="serviceActivity.serviceSnapshot.clientContract" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.clientContract.id }} - Integration: {{ serviceActivity.serviceSnapshot.clientContract.integration_date }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['is_pass_through']}" class="form-label">Is Pass Through?</label>
                        <span v-if="serviceActivity.serviceSnapshot.is_pass_through !== null" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.bin.is_pass_through ? 'Yes' : 'No' }}</span>
                        <span v-else class="m-1 text-sm">Unsure</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['client_billing_frequency']}" class="form-label">Client Billing Frequency</label>
                        <span v-if="!serviceActivity.serviceSnapshot.is_pass_through" class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.client_billing_frequency }}</span>
                        <span v-else class="m-1 text-sm">Cannot be set for pass through services</span>
                    </div>

                    <div v-if="hasField['client_base_charge'] && serviceActivity.serviceSnapshot.is_pass_through === false">
                        <label :class="{'text-red-600 text-lg': hasChanged['client_base_charge']}" class="form-label">Base Charge</label>
                        <span class="m-1 text-sm">{{ $filters.format_money(serviceActivity.serviceSnapshot.client_base_charge) }}</span>
                    </div>

                    <div v-if="hasField['client_per_unit_charge'] && serviceActivity.serviceSnapshot.is_pass_through === false">
                        <label :class="{'text-red-600 text-lg': hasChanged['client_per_unit_charge']}" class="form-label">Per Unit Charge</label>
                        <span class="m-1 text-sm">{{ $filters.format_money(serviceActivity.serviceSnapshot.client_per_unit_charge) }}</span>
                    </div>

                    <div v-if="hasField['client_disposal_amount_per_ton'] && serviceActivity.serviceSnapshot.is_pass_through === false">
                        <label :class="{'text-red-600 text-lg': hasChanged['client_disposal_amount_per_ton']}" class="form-label">Disposal Amount Per Ton</label>
                        <span class="m-1 text-sm">{{ $filters.format_money(serviceActivity.serviceSnapshot.client_disposal_amount_per_ton) }}</span>
                    </div>

                    <div v-if="hasField['client_per_haul_amount'] && serviceActivity.serviceSnapshot.is_pass_through === false">
                        <label :class="{'text-red-600 text-lg': hasChanged['client_per_haul_amount']}" class="form-label">Per Haul Amount</label>
                        <span class="m-1 text-sm">{{ $filters.format_money(serviceActivity.serviceSnapshot.client_per_haul_amount) }}</span>
                    </div>

                    <div>
                        <label :class="{'text-red-600 text-lg': hasChanged['label']}" class="form-label">Label</label>
                        <span class="m-1 text-sm">{{ serviceActivity.serviceSnapshot.label }}</span>
                    </div>
                </div>
            </fieldset>

            <hr v-if="serviceActivity.internal_change_notes" class="my-8"/>

            <div v-if="serviceActivity.internal_change_notes">
                <legend class="font-semibold text-md text-blue-600">Internal Change Notes</legend>
                <span class="m-1 text-sm">{{ serviceActivity.internal_change_notes }}</span>
            </div>

            <hr v-if="changeSummary.length > 0" class="my-8"/>

            <div class="mt-8" v-if="changeSummary.length > 0">
                <legend class="font-semibold text-md text-blue-600">Summary of Changes</legend>
                <table class="table w-1/2">
                    <tr>
                        <th>Field Name</th>
                        <th>Previous Value</th>
                        <th>Value</th>
                    </tr>

                    <tbody>
                        <tr v-for="change in changeSummary">
                            <td>{{ change.field_name }}</td>
                            <td>{{ format_field(change.old_value) }}</td>
                            <td>{{ format_field(change.new_value) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import { rrulestr } from "rrule";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            Head,
        },
        
        props: {
            assetOwner: {
                type: String,
                required: true
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            changeSummary : {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                collapsed: true,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            parseRRule(rRule) {
                return rRule.rfcstring ? rrulestr(rRule.rfcstring).toText() : '';
            },

            changeCollapse() {
                this.collapsed = !this.collapsed;
            },

            deleteServiceChange() {
                if (window.confirm('Are you sure you want to delete this data correction?')) {
                    this.$inertia.delete(this.$route('services.service-updates.destroy', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id}));
                }
            },

            format_field(value) {
                if (value && value.amount && value.currency) {
                    return this.$filters.format_money(value);
                }
                return value;
            }
        },

        computed: {
            hasChanged() {
                return {
                    'location_id' : this.serviceActivity.changes.indexOf('location_id') != -1,
                    'effective_date' : this.serviceActivity.changes.indexOf('effective_date') != -1,
                    'termination_date' : this.serviceActivity.changes.indexOf('termination_date') != -1,
                    'external_reference_id' : this.serviceActivity.changes.indexOf('external_reference_id') != -1,
                    'vendor_id' : this.serviceActivity.changes.indexOf('vendor_id') != -1,
                    'vendor_account_id' : this.serviceActivity.changes.indexOf('vendor_account_id') != -1,
                    'vendor_contract_id' : this.serviceActivity.changes.indexOf('vendor_contract_id') != -1,
                    'service_account_type' : this.serviceActivity.changes.indexOf('service_account_type') != -1,
                    'service_schedule_type' : this.serviceActivity.changes.indexOf('service_schedule_type') != -1,
                    'pickup_rrule' : this.serviceActivity.changes.indexOf('pickup_rrule') != -1,
                    'vendor_base_charge' : this.serviceActivity.changes.indexOf('vendor_base_charge') != -1,
                    'vendor_per_unit_charge' : this.serviceActivity.changes.indexOf('vendor_per_unit_charge') != -1,
                    'vendor_disposal_amount_per_ton' : this.serviceActivity.changes.indexOf('vendor_disposal_amount_per_ton') != -1,
                    'vendor_per_haul_amount' : this.serviceActivity.changes.indexOf('vendor_per_haul_amount') != -1,
                    'tons_included_per_haul' : this.serviceActivity.changes.indexOf('tons_included_per_haul') != -1,
                    'bin_quantity' : this.serviceActivity.changes.indexOf('bin_quantity') != -1,
                    'bin_size' : this.serviceActivity.changes.indexOf('bin_size') != -1,
                    'bin_size_metric' : this.serviceActivity.changes.indexOf('bin_size_metric') != -1,
                    'bin_material_type' : this.serviceActivity.changes.indexOf('bin_material_type') != -1,
                    'bin_disposal_method' : this.serviceActivity.changes.indexOf('bin_disposal_method') != -1,
                    'bin_type' : this.serviceActivity.changes.indexOf('bin_type') != -1,
                    'bin_is_compacted_or_baled' : this.serviceActivity.changes.indexOf('bin_is_compacted_or_baled') != -1,
                    'bin_compaction_ratio' : this.serviceActivity.changes.indexOf('bin_compaction_ratio') != -1,
                    'bin_pounds_per_yard' : this.serviceActivity.changes.indexOf('bin_pounds_per_yard') != -1,
                    'bin_has_casters' : this.serviceActivity.changes.indexOf('bin_has_casters') != -1,
                    'bin_enclosure' : this.serviceActivity.changes.indexOf('bin_enclosure') != -1,
                    'bin_requires_overhead_instructions' : this.serviceActivity.changes.indexOf('bin_requires_overhead_instructions') != -1,
                    'bin_container_access_times' : this.serviceActivity.changes.indexOf('bin_container_access_times') != -1,
                    'bin_configuration' : this.serviceActivity.changes.indexOf('bin_configuration') != -1,
                    'bin_owner' : this.serviceActivity.changes.indexOf('bin_owner') != -1,
                    'bin_keys' : this.serviceActivity.changes.indexOf('bin_keys') != -1,
                    'bin_has_lids' : this.serviceActivity.changes.indexOf('bin_has_lids') != -1,
                    'bin_has_lock' : this.serviceActivity.changes.indexOf('bin_has_lock') != -1,
                    'bin_secured_access' : this.serviceActivity.changes.indexOf('bin_secured_access') != -1,
                    'bin_push_out_service' : this.serviceActivity.changes.indexOf('bin_push_out_service') != -1,
                    'bin_side_door_to_bin' : this.serviceActivity.changes.indexOf('bin_side_door_to_bin') != -1,
                    'bin_compaction_method' : this.serviceActivity.changes.indexOf('bin_compaction_method') != -1,
                    'bin_area_description' : this.serviceActivity.changes.indexOf('bin_area_description') != -1,
                    'client_contract_id' : this.serviceActivity.changes.indexOf('client_contract_id') != -1,
                    'is_pass_through' : this.serviceActivity.changes.indexOf('is_pass_through') != -1,
                    'client_billing_frequency' : this.serviceActivity.changes.indexOf('client_billing_frequency') != -1,
                    'client_base_charge' : this.serviceActivity.changes.indexOf('client_base_charge') != -1,
                    'client_per_unit_charge' : this.serviceActivity.changes.indexOf('client_per_unit_charge') != -1,
                    'client_disposal_amount_per_ton' : this.serviceActivity.changes.indexOf('client_disposal_amount_per_ton') != -1,
                    'client_per_haul_amount' : this.serviceActivity.changes.indexOf('client_per_haul_amount') != -1,
                    'label' : this.serviceActivity.changes.indexOf('label') != -1,
                }
            },

            hasField() {
                return {
                    'average_pickups_per_week':       ['Commercial', 'Doorstep', 'Rolloff'].includes(this.serviceActivity.serviceSnapshot.type),
                    'client_base_charge':             ['Device', 'Rental', 'Doorstep', 'Commercial'].includes(this.serviceActivity.serviceSnapshot.type),
                    'client_disposal_amount_per_ton': this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'client_per_haul_amount':         this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'client_per_unit_charge':         this.serviceActivity.serviceSnapshot.type === 'Doorstep',
                    'pickup_rrule':                   ['Commercial', 'Doorstep', 'Rolloff'].includes(this.serviceActivity.serviceSnapshot.type),
                    'vendor_base_charge':             ['Device', 'Rental', 'Doorstep', 'Commercial'].includes(this.serviceActivity.serviceSnapshot.type),
                    'vendor_disposal_amount_per_ton': this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'vendor_per_haul_amount':         this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'vendor_per_unit_charge':         this.serviceActivity.serviceSnapshot.type === 'Doorstep',
                }
            },

            compactionMethod() {
                let matrix = {
                    'na': 'N/A',
                    'auger': 'Auger',
                    'hydraulic': 'Hydraulic',
                };

                return matrix[this.serviceActivity.serviceSnapshot.compaction_method];
            }
        }
    }
</script>
