<template>
    <div>
        <div v-if="who === 'broker'" class="my-8"><b>You</b> are entering a <b>client billing change</b>. Please fill in the details.</div>
        <div v-else class="my-8">The <b>{{ who }}</b> is requesting a <b>client billing change</b>. Please fill in the details.</div>

        <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
            <date-input class="col-span-2 md:col-span-1" :min="earliestServiceActivity.actual_date" v-model="actual_date" label="When should this change happen?" :errors="errors['actual_date']" mark-as-required />

            <div class="col-span-2 md:col-span-1" >
                <p v-if="actual_date && actual_date < today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data such as old invoice line items.</p>
                <p v-if="actual_date && actual_date > today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.</p>
            </div>

            <select-input class="col-span-2 md:col-span-1" label="Why are you making this change?" v-model="change_reason" mark-as-required>
                <option :value="null">Select a Reason</option>
                <option>Annual Price Increase</option>
                <option>Other</option>
                <option>Savings</option>
            </select-input>

            <select-input v-model="form.is_pass_through" label="Is Pass Through?" class="col-span-2 md:col-span-1" :errors="errors['is_pass_through']" mark-as-required>
                <option :value="true">Yes</option>
                <option :value="false">No</option>
            </select-input>

            <select-input :disabled="form.is_pass_through === true && service.type !== 'Rolloff'" v-model="form.client_billing_frequency" class="col-span-2 md:col-span-1" label="Client Billing Frequency" placeholder="Client Billing Frequency" :errors="errors['client_billing_frequency']" mark-as-required>
                <option :value="null">Select a Frequency</option>
                <option v-for="clientBillingFrequency in clientBillingFrequencies" :key="clientBillingFrequency" :value="clientBillingFrequency">{{ clientBillingFrequency }}</option>
            </select-input>

            <money-input v-if="form.is_pass_through === false && serviceHasClientBaseCharge" :defaultCurrency="service.location.clientCompany.currency" v-model="form.client_base_charge" class="col-span-2 md:col-span-1" label="Base Charge" placeholder="Base Charge" :errors="errors['client_base_charge']" mark-as-required />

            <money-input v-if="form.is_pass_through === false && serviceHasClientPerUnitCharge" :defaultCurrency="service.location.clientCompany.currency" v-model="form.client_per_unit_charge" class="col-span-2 md:col-span-1" label="Per Unit Charge" placeholder="Per Unit Charge" :errors="errors['client_per_unit_charge']" mark-as-required />

            <money-input v-if="form.is_pass_through === false && serviceHasClientPerHaulAmount" :defaultCurrency="service.location.clientCompany.currency" v-model="form.client_per_haul_amount" class="col-span-2 md:col-span-1" label="Per Haul Amount" placeholder="Per Haul Amount" :errors="errors['client_per_haul_amount']" mark-as-required />

            <money-input v-if="form.is_pass_through === false && serviceHasClientDisposalAmountPerTon" :defaultCurrency="service.location.clientCompany.currency" v-model="form.client_disposal_amount_per_ton" class="col-span-2 md:col-span-1" label="Disposal Amount Per Ton" placeholder="Disposal Amount Per Ton" :errors="errors['client_disposal_amount_per_ton']" mark-as-required />

            <textarea-input class="col-span-2" v-model="internal_change_notes" label="Internal Change Notes" />

            <template v-if="showPriorPeriodAdjustment">
                <hr class="col-span-2 my-4" />

                <div class="col-span-2 rounded-md bg-yellow-50 py-2 px-1">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-yellow-800">Prior Period Adjustment</h3>
                            <div class="mt-2 text-sm text-yellow-700">
                                <div class="pr-2">
                                    You are changing the price from
                                    <span class="font-medium">{{ $filters.format_money(service.client_base_charge) }}</span> to
                                    <span class="font-medium">{{ $filters.format_money(form.client_base_charge) }}</span> effective
                                    <span class="font-medium">{{ $filters.format_date(actual_date) }}</span>.
                                    <span v-if="priorPeriodAdjustment.lastBilledThru !== null">
                                        This service has already been billed through the end of
                                        <span class="font-medium">{{ priorPeriodAdjustment.lastBilledThru }}</span>.
                                    </span>
                                    <p class="mt-1">If you do not wish to bill a prior period adjustment for this change, please enter in $0.00 below and no charge will be made.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <money-input label="Prorated Amount" :defaultCurrency="$page.props.currency" v-model="priorPeriodAdjustment.proratedAmount"></money-input>
                <textarea-input label="Line Item Description" v-model="priorPeriodAdjustment.description"></textarea-input>
            </template>
        </div>

        <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">Submit Change</loading-button>
    </div>
</template>

<script>
import SelectInput from '@/Shared/SelectInput.vue';
import MoneyInput from '@/Shared/MoneyInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import DateInput from '@/Shared/DateInput.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import {format, startOfToday} from "date-fns";
import axios from 'axios';

export default {
    components: {
        SelectInput,
        DateInput,
        TextareaInput,
        LoadingButton,
        TextInput,
        MoneyInput,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        clientBillingFrequencies: {
            type: Array,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            saving: false,
            form: {
                is_pass_through:                this.service.is_pass_through,
                client_billing_frequency:       this.service.client_billing_frequency,
                client_base_charge:             this.service.client_base_charge ?? null,
                client_per_unit_charge:         this.service.client_per_unit_charge ?? null,
                client_per_haul_amount:         this.service.client_per_haul_amount ?? null,
                client_disposal_amount_per_ton: this.service.client_disposal_amount_per_ton ?? null,
            },
            actual_date:            format(startOfToday(), 'yyyy-MM-dd'),
            change_reason:          null,
            change_requester:       this.who,
            internal_change_notes:  null,
            today:                  format(startOfToday(), 'yyyy-MM-dd'),
            priorPeriodAdjustment:  null,
        }
    },

    methods: {
        submitChange() {
            this.saving = true;

            // should we schedule this or fire it off immediately?
            // we can call different routes or we could just handle in the controller... not sure which is easiest
            this.$inertia.post(this.$route('services.client-billing-changes.store', [this.service.id]), {
                ...this.form,
                'actual_date': this.actual_date,
                'change_reason': this.change_reason,
                'change_requester': this.who,
                'internal_change_notes': this.internal_change_notes,
                'ppa_amount': this.showPriorPeriodAdjustment ? this.priorPeriodAdjustment.proratedAmount : null,
                'ppa_description': this.showPriorPeriodAdjustment ? this.priorPeriodAdjustment.description : null,                
            }, {
                onFinish: () => this.saving = false
            });
        },

        suggestPriorPeriodAdjustment() {
            if (!this.$page.props.permissions.accessRevenueManagement) {
                return false;
            }

            if (this.form.is_pass_through) {
                return false;
            }

            if (!['Monthly', 'Quarterly', 'Yearly'].includes(this.form.client_billing_frequency)) {
                return false;
            }

            if (this.form.service_schedule_type === 'One Time') {
                return false;
            }

            if (this.service.type === 'Rolloff') {
                return false;
            }

            if (this.service.client_base_charge === this.form.client_base_charge) {
                return false;
            }

            return true;
        },

        updatePriorPeriodAdjustmentDetails() {
            axios.get(`/json/services/${this.service.id}/prior-period-adjustments/create-from-price-change?change_date=${this.actual_date}&adjusted_amount=${this.form.client_base_charge.amount/100}`).then(response => {
              this.priorPeriodAdjustment = response.data
            });
        }
    },

    computed: {
        serviceHasClientBaseCharge() {
            return ['Commercial', 'Device', 'Doorstep', 'Rental'].includes(this.service.type);
        },

        serviceHasClientPerUnitCharge() {
            return ['Doorstep'].includes(this.service.type);
        },

        serviceHasClientPerHaulAmount() {
            return ['Rolloff'].includes(this.service.type);
        },

        serviceHasClientDisposalAmountPerTon() {
            return ['Rolloff'].includes(this.service.type);
        },
        showPriorPeriodAdjustment() {
            return this.suggestPriorPeriodAdjustment() && this.priorPeriodAdjustment?.proratedAmount?.amount && this.priorPeriodAdjustment.proratedAmount.amount != 0
        }
    },

    watch: {
        'form.client_base_charge'() {
            if (this.suggestPriorPeriodAdjustment()) {
                this.updatePriorPeriodAdjustmentDetails();
            }
        },

        'actual_date'() {
            if (this.suggestPriorPeriodAdjustment()) {
                this.updatePriorPeriodAdjustmentDetails();
            }
        },

        'form.is_pass_through'(newValue, oldValue) {
            if (newValue === true && this.service.type !== 'Rolloff') {
                this.form.client_billing_frequency = 'Per Vendor Invoice';
            } else {
                this.form.client_billing_frequency = this.service.client_billing_frequency;
            }
        }
    }
}
</script>
