<template>
    <div :class="$attrs.class" class="relative" @mouseleave="hideSearchIfGoneForAWhile" @mouseenter="cancelSearchHiding">
        <slot name="label" :label="label" :mark-as-required="markAsRequired">
            <label v-if="label" class="form-label" :for="id" :aria-required="markAsRequired">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
            </label>
        </slot>


        <p v-if="helpText" class="mt-2 text-sm text-gray-600">{{ helpText }}</p>
        
        <input
            :id="id"
            :disabled="disabled"
            autocomplete="off"
            type="search"
            ref="input"
            v-bind="{...$attrs, class: null, value: null}"
            class="form-input"
            :class="{ error: errors }"
            :value="currentResultDisplayValue"
            @input.prevent="performSearch"
            v-on:keyup.down="moveHighlightDown"
            v-on:keyup.up="moveHightlightUp"
            v-on:keyup.enter="selectHighlightedResult"
            @click="toggleSearch"
            @blur="resetInputValue"
        >

        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <div v-show="searchShown" class="absolute pin-l mt-2 min-w-full bg-white p-2 z-50 shadow-lg">
                <div v-if="searchResults.length === 0">
                    <div v-if="searching">
                        <span class="">Searching...</span>
                        <icon name="search" class="w-3 h-3 fill-current inline search-icon search-animation" /> 
                    </div>
                    <div v-else>
                        No results found!                        
                    </div>
                </div>

                <slot name="searchResults" :searchResults="searchResults" :hovering="hovering" :selectSearchResult="selectSearchResult">
                    <div v-for="(result, index) in searchResults" :key="result[valueProperty]">
                        <div class="pb-2">
                            <div class="p-1 cursor-pointer" @mouseover="hovering(index)" @click.prevent="selectSearchResult(result)" :class="{'bg-gray-300' : index === highlightedIndex}">
                                <slot name="searchResult" :result="result">
                                    <span>{{ result[displayProperty] }}</span>
                                </slot>
                            </div>
                        </div>
                    </div>
                </slot>
            </div>
        </transition>

        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import Icon from '@/Shared/Icon.vue';

export default {
    inheritAttrs: false,
    
    props: {
        id: {
            type: String,
            default() {
                return `search-input-${uuid()}`;
            },
        },

        displayProperty: {
            type: String,
            default() {
                return 'id';
            }
        },

        valueProperty: {
            type: String,
            default() {
                return 'id';
            }
        },

        route: {
            type: String,
            default() {
                return ''
            }
        },

        routeParams: {
            type: Object,
            default() {
                return {}
            }
        },

        type: {
            type: String,
            default: 'text',
        },

        modelValue: [String, Number, Array],

        label: String,

        helpText: {
            type: String,
            default: ''
        },

        errors: {
            type: String,
            default: '',
        },

        markAsRequired: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        defaultValue: {
            type: Object,
            default: null
        },

        searchFilterName: {
            type: String,
            default: 'search'
        },

        options: {
            type: Array,
            default: []
        }
    },

    emits: [
        'update:modelValue',
        'updated',
        'selected',
        'update:options',
    ],

    components: {
        Icon
    },

    data() {
        let selectedValue = null;
        
        if (typeof this.$attrs['multiple'] !== undefined) {
            selectedValue = [];    
        }

        return {
            searchShown: false,
            searchResults: [],
            searching: false,
            highlightedIndex: null,
            windowTimeout: null,
            currentResultDisplayValue: null,
            selectedValue: selectedValue,
        }
    },

    methods: {
        focus() {
            this.$refs.input.focus()
        },

        select() {
            this.$refs.input.select()
        },

        resetInputValue() {
            if (this.$refs.input) {
                this.$refs.input.value = this.currentResultDisplayValue;
            }
        },

        setSelectionRange(start, end) {
            this.$refs.input.setSelectionRange(start, end);
        },

        hideSearch() {
            this.searchShown = false;
        },

        performSearch($event) {
            if (!$event.target.value) {
                this.currentResultDisplayValue = null;

                return;
            }

            this.highlightedIndex = null;
            this.searchShown = true;
            this.searching = true;

            this.emitSearchEvent($event)
        },

        defaultSearch(query) {
            axios.get(this.$route(this.route, {[this.searchFilterName]: query.toLowerCase(), ...this.routeParams})).then(response => {
                this.updateSearchResults(response.data.data);
            })
        },

        emitSearchEvent: debounce(function($event) {
            if (this.route) {
                this.defaultSearch($event.target.value)
            } else {
                this.$emit('search', $event.target.value, this.updateSearchResults)
            }
        }, 1000),

        updateSearchResults(results) {
            this.searching = false
            this.searchResults = results

            // sends an updated event so when the user is typing out stuff,
            // if they happen to type the exact result then this model gets sent up the chain
            // it also lets us know when the search results have finished updating
            
            let result = results.find((result) => result[this.displayProperty] === this.modelValue);
            this.$emit('updated', typeof result === 'undefined' ? null : result)
            this.$emit('update:options', this.searchResults)
        },

        selectSearchResult(result) {
            if (result[this.displayProperty] === '' || result[this.valueProperty] === '') {
                return;
            }

            this.currentResultDisplayValue = result[this.displayProperty];  // Needs to be a function passed a prop. Should be invoked with current selected items
            this.$emit('update:modelValue', result[this.valueProperty]);
            this.$emit('selected', result);
            this.$emit('updated', result);
            this.searchShown = false;
        },

        moveHighlightDown() {
            if (this.highlightedIndex === null) {
                this.highlightedIndex = 0;
            } else if (this.highlightedIndex < this.searchResults.length - 1) {
                this.highlightedIndex++;
            }
        },

        moveHightlightUp() {
            if (this.highlightedIndex === null && this.searchResults.length > 0) {
                this.highlightedIndex = this.searchResults.length - 1;
            } else if (this.highlightedIndex > 0) {
                this.highlightedIndex--;
            }
        },

        selectHighlightedResult() {
            if (this.highlightedIndex !== null) {
                this.selectSearchResult(this.searchResults[this.highlightedIndex])
            }
        },

        hovering(index) {
            this.highlightedIndex = index
        },

        cancelSearchHiding() {
            clearTimeout(this.windowTimeout)
            this.windowTimeout = null
        },

        hideSearchIfGoneForAWhile() {
            if (!this.windowTimeout) {
                this.windowTimeout = setTimeout(() => this.hideSearch(), 2000);
            }
        },

        toggleSearch() {
            if (this.searchResults.length > 0) {
                this.searchShown = this.searchShown ? false : true
            } else {
                this.searchShown = false
            }
        }
    },

    watch: {
        currentResultDisplayValue(newValue, oldValue) {
            if (!newValue) {
                this.$emit('update:modelValue', null);
            }
        },

        modelValue(newValue, oldValue) {
            if (newValue === null) {
                return;
            }

            let newDisplayValue = newValue[this.displayProperty] ?? null;

            if (newDisplayValue !== null && this.currentResultDisplayValue !== newDisplayValue) {
                this.currentResultDisplayValue = newDisplayValue;
            }
        }
    },

    mounted() {
        if (this.defaultValue) {
            this.currentResultDisplayValue = this.defaultValue[this.displayProperty];
        }
    }
}
</script>

<style scoped>
    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }
    @keyframes search-animation {
        0%   { transform: translateY(0); }
        30%  { transform: translateY(-10px); }
        50%  { transform: translateY(0) translateX(5px); }
        100% { transform: translateY(0) translateX(0); }
    }
</style>