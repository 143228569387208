<template>
    <Head :title="`Edit - ${emailTemplate.type}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.email-templates.index')" class="breadcrumb-link">Email Templates</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            {{ emailTemplate.type }}
        </nav>
    </Teleport>

    <div class="container mx-auto">
        <div v-if="$page.props.tenant.email_domain_status === 'verified'" class="bg-blue-100 rounded shadow-md p-4 my-4">
            <p>Please ensure From Email ends in {{ fromEmailShouldEndWith }} to ensure email deliverability.</p>
        </div>
        <div v-else-if="$page.props.tenant.email_domain_status === 'partially-verified-missing-mx-records'" class="bg-yellow-100 rounded shadow-md p-4 my-4">
            <p>Your email domain is partially verified, but is missing recommended MX records.</p>
            <p>Please add the MX records listed on the <inertia-link class="link" :href="route('tenant-settings.email-settings.edit')">email settings</inertia-link> page to your DNS to ensure email deliverability.</p>
            <p>Please end the "From Email" address with {{ fromEmailShouldEndWith }}.</p>
        </div>
        <div v-else class="bg-yellow-100 rounded shadow-md p-4 my-4">
            <p>Your email domain is not verified. Please update your <inertia-link class="link" :href="route('tenant-settings.email-settings.edit')">email settings</inertia-link> to ensure email deliverability.</p>
        </div>

        <div class="my-4">
            <loading-button :loading="state === 'deleting'" :disabled="state !== 'passive'" @click.prevent="deleteEmailTemplate" class="btn btn-red ml-auto text-right">
                Delete
            </loading-button>
        </div>

        <div class="my-4">
            <form id="edit-email-template" class="w-full">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Template Type</label>
                        <div class="mt-1">
                            {{ emailTemplate.type }}
                        </div>
                    </div>

                    <text-input v-model="form.from_email" label="From Email" :errors="errors.from_email" mark-as-required/>
                    <text-input v-model="form.from_name" label="From Name" :errors="errors.from_name" mark-as-required/>
                    <text-input v-model="form.cc_recipients" label="CC Recipients" :errors="errors.cc_recipients" />
                    <text-input v-model="form.bcc_recipients" label="BCC Recipients" :errors="errors.bcc_recipients" />

                    <text-input-with-variables v-model="form.subject" :variables="emailTemplate.variables" class="md:col-span-2 lg:col-span-3" label="Subject" :errors="errors.subject" mark-as-required/>
                    <div class="md:col-span-2 lg:col-span-3">
                        <quill-editor v-model="form.body" label="Body" :errors="errors.body" :variables="emailTemplate.variables" mark-as-required/>
                    </div>

                </div>

                <div class="mt-6">
                    <loading-button class="btn btn-blue" :loading="state === 'saving'" :disabled="state !== 'passive'" @click.prevent="updateEmailTemplate">
                        Save
                    </loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { ref, reactive, inject, onMounted, watch } from 'vue';

// Components
import { Head } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from "@/Shared/LoadingButton.vue";
import QuillEditor from "@/Shared/QuillEditor.vue";
import TextInput from '@/Shared/TextInput.vue';
import TextInputWithVariables from '@/Shared/TextInputWithVariables.vue';

// Inject
const route = inject('route');

// Props
const props = defineProps({
    emailTemplate: {
        type: Object,
        required: true
    },

    fromEmailShouldEndWith: {
        type: String,
    },

    errors: {
        type: Object,
        default: () => ({})
    },
});

// State
const form = reactive({
    from_email: props.emailTemplate.from_email,
    from_name: props.emailTemplate.from_name,
    subject: props.emailTemplate.subject,
    body: props.emailTemplate.body,
    cc_recipients: props.emailTemplate.cc_recipients?.join(','),
    bcc_recipients: props.emailTemplate.bcc_recipients?.join(','),
});
const mounted = ref(false);
const state = ref('passive');

// Mount
onMounted(() => {
    mounted.value = true;
});

// Methods
function updateEmailTemplate() {
    if (state.value !== 'passive') {
        return;
    }

    state.value = 'saving';

    router.put(route('tenant-settings.email-templates.update', props.emailTemplate.id), form, {
        preserveScroll: true,
        onFinish: () => {
            state.value = 'passive';
        }
    });
}

function deleteEmailTemplate() {
    if (state.value !== 'passive') {
        return;
    }

    if (window.confirm("Are you sure that you want to delete this email template? This will cause future emails of this type to use the default template.")) {
        state.value = 'deleting';

        router.delete(route('tenant-settings.email-templates.destroy', props.emailTemplate.id), {
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }
}
</script>