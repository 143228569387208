<template>
    <Head :title="`Edit Level of Service Change - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Level of Service Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
    </div>

    <div class="container mx-auto mt-8 mb-12">
        <div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <revert-input v-model="form.change_requester" class="col-span-2 md:col-span-1" label="Who requested this change?" mark-as-required>
                    <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                        <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                        <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                        <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.actual_date" class="col-span-3 md:col-span-1" label="When is this change effective?" mark-as-required>
                    <date-input :min="serviceCreationActivity.actual_date" v-model="form.actual_date" :errors="errors['actual_date']" />
                </revert-input>

                <revert-input v-model="form.change_reason" class="col-span-3 md:col-span-1" label="Why are you making this change?" mark-as-required>
                    <select-input v-model="form.change_reason">
                        <option>Other</option>
                        <option>Savings</option>
                    </select-input>
                </revert-input>
            </div>

            <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
                <revert-input v-model="form.vendor_account_id" class="col-span-2 md:col-span-1" label="Vendor Account">
                    <select-input v-model="form.vendor_account_id" :errors="errors['vendor_account_id']">
                        <option v-if="!vendorAccounts.length" :value="null">No accounts available for this Vendor</option>
                        <option v-for="vendorAccount in vendorAccounts" :value="vendorAccount.id">{{ vendorAccount.account_number + " - " + vendorAccount.status }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.vendor_contract_selection" class="col-span-2 md:col-span-1" label="Vendor Contract" mark-as-required>
                    <select-input v-model="form.vendor_contract_selection" :errors="errors['vendor_contract_selection']">
                        <option value='missing-contract'>Missing contract</option>
                        <option :value=null>No contract</option>
                        <option v-for="vendorContract in vendorContracts" :value="vendorContract.id" :key="vendorContract.id">{{ vendorContract.display_id + " - " + vendorContract.status + " - Effective " + vendorContract.effective_date ?? " - " }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.client_contract_id" class="col-span-2 md:col-span-1" label="Client Contract">
                    <select-input v-model="form.client_contract_id" :errors="errors['client_contract_id']">
                        <option v-if="clientContracts.length === 0" :value="null">No contracts available for this Client</option>
                        <option v-for="clientContract in clientContracts" :key="clientContract.id" :value="clientContract.id">{{ clientContract.id + " - Integration " + clientContract.integration_date ?? " - " }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.service_account_type" class="col-span-2 md:col-span-1" label="Service Account Type" mark-as-required>
                    <select-input v-model="form.service_account_type" :errors="errors['service_account_type']">
                        <option>Permanent</option>
                        <option>Temporary</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.service_schedule_type" class="col-span-2 md:col-span-1" label="Service Schedule Type" mark-as-required>
                    <select-input v-model="form.service_schedule_type" :errors="errors['service_schedule_type']">
                        <option>Scheduled</option>
                        <option>On Call</option>
                        <option v-if="form.service_account_type==='Temporary'">One Time</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.pickup_rrule" class="col-span-2 md:col-span-1" label="Pickup Schedule" :mark-as-required="serviceHasPickupRrule && form.service_schedule_type === 'Scheduled'">
                    <div v-if="serviceHasPickupRrule && form.service_schedule_type === 'Scheduled'">
                        <div class="text-sm py-3 text-gray-800">
                            {{ rrule_description }} <a href="#" class="" @click.prevent="showUpdatePickupRRuleModal"><icon name="edit" class="inline fill-current w-4 h-4"></icon></a>
                        </div>

                        <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500 text-sm">
                            The pickup schedule is invalid
                        </div>
                    </div>
                    <div v-else class="py-3 font-gray-800 text-sm">
                        Cannot schedule {{ form.service_schedule_type.toLowerCase() ?? 'unscheduled '}} services
                    </div>
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Vendor Base Charge" v-if="serviceHasVendorBaseCharge" v-model="form.vendor_base_charge">
                    <money-input v-model="form.vendor_base_charge" :defaultCurrency="$page.props.currency" placeholder="Base Charge" :errors="errors['vendor_base_charge']" />
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Vendor Per Unit Charge" v-if="serviceHasVendorPerUnitCharge" v-model="form.vendor_per_unit_charge">
                    <money-input v-model="form.vendor_per_unit_charge" :defaultCurrency="$page.props.currency" placeholder="Per Unit Charge" :errors="errors['vendor_per_unit_charge']" />
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Vendor Disposal Amount Per Ton" v-if="serviceHasVendorDisposalAmountPerTon" v-model="form.vendor_disposal_amount_per_ton">
                    <money-input v-model="form.vendor_disposal_amount_per_ton" :defaultCurrency="$page.props.currency" placeholder="Disposal Amount Per Ton" :errors="errors['vendor_disposal_amount_per_ton']" />
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Vendor Per Haul Amount" v-if="serviceHasVendorPerHaulAmount" v-model="form.vendor_per_haul_amount">
                    <money-input v-model="form.vendor_per_haul_amount" :defaultCurrency="$page.props.currency" placeholder="Per Haul Amount" :errors="errors['vendor_per_haul_amount']" />
                </revert-input>

                <revert-input class="col-span-2 md:col-span-1" label="Tons Included Per Haul" v-if="serviceHasTonsIncludedPerHaul" v-model="form.tons_included_per_haul">
                    <text-input v-model="form.tons_included_per_haul" class="col-span-2 md:col-span-1" type="number" min="0" :errors="errors['tons_included_per_haul']" markAsRequired />
                </revert-input>

                <revert-input v-if="form.is_pass_through === false && serviceHasClientBaseCharge" v-model="form.client_base_charge" class="col-span-2 md:col-span-1" label="Client Base Charge" mark-as-required>
                    <money-input v-model="form.client_base_charge" :defaultCurrency="$page.props.currency" placeholder="Base Charge" :errors="errors['client_base_charge']" />
                </revert-input>

                <revert-input v-if="form.is_pass_through === false && serviceHasClientPerUnitCharge" v-model="form.client_per_unit_charge" class="col-span-2 md:col-span-1" label="Client Per Unit Charge" mark-as-required>
                    <money-input v-model="form.client_per_unit_charge" :defaultCurrency="$page.props.currency" placeholder="Per Unit Charge" :errors="errors['client_per_unit_charge']" />
                </revert-input>

                <revert-input v-if="form.is_pass_through === false && serviceHasClientPerHaulAmount" v-model="form.client_per_haul_amount" class="col-span-2 md:col-span-1" label="Client Per Haul Amount" mark-as-required>
                    <money-input  v-model="form.client_per_haul_amount" :defaultCurrency="$page.props.currency"  placeholder="Per Haul Amount" :errors="errors['client_per_haul_amount']" />
                </revert-input>

                <revert-input v-if="form.is_pass_through === false && serviceHasClientDisposalAmountPerTon" v-model="form.client_disposal_amount_per_ton" class="col-span-2 md:col-span-1" label="Client Disposal Amount Per Ton" mark-as-required>
                    <money-input  v-model="form.client_disposal_amount_per_ton" :defaultCurrency="$page.props.currency" placeholder="Disposal Amount Per Ton" :errors="errors['client_disposal_amount_per_ton']" />
                </revert-input>
            </div>

	        <fieldset v-if="serviceActivity.serviceSnapshot.type === 'Doorstep'" class="mt-4">
		        <legend class="block font-semibold text-xl text-d-blue-500">Bin</legend>

		        <div class="grid gap-x-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
			        <revert-input class="col-span-2 md:col-span-1" label="Equipment Label" v-model="form.label">
			            <text-input v-model="form.label" maxlength="50" class="col-span-1" :errors="errors['label']" />
			        </revert-input>
		        </div>
		        <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
			        <revert-input class="col-span-2 md:col-span-1" label="Quantity" v-model="form.bin.quantity">
			            <text-input class="col-span-2 md:col-span-1" v-model="form.bin.quantity" type="number" />
			        </revert-input>

			        <revert-input class="col-span-2 md:col-span-1" label="Size" v-model="form.bin.size">
			            <text-input class="col-span-2 md:col-span-1" v-model="form.bin.size" type="number"/>
			        </revert-input>

			        <revert-input class="col-span-2 md:col-span-1" label="Size Metric" v-model="form.bin.size_metric">
			            <select-input class="col-span-2 md:col-span-1" v-model="form.bin.size_metric">
			                <option value="yards">yards</option>
			                <option value="gallons">gallons</option>
			                <option value="foot">foot</option>
			            </select-input>
			        </revert-input>

			        <revert-input class="col-span-2 md:col-span-1" label="Type" v-model="form.bin.type">
			            <select-input class="col-span-2 md:col-span-1" v-model="form.bin.type">
			                <option v-for="bin_type in binTypes" :value="bin_type.name" :key="bin_type.name">{{ bin_type.name }}</option>
			            </select-input>
			        </revert-input>
		        </div>
	        </fieldset>

	        <div v-if="serviceActivity.serviceSnapshot.type !== 'Device' && serviceActivity.serviceSnapshot.type !== 'Doorstep'" class="mt-6">
		        <span class="font-semibold text-xl text-d-blue-500">Bin</span>
		        <div class="grid gap-x-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-4">
			        <revert-input class="col-span-2 md:col-span-1" label="Equipment Label" v-model="form.label">
				        <text-input v-model="form.label" maxlength="50" class="col-span-1" :errors="errors['label']"/>
			        </revert-input>
		        </div>
		        <revertible-bin :property-type="serviceActivity.serviceSnapshot.location?.property_type" :bin-types="binTypes" :material-types="materialTypes" :asset-owners="assetOwners" v-model="form.bin" />
	        </div>

            <revert-input v-model="form.internal_change_notes" class="col-span-2" label="Internal Change Notes">
                <textarea-input v-model="form.internal_change_notes" />
            </revert-input>

            <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">Submit Change</loading-button>
        </div>

        <!-- Pickup RRule Modal -->
        <modal v-if="serviceActivity.serviceSnapshot.type === 'Commercial' || serviceActivity.serviceSnapshot.type === 'Doorstep' || serviceActivity.serviceSnapshot.type === 'Rolloff'" class="max-w-2xl" ref="updatePickupRRuleModal" tabindex="-1" role="dialog">
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Pickup Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6" />
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Average Pickups Per Week"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import Modal from "@/Shared/Modal.vue";
    import RRuleInput from "@/Shared/RRuleInput.vue";
    import TextInput from "@/Shared/TextInput.vue";
    import RevertibleBin from "@/Shared/Services/RevertibleBin.vue";
    import { Head } from '@inertiajs/vue3';
    import { format, startOfToday } from "date-fns";

    export default {
        components: {
            TextInput,
            RRuleInput,
            Modal,
            DateInput,
            Icon,
            LoadingButton,
            MoneyInput,
            RevertInput,
            RevertibleBin,
            SelectInput,
            TextareaInput,
            Head,
        },

        props: {
            assetOwners: {
                type: Array,
                required: true
            },

            binTypes: {
                type: Array,
                required: true
            },

            clientBillingFrequencies: {
                type: Array,
                required: true
            },

            clientContracts: {
                type: Array,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

            materialTypes: {
                type: Array,
                required: true
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            serviceCreationActivity: {
                type: Object,
                required: true
            },

            vendorAccounts: {
                type: Array,
                required: true
            },

            vendorContracts: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                form: {
                    actual_date:                    this.serviceActivity.actual_date,
                    change_reason:                  this.serviceActivity.reason,
                    change_requester:               this.serviceActivity.change_requester,
                    internal_change_notes:          this.serviceActivity.internal_change_notes,
                    vendor_account_id:              this.serviceActivity.serviceSnapshot.vendor_account_id,
                    vendor_contract_id:             this.serviceActivity.serviceSnapshot.vendor_contract_id,
                    vendor_contract_selection:      this.serviceActivity.serviceSnapshot.vendor_contract_selection,
                    client_contract_id:             this.serviceActivity.serviceSnapshot.client_contract_id,
                    is_pass_through:                this.serviceActivity.serviceSnapshot.is_pass_through,
                    service_account_type:           this.serviceActivity.serviceSnapshot.service_account_type,
                    service_schedule_type:          this.serviceActivity.serviceSnapshot.service_schedule_type,
                    pickup_rrule:                   this.serviceActivity.serviceSnapshot.pickup_rrule,
                    average_pickups_per_week:       this.serviceActivity.serviceSnapshot.average_pickups_per_week,
                    vendor_base_charge:             this.serviceActivity.serviceSnapshot.vendor_base_charge ?? null,
                    vendor_per_unit_charge:         this.serviceActivity.serviceSnapshot.vendor_per_unit_charge ?? null,
                    vendor_per_haul_amount:         this.serviceActivity.serviceSnapshot.vendor_per_haul_amount ?? null,
                    vendor_disposal_amount_per_ton: this.serviceActivity.serviceSnapshot.vendor_disposal_amount_per_ton ?? null,
                    tons_included_per_haul:         this.serviceActivity.serviceSnapshot.tons_included_per_haul ?? null,
                    client_billing_frequency:       this.serviceActivity.serviceSnapshot.client_billing_frequency,
                    client_base_charge:             this.serviceActivity.serviceSnapshot.client_base_charge ?? null,
                    client_per_unit_charge:         this.serviceActivity.serviceSnapshot.client_per_unit_charge ?? null,
                    client_per_haul_amount:         this.serviceActivity.serviceSnapshot.client_per_haul_amount ?? null,
                    client_disposal_amount_per_ton: this.serviceActivity.serviceSnapshot.client_disposal_amount_per_ton ?? null,
	                label:						    this.serviceActivity.serviceSnapshot.label,
                    bin:                            {...this.serviceActivity.serviceSnapshot.bin},
                },
                saving: false,
                today: format(startOfToday(), 'yyyy-MM-dd'),
                mounted: false,
                rrule_description: null,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitChange() {
                this.saving = true;

                // should we schedule this or fire it off immediately?
                // we can call different routes or we could just handle in the controller... not sure which is easiest
                this.$inertia.put(this.$route('services.level-of-service-changes.update', [this.serviceActivity.service_id, this.serviceActivity.id]), {
                    ...this.form,
                }, {
                    onFinish: () => this.saving = false
                });
            },

            showUpdatePickupRRuleModal() {
                this.$refs.updatePickupRRuleModal.show();
            },

            deleteServiceChange() {
                if (window.confirm('Are you sure you want to delete this level of service change?')) {
                    this.$inertia.delete(this.$route('services.level-of-service-changes.destroy', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id}));
                }
            },
        },

        computed: {
            serviceHasClientBaseCharge() {
                return ['Commercial', 'Device', 'Doorstep', 'Rental'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasClientPerUnitCharge() {
                return ['Doorstep'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasClientPerHaulAmount() {
                return ['Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasClientDisposalAmountPerTon() {
                return ['Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasPickupRrule() {
                return ['Commercial', 'Device', 'Doorstep', 'Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasTonsIncludedPerHaul() {
                return ['Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasVendorBaseCharge() {
                return ['Commercial', 'Device', 'Doorstep', 'Rental'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasVendorPerUnitCharge() {
                return ['Doorstep'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasVendorPerHaulAmount() {
                return ['Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasVendorDisposalAmountPerTon() {
                return ['Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },
        },

        watch: {
            'form.client_base_charge'() {
                if (this.suggestPriorPeriodAdjustment()) {
                    this.updatePriorPeriodAdjustmentDetails();
                }
            },

            'actual_date'() {
                if (this.suggestPriorPeriodAdjustment()) {
                    this.updatePriorPeriodAdjustmentDetails();
                }
            }
        }
    }
</script>
