<template>
    <Head title="Vendor Invoice Sales Taxes" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Invoice Sales Taxes</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="vendor-sales-taxes" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <div class="flex my-4 items-center">
                        <div class="w-1/2">
                            <div class="my-2 mb-4">
                                <div class="input-group">
                                    <span class="input-group-item">
                                        <icon name="search" class="w-4 h-4 fill-current mr-2" />
                                    </span>

                                    <input type="text" v-model="form.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>
<!--                        To be used in sales taxes v2-->
<!--                        <div class="ml-auto my-2 mb-4">-->
<!--                            <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.create')" class="btn btn-blue">-->
<!--                                <icon name="plus" class="inline text-white fill-current h-4 w-4 mr-1" /> New-->
<!--                            </inertia-link>-->
<!--                        </div>-->
                    </div>

                    <div v-if="vendorSalesTaxes.data.length">
                        <div class="my-2">
                            (Showing <span class="font-semibold text-gray-900">{{ vendorSalesTaxes.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ vendorSalesTaxes.total }}</span>)
                        </div>

                        <div class="overflow-x-auto">
                            <table class="table table-condensed">
                                <thead>
                                <tr>
                                    <sortable-table-header field="name" class="font-semibold" :filters="filters" route-name="tenant-settings.vendor-sales-taxes.index">Name</sortable-table-header>
                                    <sortable-table-header field="type" class="font-semibold" :filters="filters" route-name="tenant-settings.vendor-sales-taxes.index">Type</sortable-table-header>
                                    <sortable-table-header field="country" class="font-semibold" :filters="filters" route-name="tenant-settings.vendor-sales-taxes.index">Country</sortable-table-header>
                                    <sortable-table-header field="state_province" class="font-semibold" :filters="filters" route-name="tenant-settings.vendor-sales-taxes.index">State/Province</sortable-table-header>
                                    <sortable-table-header field="applied_by_default" class="font-semibold" :filters="filters" route-name="tenant-settings.vendor-sales-taxes.index">Applied to Locations by Default</sortable-table-header>
                                    <sortable-table-header field="gl_code" class="font-semibold" :filters="filters" route-name="tenant-settings.vendor-sales-taxes.index">Taxable Exemptions</sortable-table-header>
<!--                                    To be used in sales taxes v2-->
<!--                                    <th></th>-->
                                </tr>
                                </thead>

                                <tbody>
                                <tr v-for="vendorSalesTax in vendorSalesTaxes.data" :key="vendorSalesTax.id">
                                    <td>
                                        <inertia-link class="link" :href="$route('tenant-settings.vendor-sales-taxes.show', [vendorSalesTax.id])">
                                            {{ vendorSalesTax.name }}
                                        </inertia-link>
                                    </td>
                                    <td>{{ vendorSalesTax.vendorSalesTaxType.name }}</td>
                                    <td>{{ vendorSalesTax.country }}</td>
                                    <td>{{ vendorSalesTax.state_province }}</td>
                                    <td>{{ vendorSalesTax.applied_by_default ? 'Yes' : 'No' }}</td>
                                    <td>{{ (vendorSalesTax.vendorSalesTaxRules.length || vendorSalesTax.rule_mode === 'Exempt Unless') ? vendorSalesTax.rule_mode : 'None' }}</td>
<!--                                    To be used in sales taxes v2-->
<!--                                    <td class="text-right">-->
<!--                                            <span class="inline-block">-->
<!--                                                <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.edit', [vendorSalesTax.id])" class="link text-lg" title="Edit Vendor Invoice Sales Tax">-->
<!--                                                    <icon name="edit" class="fill-current w-5 h-5" />-->
<!--                                                </inertia-link>-->
<!--                                            </span>-->
<!--                                    </td>-->
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <pagination :links="vendorSalesTaxes.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="receipt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Vendor Invoice Sales Taxes Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
import {throttle, pickBy} from 'lodash-es';
import { router } from '@inertiajs/vue3';
import { ref, reactive, inject, watch, onMounted } from 'vue';

// Components
import { Head } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import Pagination from '@/Shared/Pagination.vue';
import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
import SubNav from '../SubNav.vue';

// Inject
const route = inject('route')

// Props
const props = defineProps({
    vendorSalesTaxes: {
        type: Object,
        required: true
    },

    filters: Object
})

// State
const form = reactive({search: props.filters.search})
const mounted = ref(false)

// Mount
onMounted(() => {
    mounted.value = true
})

// Watch
watch(form, throttle(function() {
    let query = pickBy(form);
    query = Object.keys(query).length ? query : {remember: 'forget'};

    router.get(route('tenant-settings.vendor-sales-taxes.index'), query, {preserveState: true});
}, 150), {deep: true})
</script>