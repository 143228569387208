<style>

</style>

<template>
    <div>
        <label v-if="label" class="form-label" :for="id">
            <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
        </label>

        <p v-if="helpText" class="mt-2 text-sm text-gray-600">{{ helpText }}</p>

        <input :id="id" ref="input" v-bind="$attrs" class="form-input" :class="{ error: errors }" type="datetime-local" :value="isoValue" :disabled="disabled" @input="$emit('update:modelValue', $event.target.value)">
        
        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>
    import {format} from 'date-fns';
    import { v4 as uuid } from 'uuid';

    const HTML5_DATETIME_LOCAL = "yyyy-MM-dd'T'HH:mm"

    export default {
        props: {
            id: {
                type: String,
                default() {
                    return `date-time-local-input-${uuid()}`;
                },
            },

            modelValue: {
                required: true
            },

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },

        data () {
            return {

            };
        },

        methods: {
            focus() {
                this.$refs.input.focus()
            },

            select() {
                this.$refs.input.select()
            }
        },

        computed: {
            isoValue() {
                return this.modelValue ? format(new Date(this.modelValue), HTML5_DATETIME_LOCAL) : null
            }
        }
    }
</script>