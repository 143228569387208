<template>
    <Head title="Create Incident" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('incidents.index')" class="breadcrumb-link">Incidents</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-incident-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Location/Service Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Location and service for the incident.</p>
                </div>
            </legend>

            <div class="grid grid-cols-2 gap-y-6 gap-x-4">
                <div class="col-span-2 md:col-span-1">
                    <Combobox as="div" v-model="selectedLocation">
                        <ComboboxLabel class="form-label" for="selected_location">
                            <span class="text-red-500">*</span> Location
                        </ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="selected_location" name="selected_location" class="form-input" @change="searchLocations($event)" :display-value="comboDisplayValue" />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <div v-if="state === 'searching-locations'" class="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white px-2 py-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <span class="mr-2">Searching...</span>
                                <icon name="search" class="w-3 h-3 fill-current inline search-icon search-animation" />
                            </div>

                            <ComboboxOptions v-if="filteredLocationOptions.length > 0 && state === 'passive'" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="location in filteredLocationOptions" :key="location.id" :value="location" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ location.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <select-input v-if="selectedLocation" v-model="form.service_id" class="col-span-2 md:col-span-1" label="Service" placeholder="Service" :errors="errors.service_id">
                    <option disabled value="">Please Select</option>
                    <option v-for="service in locationServices" :key="service.id" :value="service.id">{{ service.id }}</option>
                </select-input>

                <select-input v-else class="col-span-2 md:col-span-1" label="Service" placeholder="Service" :errors="errors.service_id">
                    <option disabled value="">Please Select A Location First</option>
                </select-input>
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Incident settings and info.</p>
                </div>
            </legend>

            <div class="grid grid-cols-2 gap-y-6 gap-x-4">
                <select-input name="source" v-model="form.source" class="col-span-2 md:col-span-1" label="Source" placeholder="Source" :errors="errors.source" mark-as-required>
                    <option disabled value="">Please Select</option>
                    <option v-for="source in sources" :key="source" :value="source">{{ source }}</option>
                </select-input>

                <select-input name="type_id" v-model="form.type_id" class="col-span-2 md:col-span-1" label="Type" placeholder="Type" :errors="errors.type_id" mark-as-required>
                    <option disabled value="">Please Select</option>
                    <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
                </select-input>

                <select-input v-if="form.type_id" name="reason" v-model="form.reason_id" class="col-span-2 md:col-span-1" label="Reason" placeholder="Reason" :errors="errors.reason_id" mark-as-required>
                    <option disabled value="">Please Select</option>
                    <option v-for="reason in typeReasons" :key="reason.id" :value="reason.id">{{ reason.name }}</option>
                </select-input>

                <select-input v-else class="col-span-2 md:col-span-1" label="Reason" placeholder="Reason" :errors="errors.reason_id" mark-as-required>
                    <option disabled value="">Select a Reason</option>
                </select-input>

                <select-input name="priority" v-model="form.priority" class="col-span-2 md:col-span-1" label="Priority" placeholder="Priority" :errors="errors.priority" mark-as-required>
                    <option disabled value="">Please Select</option>
                    <option v-for="priority in priorities" :key="priority" :value="priority">{{ priority }}</option>
                </select-input>

                <select-input name="assigned_to_id" v-model="form.assigned_to_id" class="col-span-2 md:col-span-1" label="Assigned To" placeholder="Assigned To" :errors="errors.assigned_to_id">
                    <option :value="null">Unassigned</option>
                    <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
                </select-input>

                <date-input name="due_on" v-model="form.due_on" class="col-span-2 md:col-span-1" label="Due On" placeholder="Due On" :errors="errors.due_on" mark-as-required />

                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-2 md:col-span-1"
                    :errors="errors.external_reference_id"
                    help-text="Allows for external references IDs"
                />

                <textarea-input
                    name="first_comment"
                    v-model="form.first_comment"
                    class="col-span-2"
                    label="First Comment"
                    :autosize="true"
                    help-text="First comment for this incident can go here.."
                    :errors="errors.first_comment"
                />
            </div>
        </fieldset>

        <div>
            <loading-button type="submit" :loading="state === 'saving'" class="btn btn-orange">Submit</loading-button>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import axios from 'axios';
    import { debounce } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            TextInput,
            DateInput,
            TextareaInput,
            SelectInput,
            LoadingButton,
            Head,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },

            // Default service_id if passed via URL query string.
            serviceId: {
                type: String,
            },

            // Default location if a service id was passed via URL query string.
            location: {
                type: Object,
                default: () => ({}),
            },
            
            employees: {
                type: Array,
                required: true
            },

            sources: {
                type: Array,
                required: true
            },

            types: {
                type: Array,
                required: true
            },

            priorities: {
                type: Array,
                required: true
            }
        },

        data() {
           return {
               form: {
                   location_id: this.location ? this.location.id : null,
                   service_id: this.serviceId,
                   source: null,
                   type_id: null,
                   type: null,
                   reason_id: null,
                   priority: null,
                   assigned_to_id: null,
                   due_on: null,
                   first_comment: null,
               },
               state: 'passive',
               locations: [],
               selectedLocation: this.location ? {...this.location} : null,
               mounted: false,
           }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            searchLocations: debounce(function(event) {
                if (event.target.value && this.state === 'passive') {
                    this.state = 'searching-locations';

                    axios.get(this.$route('json.locations.index', {search: event.target.value.toLowerCase(), sort_by: 'name', sort_direction: 'asc'})).then(response => {
                        this.locations = response.data.data.map(location => ({id: location.id, name: location.name, services:location.services}));
                    })
                    .finally(() => this.state = 'passive');
                }
            }, 800),

            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia.post(this.$route('incidents.store'), {...this.form, location_id: this.selectedLocation?.id ?? null}, {
                        onFinish: () => this.state = 'passive'
                    });
                }
            },

            comboDisplayValue(option) {
                return option?.name ?? 'No locations selected'; 
            },
        },

        computed: {
            locationServices() {
                return this.selectedLocation?.services ?? [];
            },

            filteredLocationOptions() {
                let locations = [];

                if (this.selectedLocation) {
                    locations = [...this.locations, this.selectedLocation];
                } else {
                    locations = this.locations;
                }

                return [...new Set(locations)];
            },

            typeReasons() {
                if (this.form.type_id) {
                    return this.types.find(type => type.id === this.form.type_id).reasons;
                } else {
                    return [];
                }
            },
        },
    }
</script>

<style scoped>
    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }
    @keyframes search-animation {
        0%   { transform: translateY(0); }
        30%  { transform: translateY(-10px); }
        50%  { transform: translateY(0) translateX(5px); }
        100% { transform: translateY(0) translateX(0); }
    }
</style>
