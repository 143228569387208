<style></style>

<template>
    <transition enter-active-class="duration-500 sm:duration-700" leave-active-class="duration-500 sm:duration-700">
        <div v-show="isOpen" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <transition
                enter-active-class="ease-in-out duration-500"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in-out duration-500"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                    <div v-show="isOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            </transition>

            <div @click="conditionallyToggleSidebar" class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-md pl-10 sm:pl-16">
                        <transition enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from-class="translate-x-full"
                            enter-to-class="translate-x-0"
                            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from-class="translate-x-0"
                            leave-to-class="translate-x-full">
                                <div ref="slideOver" v-show="isOpen" class="pointer-events-auto w-screen max-w-2xl">
                                    <div class="flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white shadow-xl">
                                        <slot name="header" :close="close"></slot>
                                        
                                        <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                            <!-- Replace with your content -->
                                            <div class="absolute inset-0 px-4 sm:px-6">
                                                <div class="px-6 lg:px-8">
                                                    <slot name="body"></slot>
                                                </div>
                                            </div>
                                            <!-- /End replace -->
                                        </div>
                                    </div>
                                </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
    import { ref } from 'vue';

    defineExpose({ show, close });

    /**
     * Emits
     */
    const emit = defineEmits(['closed', 'opened']);
    
    /**
     * Data
     */
    const isOpen = ref(false);
    const slideOver = ref(null);

    /**
     * Methods
     */
    function show() {
        isOpen.value = true;
        
        emit('opened');
    }

    function close() {
        isOpen.value = false;

        emit('closed');
    }

    function conditionallyToggleSidebar(e) {
        if (!slideOver.value.contains(e.target)) {
            close();
        }
    }
</script>