<template>
    <Head :title="`Service Termination Date Change - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Service Termination Date Change</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
    </div>

    <div class="container mx-auto mt-8 mb-12">
        <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-fit lg:px-8 rounded-md bg-blue-50 p-4 my-3">
            <div class="flex">
                <div class="flex-shrink-0">
                    <!-- Heroicon name: solid/information-circle -->
                    <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">
                        Service termination date changes cannot be edited. If you need to change the termination date on this service, please delete this change and enter a new service termination change from the service edit screen.
                    </p>
                </div>
            </div>
        </div>

        <div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <div>
                    <label class="form-label">Change Requester</label>
                    <span class="m-1 text-sm">{{ decoratedChangeRequester }}</span>
                </div>

                <div>
                    <label class="form-label">Termination Date</label>
                    <span class="m-1 text-sm">{{ $filters.format_date(serviceActivity.serviceSnapshot.termination_date) }}</span>
                </div>

                <div>
                    <label class="form-label">Change Reason</label>
                    <span class="m-1 text-sm">{{ serviceActivity.reason }}</span>
                </div>
            </div>

            <div>
                <label class="form-label">Internal Change Notes</label>
                <span class="m-1 text-sm">{{ serviceActivity.internal_change_notes }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            serviceCreationActivity: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                saving: false,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            deleteServiceChange() {
                if (window.confirm('Are you sure you want to delete this service termination date change?')) {
                    this.$inertia.delete(this.$route('services.service-termination-date-changes.destroy', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id}));
                }
            },
        },

        computed: {
            decoratedChangeRequester() {
                switch (this.serviceActivity.change_requester) {
                    case 'broker':
                        return this.$page.props?.authUser?.tenant_name ?? 'Our Company';
                    case 'client':
                        return this.serviceActivity.serviceSnapshot.location.clientCompany.name;
                    case 'vendor':
                        return this.serviceActivity.serviceSnapshot.vendor.name ?? '';
                    default:
                        return this.serviceActivity.change_requester
                }
            }
        },
    }
</script>