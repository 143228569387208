<template>
    <Head :title="`Client Company - ${clientCompany.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-companies.index')" class="breadcrumb-link">Client Companies</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ clientCompany.name }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div
        class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="building" class="h-14 w-14" />
            </div>
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ clientCompany.name }}
                    <span v-if="clientCompany.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ clientCompany.external_reference_id ?? 'EXT-123ABC' }}</span>
                </h1>

                <p v-if="clientCompany.became_client_date" class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Client Company</span> since <time :datetime="clientCompany.became_client_date">{{ $filters.format_date(clientCompany.became_client_date) }}</time>
                </p>

                <p v-if="clientCompany.archived_at" class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900">Archived</span> at {{ $filters.format_date_time(clientCompany.archived_at) }}
                    <span v-if="clientCompany.archivedBy"> by {{ clientCompany.archivedBy.name }}</span>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <loading-button v-if="clientCompany.archived_at" type="button" :disabled="state !== 'passive'" :loading="state === 'unarchiving'" @click="unarchiveClientCompany" class="btn btn-gray">
                Unarchive
            </loading-button>
            <loading-button v-else type="button" :disabled="state !== 'passive'" :loading="state === 'archiving'" @click="archiveClientCompany" class="btn btn-gray">
                Archive
            </loading-button>

            <inertia-link :href="$route('client-companies.edit', [clientCompany.id])" class="btn btn-gray">Edit</inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="record-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="record-information-title" class="text-lg leading-6 font-medium text-gray-900">Company Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Details and documents</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm" :class="[clientCompany.status === 'Active' ? 'text-green-600' : 'text-gray-500']">{{ clientCompany.status }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Industry</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientCompany.industry ?? 'Not Set' }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Primary Contact Phone</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(clientCompany.primary_contact_phone) ?? 'Not Set' }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Primary URL</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientCompany.primary_url ?? 'Not Set' }}</dd>
                            </div>
                            <div v-if="clientCompany.default_weight_unit" class="sm:col-span-1">
                              <dt class="text-sm font-medium text-gray-500">Default Weight Unit</dt>
                              <dd class="mt-1 text-sm text-gray-900">{{ clientCompany.default_weight_unit }}</dd>
                            </div>
                            <div v-if="clientCompany.description" class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">About</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ clientCompany.description }}</dd>
                            </div>
                            <div v-if="clientCompany.contract_document_path || clientCompany.letter_of_authorization_document_path" class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">Attachments</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                                        <li v-if="clientCompany.contract_document_path" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div class="w-0 flex-1 flex items-center">
                                                <!-- Heroicon name: solid/paper-clip -->
                                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                                </svg>
                                                <span class="ml-2 flex-1 w-0 truncate"> Contract Document
                                                </span>
                                            </div>
                                            <div class="ml-4 flex-shrink-0">
                                                <a :href="$route('documents.preview', { path: clientCompany.contract_document_path })" class="font-medium link" target="_blank">Download</a>
                                            </div>
                                        </li>
                                        <li v-if="clientCompany.letter_of_authorization_document_path" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div class="w-0 flex-1 flex items-center">
                                                <!-- Heroicon name: solid/paper-clip -->
                                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                                                </svg>
                                                <span class="ml-2 flex-1 w-0 truncate"> Letter of Authorization
                                                </span>
                                            </div>
                                            <div class="ml-4 flex-shrink-0">
                                                <a :href="$route('documents.preview', { path: clientCompany.letter_of_authorization_document_path })" class="font-medium link" target="_blank">Download</a>
                                            </div>
                                        </li>
                                    </ul>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <section aria-labelledby="summary-title" class="lg:col-start-3 lg:col-span-1">
            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="summary-title" class="text-lg font-medium text-gray-900 mb-2">Summary</h2>

                <dl class="grid grid-cols-1 gap-x-0 gap-y-10 sm:grid-cols-2">
                    <div class="flex flex-col gap-y-2">
                        <dt class="text-sm leading-6 text-gray-600">Total Locations</dt>
                        <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">
                            <inertia-link :href="$route('locations.index', {search: clientCompany.name})" class="link">
                                {{ summaryData.total_locations ?? 0}}
                            </inertia-link>
                        </dd>
                    </div>
                    <div class="flex flex-col gap-y-2 sm:border-l sm:border-gray-900/20 sm:text-right">
                        <dt class="text-sm leading-6 text-gray-600">Active Locations</dt>
                        <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">
                            {{ summaryData.active_locations ?? 0 }}
                        </dd>
                    </div>
                    <div class="flex flex-col gap-y-2">
                        <dt class="text-sm leading-6 text-gray-600">Active Services</dt>
                        <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">
                            {{ summaryData.active_services ?? 0 }}
                        </dd>
                    </div>
                    <div class="flex flex-col gap-y-2 sm:border-l sm:border-gray-900/20 sm:text-right">
                        <dt class="text-sm leading-6 text-gray-600">Open Incidents</dt>
                        <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">
                            {{ summaryData.open_incidents ?? 0 }}
                        </dd>
                    </div>
                </dl>
            </div>
        </section>

        <div class="lg:col-start-3 lg:col-span-1 space-y-6 ">

            <!-- Sticky Notes !-->
            <sticky-note
                :content="clientCompany.sticky_notes"
                :hideLabel="true"
                :savingRoute="$route('client-companies.sticky-notes-changes.store', [clientCompany.id])"
                @saved="clientCompany.sticky_notes = $event"
            />
        </div>

        <!-- Client Contacts !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="clients-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap items-center justify-between px-4 py-5 sm:px-6">
                        <div>
                            <h2 id="clients-title" class="text-lg leading-6 font-medium text-gray-900">Contacts</h2>
                        </div>

                        <div v-if="clients.length > 0">
                            <inertia-link :href="$route('clients.create', {client_company_id: clientCompany.id})" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="clients.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
	                            <transition
		                            v-if="$page.props.permissions.accessClientPortal"
		                            enter-active-class="transition-height ease-in-out duration-500"
		                            enter-from-class="h-0"
		                            enter-to-class="h-full"
		                            leave-active-class="transition-height ease-in-out duration-500"
		                            leave-from class="h-full"
		                            leave-to-class="h-0">
		                            <div v-if="clients.length && selectedClientIds.length" class="bg-white flex h-10 items-center sm:left-14">
			                            <span v-if="selectedClientIds.length === 1" class="mr-8 ml-10">{{  `${selectedClientIds.length} item selected` }}</span>
			                            <span v-else class="mr-8 ml-10">{{  `${selectedClientIds.length} items selected` }}</span>

			                            <div class="flex space-x-3">
				                            <button type="button" @click="inviteClients" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!selectedClientIds">Invite to Client Portal</button>

				                            <!--Used a form action here because we don't want inertia to handle the redirect for the zip download-->

			                            </div>
		                            </div>
	                            </transition>
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table table-condensed">
                                        <thead>
                                            <tr>
	                                            <th v-if="$page.props.permissions.accessClientPortal" scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
		                                            <input type="checkbox" :disabled="!clients.length" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="clients.length && (indeterminate || selectedClientIds.length === clients.length)"
		                                                   :indeterminate="indeterminate"
		                                                   @change="selectedClientIds = $event.target.checked ? clients.map((client) => client.id) : []"/>
	                                            </th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Title</th>
	                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Email</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Phone</th>
	                                            <th v-if="$page.props.permissions.accessClientPortal" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Client Portal</th>
	                                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="contact in clients" :key="contact.id">
	                                            <td v-if="$page.props.permissions.accessClientPortal" class="relative w-12 px-6 sm:w-16 sm:px-8">
		                                            <div v-if="selectedClientIds.includes(contact.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
		                                            <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :disabled="contact.confirmed_at" :value="contact.id" v-model="selectedClientIds" />
	                                            </td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ contact.first_name }} {{ contact.last_name }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ contact.title }}</td>
                                                <td class="">{{ contact.email }}</td>
                                                <td class="">{{ $filters.format_phone(contact.primary_phone_number) }}</td>
	                                            <td v-if="$page.props.permissions.accessClientPortal">
		                                            <div v-if="contact.confirmed_at">Confirmed</div>
		                                            <div v-else-if="contact.invited_at && !contact.confirmed_at">Invited</div>
	                                            </td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">

                                                    <inertia-link :href="$route('clients.show', [contact.id])" class="link mr-2">
                                                      View<span class="sr-only">, {{ contact.id }}</span>
                                                    </inertia-link>
                                                    <inertia-link :href="$route('clients.edit', [contact.id])" class="link mr-2">
                                                        Edit<span class="sr-only">, {{ contact.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="address-book" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No contacts</h3>
                            <div class="mt-6">
                                <inertia-link :href="$route('clients.create', {client_company_id: clientCompany.id})" class="btn btn-blue">
                                    <icon name="plus" class="-ml-1 mr-2 h-5 w-5 fill-current" />
                                    Add a Contact
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="clients.length">
                        <inertia-link :href="$route('clients.index', {search: clientCompany.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Locations !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="locations-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap items-center justify-between px-4 py-5 sm:px-6">
                        <div>
                            <h2 id="locations-title" class="text-lg leading-6 font-medium text-gray-900">Locations</h2>
                        </div>

                        <div v-if="locations.length > 0">
                            <inertia-link :href="$route('locations.create')" class="btn btn-orange text-sm">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="locations.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table table-condensed">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Address</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="location in locations" :key="location.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                    <inertia-link class="link" :href="$route('locations.show', [location.id])">{{ location.name }}</inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ location.address.full_address }}</td>

                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('locations.show', [location.id])" class="link mr-2">
                                                        View<span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>

                                                    <inertia-link :href="$route('locations.edit', [location.id])" class="link mr-2">
                                                        Edit<span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="map-marker-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No locations</h3>
                            <div class="mt-6">
                                <inertia-link :href="$route('locations.create', {client_company_id: clientCompany.id})" class="btn btn-blue">
                                    <icon name="plus" class="-ml-1 mr-2 h-5 w-5 fill-current" />
                                    New Location
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="locations.length">
                        <inertia-link :href="$route('locations.index', {search: clientCompany.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Incidents !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="incidents-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap items-center justify-between px-4 py-5 sm:px-6">
                        <div class="">
                            <h2 id="incidents-title" class="text-lg leading-6 font-medium text-gray-900">Active Incidents</h2>
                        </div>

                        <div v-if="incidents.length > 0">
                            <inertia-link :href="$route('incidents.create')" class="btn btn-orange text-sm">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="incidents.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">#</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Priority</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Reason</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Reported</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Source</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Assigned To</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="incident in incidents" :key="incident.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{{ incident.id }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ incident.status }}</td>
                                                <td>{{ incident.priority }}</td>
                                                <td>{{ incident.reason.name }}</td>
                                                <td>{{ $filters.format_date_time(incident.reported_at) }}</td>
                                                <td>{{ incident.source }}</td>
                                                <td>
                                                    <template v-if="incident.assignedTo" class="mt-1 text-sm text-gray-900">
                                                        <img v-if="incident.assignedTo?.avatar_thumbnail_url" :src="incident.assignedTo?.avatar_thumbnail_url" :alt="incident.assignedTo?.name" class="inline border border-gray-400 rounded-full mr-4 h-10 w-10" />

                                                        <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                                            <span class="text-lg font-medium leading-none text-white uppercase">{{ incident.assignedTo?.first_name[0] }}{{ incident.assignedTo?.last_name[0] }}</span>
                                                        </span>
                                                        <span>{{ incident.assignedTo?.name }}</span>
                                                    </template>
                                                    <template v-else>Unassigned</template>
                                                </td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('incidents.edit', [incident.id])" class="link">
                                                        Edit<span class="sr-only">, {{ incident.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="tasks" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No active incidents</h3>
                            <div class="mt-6">
                                <inertia-link :href="$route('incidents.create')" class="btn btn-blue">
                                    <icon name="plus" class="-ml-1 mr-2 h-5 w-5 fill-current" />
                                    Create an Incident
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="incidents.length">
                        <inertia-link :href="$route('incidents.index', {search: clientCompany.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Invoices !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="client-invoices-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="client-invoices-title" class="text-lg leading-6 font-medium text-gray-900">Recent Client Invoices</h2>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="clientInvoices.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">Account #</th>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">Invoice #</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Invoice Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Due Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Billed</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Adjustments</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Paid</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Due</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="invoice in clientInvoices" :key="invoice.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ invoice.account_number }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ invoice.invoice_number }}</td>
                                                <td>{{ $filters.format_date(invoice.invoice_date) }}</td>
                                                <td>{{ $filters.format_date(invoice.due_at) }}</td>
                                                <td>{{ $filters.format_money(invoice.total_amount_billed) }}</td>
                                                <td>
                                                    {{ $filters.format_money(add_money(invoice.total_amount_credit_memo,invoice.total_amount_write_off)) }}
                                                </td>
                                                <td>{{ $filters.format_money(invoice.total_amount_paid) }}</td>
                                                <td>{{ $filters.format_money(invoice.total_amount_due) }}</td>
                                                <td>
                                                    <span :class="[clientInvoiceStatusColorClass(invoice.status), 'px-2 py-1']">{{ invoice.status }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="file-invoice" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No recent client invoices</h3>
                        </div>
                    </div>

                    <div v-if="clientInvoices.length">
                        <inertia-link :href="$route('client-invoices.index', {search: clientCompany.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Credit Memos !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="client-memos-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="client-memos-title" class="text-lg leading-6 font-medium text-gray-900">Recent Client Credit Memos</h2>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="clientCreditMemos.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">Document #</th>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">Document Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Total Amount</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Applied Amount</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="creditMemo in clientCreditMemos" :key="creditMemo.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ creditMemo.document_number }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ $filters.format_date(creditMemo.document_date) }}</td>
                                                <td class="">{{ $filters.format_money(creditMemo.total_amount) }}</td>
                                                <td class="">{{ $filters.format_money(creditMemo.applied_amount) }}</td>
                                                <td class="">
                                                    <span :class="[clientCreditMemoStatusColorClass(creditMemo.status), 'px-2 py-1']">{{ creditMemo.status }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-invoice-dollar" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No recent credit memos</h3>
                        </div>
                    </div>

                    <div v-if="clientCreditMemos.length">
                        <inertia-link :href="$route('client-credit-memos.index', {search: clientCompany.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Client Contracts !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="client-contracts-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex justify-between items-center px-4 py-5 sm:px-6">
                        <div>
                            <h2 id="client-contracts-title" class="text-lg leading-6 font-medium text-gray-900">Recent Client Contracts</h2>
                        </div>

                        <div v-if="clientContracts.length > 0">
                            <inertia-link :href="$route('client-contracts.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>


                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="clientContracts.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">ID</th>
                                                <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">Integration Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Total # Services</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Active # Services</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Terminating # Services</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="contract in clientContracts" :key="contract.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ contract.id }}</td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ $filters.format_date(contract.integration_date) }}</td>
                                                <td :class="contract.number_of_services > 0 ? 'text-gray-900' : 'text-gray-500'">{{ contract.number_of_services }}</td>
                                                <td :class="contract.number_of_active_services > 0 ? 'text-gray-900' : 'text-gray-500'">{{ contract.number_of_active_services }}</td>
                                                <td :class="contract.number_of_terminating_services > 0 ? 'text-gray-900' : 'text-gray-500'">{{ contract.number_of_terminating_services }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('client-contracts.edit', [contract.id])" class="link">
                                                        Edit<span class="sr-only">, {{ contract.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-signature" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No recent client contracts</h3>
                            <div class="mt-6">
                                <inertia-link :href="$route('client-contracts.create')" class="btn btn-blue">
                                    <icon name="plus" class="-ml-1 mr-2 h-5 w-5 fill-current" />
                                    Add a Contract
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="clientContracts.length">
                        <inertia-link :href="$route('client-contracts.index', {search: clientCompany.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { add_money } from "@/Shared/Utils/Money.js";
    import { Head, router } from '@inertiajs/vue3';
    import {ref, onMounted, reactive, computed} from 'vue';
    import StickyNote from "../../Shared/StickyNote.vue";

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({
                contractDocument: {},
                letterOfAuthorization: {}
            })
        },

        clientCompany: {
            type: Object,
            required: true
        },

        clients: {
            type: Array,
            required: true
        },

        locations: {
            type: Array,
            required: true
        },

        incidents: {
            type: Array,
            required: true
        },

        clientInvoices: {
            type: Array,
            required: true
        },

        clientCreditMemos: {
            type: Array,
            required: true
        },

        clientContracts: {
            type: Array,
            required: true
        },

        summaryData: {
            type: Object,
            required: true
        }
    });

    /**
     * State
     */
    const mounted = ref(false);
    const state = ref('passive');
    const clientCompany = reactive({...props.clientCompany});
	const selectedClientIds = ref([]);

    onMounted(() => {
        mounted.value = true;
    });

    function clientInvoiceStatusColorClass(status) {
        switch(status) {
            case 'Delinquent':
                return 'bg-red-100 text-red-800';
            case 'Paid':
                return 'bg-green-100 text-green-800';
            case 'Sent':
                return 'bg-blue-100 text-blue-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    }

    function clientCreditMemoStatusColorClass(status) {
        switch(status) {
            case 'Fully Applied':
                return 'bg-green-100 text-green-800';
            case 'Sent':
                return 'bg-blue-100 text-blue-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    }


    function archiveClientCompany() {
        if (state.value !== 'passive' || props.clientCompany.archived_at) {
            return;
        }

        state.value = 'archiving';

        router.patch(route('client-companies.archive', props.clientCompany.id), {}, {
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }

    function unarchiveClientCompany() {
        if (state.value !== 'passive' || !props.clientCompany.archived_at) {
            return;
        }

        state.value = 'unarchiving';

        router.patch(route('client-companies.unarchive', props.clientCompany.id), {}, {
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }

    function inviteClients() {
	    if (window.confirm(`Are you sure you want to invite these ${selectedClientIds.value.length} clients to the client portal?`)) {
		    router.post(route('client-contacts.bulk-invite'), {client_ids: selectedClientIds.value, client_company_id: props.clientCompany.id}, {
			    preserveScroll: true,
			    onFinish: () => {
				    selectedClientIds.value = [];
			    }
		    });
	    }
    }

	// computed
    const indeterminate = computed(() => selectedClientIds.value.length > 0 && selectedClientIds.value.length < props.clients.length);
</script>
