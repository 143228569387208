<template>
    <div>
        <div class="my-8">The <b>{{ who }}</b> is requesting a <b>level of service change</b> for this {{ service.type }} service. Please fill in the details.</div>

        <div class="grid gap-4 grid-cols-2 my-4">
            <date-input class="col-span-2 md:col-span-1" :min="earliestServiceActivity.actual_date" v-model="actual_date" label="When should this change happen?" :errors="errors['actual_date']" mark-as-required />
            
            <div class="col-span-2 md:col-span-1">
                <p v-if="actual_date && actual_date < today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).</p>
                <p v-if="actual_date && actual_date > today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.</p>
            </div>

            <select-input class="col-span-2 md:col-span-1" label="Why are you making a level of service change?" v-model="change_reason" mark-as-required>
                <option>Other</option>
                <option>Savings</option>
            </select-input>

            <div></div>

            <select-input class="col-span-2 md:col-span-1" v-model="form.vendor_account_id" label="Vendor Account" :errors="errors['vendor_account_id']">
                <option v-if="!vendorAccounts.length" :value="null">No accounts available for this Vendor</option>
                <option v-for="vendorAccount in vendorAccounts" :value="vendorAccount.id">{{ vendorAccount.account_number + " - " + vendorAccount.status }}</option>
            </select-input>

            <select-input class="col-span-2 md:col-span-1" v-model="form.vendor_contract_selection" label="Vendor Contract" :errors="errors['vendor_contract_selection']">
                <option value='missing-contract'>Missing contract</option>
                <option :value=null>No contract</option>
                <option v-for="vendorContract in vendorContracts" :value="vendorContract.id" :key="vendorContract.id">{{ vendorContract.display_id + " - " + vendorContract.status + " - Effective " + vendorContract.effective_date ?? " - " }}</option>
            </select-input>

            <select-input class="col-span-2 md:col-span-1" v-model="form.client_contract_id" label="Client Contract" :errors="errors['client_contract_id']">
                <option v-if="clientContracts.length === 0" :value="null">No contracts available for this Client</option>
                <option v-for="clientContract in clientContracts" :key="clientContract.id" :value="clientContract.id">{{ clientContract.id  + " - Integration " + clientContract.integration_date ?? " - " }}</option>
            </select-input>

            <select-input class="col-span-2 md:col-span-1" v-model="form.service_account_type" label="Service Account Type" :errors="errors['service_account_type']" markAsRequired>
                <option>Permanent</option>
                <option>Temporary</option>
            </select-input>

            <select-input class="col-span-2 md:col-span-1" v-model="form.service_schedule_type" label="Service Schedule Type" :errors="errors['service_schedule_type']" markAsRequired>
                <option>Scheduled</option>
                <option>On Call</option>
                <option v-if="form.service_account_type==='Temporary'">One Time</option>
            </select-input>

            <div v-if="form.service_schedule_type === 'Scheduled'" class="col-span-2 md:col-span-1">
                <span class="form-label">
                        <span class="text-red-500">*</span>
                        Pickup Schedule 
                    </span>

                <div class="m-2">
                    {{ rrule_description }} <a href="#" class="" @click.prevent="showUpdatePickupRRuleModal"><icon name="edit" class="inline fill-current w-5 h-6"></icon></a>
                </div>
                
                <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500 text-sm">
                    The pickup schedule is invalid
                </div>
            </div>
            <div v-else></div>

            <money-input class="col-span-2 md:col-span-1" v-if="['Device', 'Doorstep', 'Commercial', 'Rental'].includes(service.type)" :defaultCurrency="$page.props.currency" v-model="form.vendor_base_charge" label="Vendor Base Charge ($)" :errors="errors['vendor_base_charge']" />
            
            <money-input class="col-span-2 md:col-span-1" v-if="['Rolloff'].includes(service.type)" :defaultCurrency="$page.props.currency" v-model="form.vendor_disposal_amount_per_ton" label="Vendor Disposal Amount Per Ton" :errors="errors['vendor_disposal_amount_per_ton']" />
            <money-input class="col-span-2 md:col-span-1" v-if="['Rolloff'].includes(service.type)" :defaultCurrency="$page.props.currency" v-model="form.vendor_per_haul_amount" label="Vendor Per Haul Amount" :errors="errors['vendor_per_haul_amount']" />
            <text-input class="col-span-2 md:col-span-1" v-if="['Rolloff'].includes(service.type)" :defaultCurrency="$page.props.currency" type="number" min="0" v-model="form.tons_included_per_haul" label="Tons Included Per Haul" :errors="errors['tons_included_per_haul']" markAsRequired />

            <money-input class="col-span-2 md:col-span-1" v-if="!service.is_pass_through && ['Device', 'Doorstep', 'Commercial', 'Rental'].includes(service.type)" :defaultCurrency="$page.props.currency" v-model="form.client_base_charge" label="Client Base Charge ($)" :errors="errors['client_base_charge']" />
            <money-input class="col-span-2 md:col-span-1" v-if="!service.is_pass_through && ['Rolloff'].includes(service.type)" v-model="form.client_disposal_amount_per_ton" :defaultCurrency="$page.props.currency" label="Client Disposal Amount Per Ton" :errors="errors['client_disposal_amount_per_ton']" />
            <money-input class="col-span-2 md:col-span-1" v-if="!service.is_pass_through && ['Rolloff'].includes(service.type)" v-model="form.client_per_haul_amount" :defaultCurrency="$page.props.currency" label="Client Per Haul Amount" :errors="errors['client_per_haul_amount']" />
        </div>

	    <fieldset v-if="service.type === 'Doorstep'" class="mt-4">
		    <legend class="block font-semibold text-xl text-d-blue-500">Bin</legend>

		    <div class="grid gap-x-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
			    <text-input v-model="form.label" maxlength="50" class="col-span-1" :errors="errors['label']" label="Equipment Label"/>
		    </div>
		    <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
			    <text-input class="col-span-2 md:col-span-1" v-model="form.bin.quantity" type="number" label="Quantity" />

			    <text-input class="col-span-2 md:col-span-1" v-model="form.bin.size" type="number" label="Bin Size" />

			    <select-input class="col-span-2 md:col-span-1" v-model="form.bin.size_metric" label="Size Metric">
				    <option value="yards">yards</option>
				    <option value="gallons">gallons</option>
				    <option value="foot">foot</option>
			    </select-input>

			    <select-input class="col-span-2 md:col-span-1" v-model="form.bin.material_type" label="Material Type">
				    <option v-for="material_type in materialTypes" :value="material_type.name" :key="material_type.name">{{ material_type.name }}</option>
			    </select-input>
		    </div>
	    </fieldset>

	    <div v-if="service.type !== 'Device' && service.type !== 'Doorstep'" class="mt-6">
		    <span class="font-semibold text-xl text-d-blue-500">Bin</span>
		    <div class="grid gap-x-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-4">
			    <text-input v-model="form.label" maxlength="50" class="col-span-1" :errors="errors['label']" label="Equipment Label"/>
		    </div>
		    <bin :property-type="service.location.property_type" :bin-types="binTypes" :material-types="materialTypes" :asset-owners="assetOwners" :fields-are-required="true" :errors="errors" v-model="form.bin"/>
	    </div>

        <textarea-input v-model="internal_change_notes" class="my-6 w-full" label="Internal Change Notes" />

        <template v-if="showPriorPeriodAdjustment">
            <hr class="col-span-2 my-4" />

            <div class="col-span-2 rounded-md bg-yellow-50 py-2 px-1">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-yellow-800">Prior Period Adjustment</h3>
                        <div class="mt-2 text-sm text-yellow-700">
                            <div class="pr-2">
                                You are changing the price from
                                <span class="font-medium">{{ $filters.format_money(service.client_base_charge) }}</span> to
                                <span class="font-medium">{{ $filters.format_money(form.client_base_charge) }}</span> effective
                                <span class="font-medium">{{ $filters.format_date(actual_date) }}</span>.
                                <span v-if="priorPeriodAdjustment.lastBilledThru !== null">
                                        This service has already been billed through the end of
                                        <span class="font-medium">{{ priorPeriodAdjustment.lastBilledThru }}</span>.
                                    </span>
                                <p class="mt-1">If you do not wish to bill a prior period adjustment for this change, please enter in $0.00 below and no charge will be made.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <money-input label="Prorated Amount" :defaultCurrency="$page.props.currency" v-model="priorPeriodAdjustment.proratedAmount"></money-input>
            <textarea-input label="Line Item Description" v-model="priorPeriodAdjustment.description"></textarea-input>
        </template>

        <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">Submit Change</loading-button>
        
        <!-- Pickup RRule Modal -->
        <modal v-if="service.type === 'Commercial' || service.type === 'Doorstep' || service.type === 'Rolloff'" class="max-w-2xl" ref="updatePickupRRuleModal" tabindex="-1" role="dialog">
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Pickup Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6" />
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Average Pickups Per Week"
                        :start-with-schedule="true"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4 flex">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import Bin from '@/Shared/Services/Bin.vue';
    import Modal from '@/Shared/Modal.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import RRuleInput from '@/Shared/RRuleInput.vue';
    import { format, startOfToday } from "date-fns";
    import axios from "axios";

    export default {
        components: {
            Bin,
            Modal,
            SelectInput,
            DateInput,
            TextareaInput,
            Icon,
            LoadingButton,
            TextInput,
            MoneyInput,
            RRuleInput
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            binTypes: {
                type: Array,
                required: true
            },

            materialTypes: {
                type: Array,
                required: true
            },

            assetOwners: {
                type: Array,
                required: true
            },

            service: {
                type: Object,
                required: true
            },

            vendorAccounts: {
                type: Array,
                required: true
            },
            
            vendorContracts: {
                type: Array,
                required: true
            },

            clientContracts: {
                type: Array,
                default: []
            },
            
            who: {
                type: String,
                required: true
            },

            earliestServiceActivity: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                saving: false,
                form: {
                    ... this.service,
                },
                actual_date:            format(startOfToday(), 'yyyy-MM-dd'),
                change_reason:          'Other',
                change_requester:       this.who,
                internal_change_notes:  null,
                today:                  format(startOfToday(), 'yyyy-MM-dd'),
                rrule_description: null,
                priorPeriodAdjustment:  null,
            }
        },

        methods: {
            showUpdatePickupRRuleModal() {                
                this.$refs.updatePickupRRuleModal.show();
            },
            submitChange() {
                this.saving = true;

                this.$inertia.post(this.$route(`services.level-of-service-changes.store`, [this.service.id]), {
                    ...this.form,
                    'actual_date': this.actual_date,
                    'change_reason': this.change_reason,
                    'change_requester': this.who,
                    'internal_change_notes': this.internal_change_notes,
                    'ppa_amount': this.showPriorPeriodAdjustment ? this.priorPeriodAdjustment.proratedAmount : null,
                    'ppa_description': this.showPriorPeriodAdjustment ? this.priorPeriodAdjustment.description : null,
                }, {
                    onFinish: () => this.saving = false
                });
            },

            suggestPriorPeriodAdjustment() {
                if (!this.$page.props.permissions.accessRevenueManagement) {
                    return false;
                }

                if (this.form.is_pass_through) {
                    return false;
                }

                if (!['Monthly', 'Quarterly', 'Yearly'].includes(this.form.client_billing_frequency)) {
                    return false;
                }

                if (this.form.service_schedule_type === 'One Time') {
                    return false;
                }

                if (this.service.type === 'Rolloff') {
                    return false;
                }

                if (this.service.client_base_charge === this.form.client_base_charge) {
                    return false;
                }

                return true;
            },

            updatePriorPeriodAdjustmentDetails() {
                axios.get(`/json/services/${this.service.id}/prior-period-adjustments/create-from-price-change?change_date=${this.actual_date}&adjusted_amount=${this.form.client_base_charge.amount/100}`).then(response => {
                    this.priorPeriodAdjustment = response.data
                });
            }
        },

        computed: {
            showPriorPeriodAdjustment() {
                return this.suggestPriorPeriodAdjustment() && this.priorPeriodAdjustment?.proratedAmount?.amount && this.priorPeriodAdjustment.proratedAmount.amount != 0
            }
        },

        watch: {
            'form.client_base_charge'() {
                if (this.suggestPriorPeriodAdjustment()) {
                    this.updatePriorPeriodAdjustmentDetails();
                }
            },

            'actual_date'() {
                if (this.suggestPriorPeriodAdjustment()) {
                    this.updatePriorPeriodAdjustmentDetails();
                }
            },

            'form.is_pass_through'(newValue, oldValue) {
                if (newValue === true && this.service.type !== 'Rolloff') {
                    this.form.client_billing_frequency = 'Per Vendor Invoice';
                } else {
                    this.form.client_billing_frequency = this.service.client_billing_frequency;
                }
            }
        }
    }
</script>
