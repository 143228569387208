<template>
    <Head :title="`Edit Service - ${service.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', service.id)" class="breadcrumb-link">{{ service.id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="container mx-auto mt-8 mb-12">

        <div v-if="step == 1">
            <nav aria-label="Progress">
                <ol role="list" class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">

                    <li class="relative md:flex-1 md:flex">
                        <!-- Current Step -->
                        <a href="#" class="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                            <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-d-blue-600 rounded-full">
                                <span class="text-d-blue-500">01</span>
                            </span>
                            <span class="ml-4 text-sm font-medium text-d-blue-500">Type of Change</span>
                        </a>

                        <!-- Arrow separator for lg screens and up -->
                        <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                            <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                                <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </li>

                    <li class="relative md:flex-1 md:flex">
                        <!-- Upcoming Step -->
                        <span class="group flex items-center">
                            <span class="px-6 py-4 flex items-center text-sm font-medium">
                                <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                                    <span class="text-gray-500">02</span>
                                </span>
                                <span class="ml-4 text-sm font-medium text-gray-500">Details</span>
                            </span>
                        </span>
                    </li>
                </ol>
            </nav>

            <legend class="font-semibold text-xl pt-8">Who has requested this change?</legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <div :class="{'border-gray-700': who == 'vendor'}" class="col-span-3 md:col-span-1 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-d-blue-500">
                    <div class="flex-shrink-0">
                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                            <span class="text-lg font-medium leading-none text-white uppercase"></span>
                        </span>
                    </div>

                    <div class="flex-1 min-w-0">
                        <a @click="who = 'vendor'" href="#" class="focus:outline-none">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <p class="text-sm font-medium text-gray-900">{{ service.vendor?.name ?? 'Vendor' }}</p>
                            <p class="text-sm text-gray-500">The vendor requested changes to service.</p>
                        </a>
                    </div>
                </div>

                <div :class="{'border-gray-700': who == 'client'}" class="col-span-3 md:col-span-1 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-d-blue-500">
                    <div class="flex-shrink-0">
                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                            <span class="text-lg font-medium leading-none text-white uppercase"></span>
                        </span>
                    </div>
                    <div class="flex-1 min-w-0">
                        <a @click="who = 'client'" href="#" class="focus:outline-none">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <p class="text-sm font-medium text-gray-900">{{ service.location?.clientCompany?.name ?? 'Client' }}</p>
                            <p class="text-sm text-gray-500">The client requested changes to service.</p>
                        </a>
                    </div>
                </div>

                <div :class="{'border-gray-700': who == 'broker'}" class="col-span-3 md:col-span-1 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-d-blue-500">
                    <div class="flex-shrink-0">
                        <img v-if="current_tenant_thumbnail_url" :src="current_tenant_thumbnail_url" class="inline border border-gray-400 rounded-full mr-2 h-8 w-8" />
                        <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                            <span class="text-lg font-medium leading-none text-white uppercase"></span>
                        </span>
                    </div>
                    <div class="flex-1 min-w-0">
                        <a @click="who = 'broker'" href="#" class="focus:outline-none">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <p class="text-sm font-medium text-gray-900">{{ $page?.props?.authUser?.tenant_name ?? 'Our Company'}}</p>
                            <p class="text-sm text-gray-500">Our company is proposing the change.</p>
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="who">
                <hr class="my-4" />

                <legend v-if="who === 'client'" class="text-xl pt-6">
                    What type of change is the client <span class="font-semibold">{{ service?.location?.clientCompany?.name ?? '' }}</span> requesting?
                </legend>

                <legend v-if="who === 'vendor'" class="text-xl pt-6">
                    What type of change is the vendor <span class="font-semibold">{{ service?.vendor?.name ?? '' }}</span> performing?
                </legend>

                <legend v-if="who === 'broker'" class="text-xl pt-6">
                    What type of change are <span class="font-semibold">you</span> entering?
                </legend>

                <div class="my-4">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li class="py-4" v-if="['broker'].indexOf(who) != -1">
                            <div class="flex flex-wrap cursor-pointer" @click="change_type = 'client-billing-change'; step = 2">
                                <div class="flex items-center justify-between">
                                    <h3 class="text-sm font-medium md:mr-2">Client Billing Change</h3>
                                </div>
                                <p class="text-sm text-gray-500">Example: The new price is charged to the client or billing frequency is changing.</p>
                            </div>
                        </li>

                        <li class="py-4" v-if="['broker', 'client'].indexOf(who) != -1">
                            <div class="flex flex-wrap cursor-pointer" @click="change_type = 'client-contract-change'; step = 2">
                                <h3 class="text-sm font-medium md:mr-2">Client Contract Change</h3>
                                <p class="text-sm text-gray-500">Example: terms have been re-negotiated between us and client.</p>
                            </div>
                        </li>

                        <li class="py-4" v-if="['client', 'vendor'].indexOf(who) != -1">
                            <div class="flex flex-wrap cursor-pointer" @click="change_type = 'level-of-service-change'; step = 2">
                                <h3 class="text-sm font-medium md:mr-2">Level of Service Change</h3>
                                <p class="text-sm text-gray-500">Example: more/less trash is being disposed.</p>
                            </div>
                        </li>

                        <li class="py-4" v-if="['client', 'vendor'].indexOf(who) != -1 && ['Commercial', 'Doorstep', 'Rolloff'].includes(service.type)">
                            <div v-if="service.service_schedule_type==='Scheduled'" class="flex space-x-3 cursor-pointer" @click="change_type = 'pickup-day-change'; step = 2">
                                <h3 class="text-sm font-medium md:mr-2">Pickup Days Change</h3>
                                <p class="text-sm text-gray-500">Example: A waste hauler is picking trash up on Tuesday instead of Monday now.</p>
                            </div>

                            <div v-else class="flex flex-wrap">
                                <h3 class="text-sm font-medium md:mr-2 line-through">Pickup Days Change</h3>
                                <p class="text-sm font-bold text-gray-500">This service's schedule type does not have a pickup schedule.</p>
                            </div>
                        </li>

                        <li class="py-4" v-if="['broker'].includes(who)">
                            <div class="flex flex-wrap cursor-pointer"  @click="change_type = 'sticky-notes-change'; step = 2">
                                <h3 class="text-sm font-medium md:mr-2">Sticky Notes Change</h3>
                                <p class="text-sm text-gray-500">Example: Update the sticky notes on the service</p>
                            </div>
                        </li>

                        <li class="py-4" v-if="['client', 'vendor', 'broker'].indexOf(who) != -1">
                            <div class="flex flex-wrap cursor-pointer"  @click="change_type = 'terminating-service'; step = 2">
                                <h3 class="text-sm font-medium md:mr-2">Terminating Service</h3>
                                <p class="text-sm text-gray-500">Example: Service terminates next month</p>
                            </div>
                        </li>

                        <li class="py-4" v-if="['client', 'vendor', 'broker'].indexOf(who) != -1">
                            <div v-if="service.has_vendor_account !== false" class="flex flex-wrap cursor-pointer" @click="change_type = 'vendor-account-change'; step = 2">
                                <h3 class="text-sm font-medium md:mr-2" :class="{ 'line-through': service.has_vendor_account === false}">Vendor Account Change</h3>
                                <p class="text-sm text-gray-500">Example: the vendor has changed the account number for this service</p>
                            </div>

                            <div v-else class="flex flex-wrap">
                                <h3 class="text-sm font-medium md:mr-2 line-through">Vendor Account Change</h3>
                                <p class="text-sm font-bold text-gray-500">This service has been marked as having no vendor account</p>
                            </div>
                        </li>

                        <li class="py-4" v-if="['client', 'vendor', 'broker'].indexOf(who) != -1">
                            <div class="flex flex-wrap cursor-pointer" @click="change_type = 'vendor-contract-change'; step = 2">
                                <h3 class="text-sm font-medium md:mr-2">Vendor Contract Change</h3>
                                <p class="text-sm text-gray-500">Example: contract for this service has been re-negotiated.</p>
                            </div>
                        </li>

                        <li class="py-4" v-if="['vendor'].indexOf(who) != -1">
                            <div class="flex flex-wrap cursor-pointer" @click="change_type = 'vendor-pricing-change'; step = 2">
                                <h3 class="text-sm font-medium md:mr-2">Vendor Pricing Change</h3>
                                <p class="text-sm text-gray-500">Example: vendor wants to charge more/less for this service</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- wizarding step 2 -->
        <div v-if="step == 2">
            <nav aria-label="Progress">
                <ol role="list" class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">

                    <li class="relative md:flex-1 md:flex">
                        <!-- Completed Step -->
                        <a href="#" @click="step = 1" class="group flex items-center w-full">
                            <span class="px-6 py-4 flex items-center text-sm font-medium">
                                <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-d-blue-600 rounded-full group-hover:bg-d-blue-800">
                                    <!-- Heroicon name: solid/check -->
                                    <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="ml-4 text-sm font-medium text-gray-900">Type of Change</span>
                            </span>
                        </a>

                        <!-- Arrow separator for lg screens and up -->
                        <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                            <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                                <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </li>

                    <li class="relative md:flex-1 md:flex">
                        <!-- Current Step -->
                        <a href="#" class="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                            <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-d-blue-600 rounded-full">
                                <span class="text-d-blue-600">02</span>
                            </span>
                            <span class="ml-4 text-sm font-medium text-d-blue-600">Details</span>
                        </a>
                    </li>
                </ol>
            </nav>

            <client-billing-change
                v-if="change_type === 'client-billing-change'"
                :client-billing-frequencies="clientBillingFrequencies"
                :errors="errors"
                :service="service"
                :who="who"
                :earliest-service-activity="earliestServiceActivity">
            </client-billing-change>

            <client-contract-change
                v-if="change_type === 'client-contract-change'"
                :client-billing-frequencies="clientBillingFrequencies"
                :client-contracts="initialAvailableClientContracts"
                :errors="errors"
                :service="service"
                :who="who"
                :earliest-service-activity="earliestServiceActivity">
            </client-contract-change>

            <level-of-service-change
                v-if="change_type === 'level-of-service-change'"
                :who="who"
                :vendor-accounts="initialAvailableVendorAccounts"
                :vendor-contracts="initialAvailableVendorContracts"
                :client-contracts="initialAvailableClientContracts"
                :bin-types="binTypes"
                :material-types="materialTypes"
                :asset-owners="assetOwners"
                :service="service"
                :errors="errors"
                :earliest-service-activity="earliestServiceActivity">
            </level-of-service-change>

            <pickup-day-change
                v-if="change_type === 'pickup-day-change'"
                :errors="errors"
                :service="service"
                :who="who"
                :earliest-service-activity="earliestServiceActivity">
            </pickup-day-change>

            <sticky-notes-change
                v-if="change_type === 'sticky-notes-change'"
                :errors="errors"
                :service="service"
                :who="who"
                :earliest-service-activity="earliestServiceActivity">
            </sticky-notes-change>

            <terminating-service
                v-if="change_type === 'terminating-service'"
                :errors="errors"
                :service="service"
                :who="who"
                :earliest-service-activity="earliestServiceActivity">
            </terminating-service>

            <vendor-account-change
                v-if="change_type === 'vendor-account-change'"
                :errors="errors"
                :service="service"
                :vendor-accounts="initialAvailableVendorAccounts"
                :who="who"
                :earliest-service-activity="earliestServiceActivity">
            </vendor-account-change>

            <vendor-contract-change
                v-if="change_type === 'vendor-contract-change'"
                :errors="errors"
                :vendor-contracts="initialAvailableVendorContracts"
                :service="service"
                :who="who"
                :earliest-service-activity="earliestServiceActivity">
            </vendor-contract-change>

            <vendor-pricing-change
                v-if="change_type === 'vendor-pricing-change'"
                :errors="errors"
                :service="service"
                :who="who"
                :earliest-service-activity="earliestServiceActivity">
            </vendor-pricing-change>
        </div> <!-- end step 2-->
    </div>
</template>

<script>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import ClientBillingChange from './Edit/ClientBillingChange.vue';
    import ClientContractChange from './Edit/ClientContractChange.vue';
    import LevelOfServiceChange from './Edit/LevelOfServiceChange.vue';
    import PickupDayChange from './Edit/PickupDayChange.vue';
    import StickyNotesChange from './Edit/StickyNotesChange.vue';
    import TerminatingService from './Edit/TerminatingService.vue';
    import VendorAccountChange from './Edit/VendorAccountChange.vue';
    import VendorContractChange from './Edit/VendorContractChange.vue';
    import VendorPricingChange from './Edit/VendorPricingChange.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            DateInput,
            SelectInput,
            Icon,
            Head,
            ClientBillingChange,
            ClientContractChange,
            LevelOfServiceChange,
            PickupDayChange,
            StickyNotesChange,
            TerminatingService,
            VendorAccountChange,
            VendorContractChange,
            VendorPricingChange,
        },

        props: {
            assetOwners: {
                type: Array,
                required: true
            },

            clientBillingFrequencies: {
                type: Array,
                required: true
            },

            binTypes: {
                type: Array,
                required: true
            },

            clientServiceFeeFrequencies: {
                type: Array,
                required: true
            },

            earliestServiceActivity: {
                type: Object,
                required: true
            },

            initialAvailableVendorAccounts: {
                type: Array,
                default: []
            },

            initialAvailableVendorContracts: {
                type: Array,
                default: []
            },

            initialAvailableClientContracts: {
                type: Array,
                default: []
            },

            initialClients: {
                type: Array,
                default: []
            },

            locations: {
                type: Array,
                default: []
            },

            lineItemCategories: {
                type: Array,
                required: true
            },

            materialTypes: {
                type: Array,
                required: true
            },

            serviceTypes: {
                type: Array,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

            service: {
                type: Object,
                required: true
            },

            serviceAccountTypes: {
                type: Array,
                required: true
            },

            serviceScheduleTypes: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                step: 1,
                who: null,
                change_type: null,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        computed: {
            fancy_who() {
                if (this.who === 'client') {
                    return 'the client ' + this.service?.location?.clientCompany?.name ?? ''
                }
                else if (this.who === 'vendor') {
                    return 'the vendor ' + this.service?.vendor?.name ?? ''
                }
                else if (this.who === 'broker') {
                    return 'you'
                }

                return this.who
            },

            current_tenant_thumbnail_url() {
                return this.$page.props
                    .authUser
                    .employeesTenants
                    .filter(tenant => tenant.id === this.$page.props.authUser.current_tenant_id)
                    .brand_mark_thumbnail_url ?? '';
            }
        },
    }
</script>
