<template>
    <Head :title="`Edit Client Billing Change - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Client Billing Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
    </div>

    <div class="container mx-auto mt-8 mb-12">
        <div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <revert-input v-model="form.change_requester" class="col-span-2 md:col-span-1" label="Who requested this change?" mark-as-required>
                    <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                        <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-model="form.actual_date" class="col-span-3 md:col-span-1" label="When is this change effective?" mark-as-required>
                    <date-input :min="serviceCreationActivity.actual_date" v-model="form.actual_date" :errors="errors['actual_date']" />
                </revert-input>

                <revert-input v-model="form.change_reason" class="col-span-3 md:col-span-1" label="Why are you making this change?" mark-as-required>
                    <select-input v-model="form.change_reason">
                        <option>Annual Price Increase</option>
                        <option>Other</option>
                        <option>Savings</option>
                    </select-input>
                </revert-input>
            </div>

            <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
                <revert-input v-model="form.is_pass_through" class="col-span-2 md:col-span-1" label="Is Pass Through?" mark-as-required>
                    <select-input v-model="form.is_pass_through" :errors="errors['is_pass_through']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select-input>
                </revert-input>

                <revert-input :disabled="form.is_pass_through === true && serviceActivity.service.type !== 'Rolloff'" v-model="form.client_billing_frequency" class="col-span-2 md:col-span-1" label="Client Billing Frequency" mark-as-required>
                    <select-input v-model="form.client_billing_frequency" placeholder="Client Billing Frequency" :errors="errors['client_billing_frequency']">
                        <option v-for="clientBillingFrequency in clientBillingFrequencies" :key="clientBillingFrequency" :value="clientBillingFrequency">{{ clientBillingFrequency }}</option>
                    </select-input>
                </revert-input>

                <revert-input v-if="form.is_pass_through === false && serviceHasClientBaseCharge" v-model="form.client_base_charge" class="col-span-2 md:col-span-1" label="Base Charge" mark-as-required>
                    <money-input v-model="form.client_base_charge" placeholder="Base Charge" :defaultCurrency="service.location.clientCompany.currency" :errors="errors['client_base_charge']" />
                </revert-input>

                <revert-input v-if="form.is_pass_through === false && serviceHasClientPerUnitCharge" v-model="form.client_per_unit_charge" class="col-span-2 md:col-span-1" label="Per Unit Charge" mark-as-required>
                    <money-input v-model="form.client_per_unit_charge" placeholder="Per Unit Charge" :defaultCurrency="service.location.clientCompany.currency" :errors="errors['client_per_unit_charge']" />
                </revert-input>

                <revert-input v-if="form.is_pass_through === false && serviceHasClientPerHaulAmount" v-model="form.client_per_haul_amount" class="col-span-2 md:col-span-1" label="Per Haul Amount" mark-as-required>
                    <money-input  v-model="form.client_per_haul_amount"  placeholder="Per Haul Amount" :defaultCurrency="service.location.clientCompany.currency" :errors="errors['client_per_haul_amount']" />
                </revert-input>

                <revert-input v-if="form.is_pass_through === false && serviceHasClientDisposalAmountPerTon" v-model="form.client_disposal_amount_per_ton" class="col-span-2 md:col-span-1" label="Disposal Amount Per Ton" mark-as-required>
                    <money-input  v-model="form.client_disposal_amount_per_ton" :defaultCurrency="service.location.clientCompany.currency" placeholder="Disposal Amount Per Ton" :errors="errors['client_disposal_amount_per_ton']" />
                </revert-input>

                <revert-input v-model="form.internal_change_notes" class="col-span-2" label="Internal Change Notes">
                    <textarea-input v-model="form.internal_change_notes" />
                </revert-input>

                <hr v-if="showPriorPeriodAdjustment" class="col-span-2 my-4" />

                <div class="col-span-2 rounded-md bg-yellow-50 py-2 px-1" v-if="showPriorPeriodAdjustment">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-yellow-800">Prior Period Adjustment</h3>
                            <div class="mt-2 text-sm text-yellow-700">
                                <div class="pr-2">
                                    You are changing the price on this service from
                                    <span class="font-medium">{{ $filters.format_money(serviceActivity.serviceSnapshot.client_base_charge) }}</span> to
                                    <span class="font-medium">{{ $filters.format_money(form.client_base_charge) }}</span> on
                                    <span class="font-medium">{{ $filters.format_date(form.actual_date) }}</span>.
                                    <span v-if="priorPeriodAdjustment.lastLineItemBilled">
                                        The service was billed for fiscal period
                                        <span class="font-medium">{{ $filters.format_date(priorPeriodAdjustment.lastLineItemBilled.origin_date) }}</span> already for
                                        <span class="font-medium">{{ $filters.format_money(priorPeriodAdjustment.lastLineItemBilled.total_amount) }}</span>
                                    </span>
                                    <p class="mt-1">If you wish to bill a prior period adjustment for this change, navigate to the prior period adjustments page after saving this change.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">Submit Change</loading-button>
        </div>
    </div>
</template>

<script>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { format, startOfToday } from "date-fns";
    import axios from "axios";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            DateInput,
            Icon,
            LoadingButton,
            MoneyInput,
            RevertInput,
            SelectInput,
            TextareaInput,
            Head,
        },

        props: {
            clientBillingFrequencies: {
                type: Array,
                required: true
            },

            serviceCreationActivity: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

            serviceActivity: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    actual_date:                    this.serviceActivity.actual_date,
                    is_pass_through:                this.serviceActivity.serviceSnapshot.is_pass_through,
                    change_requester:               this.serviceActivity.change_requester,
                    change_reason:                  this.serviceActivity.reason,
                    internal_change_notes:          this.serviceActivity.internal_change_notes,
                    client_billing_frequency:       this.serviceActivity.serviceSnapshot.client_billing_frequency,
                    client_base_charge:             this.serviceActivity.serviceSnapshot.client_base_charge ?? null,
                    client_per_unit_charge:         this.serviceActivity.serviceSnapshot.client_per_unit_charge ?? null,
                    client_per_haul_amount:         this.serviceActivity.serviceSnapshot.client_per_haul_amount ?? null,
                    client_disposal_amount_per_ton: this.serviceActivity.serviceSnapshot.client_disposal_amount_per_ton ?? null,
                },
                saving: false,
                priorPeriodAdjustment: null,
                today: format(startOfToday(), 'yyyy-MM-dd'),
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitChange() {
                this.saving = true;

                // should we schedule this or fire it off immediately?
                // we can call different routes or we could just handle in the controller... not sure which is easiest
                this.$inertia.put(this.$route('services.client-billing-changes.update', [this.serviceActivity.service_id, this.serviceActivity.id]), {
                    ...this.form,
                }, {
                    onFinish: () => this.saving = false
                });
            },

            suggestPriorPeriodAdjustment() {
                if (!this.$page.props.permissions.accessRevenueManagement) {
                    return false;
                }

                if (this.form.is_pass_through) {
                    return false;
                }

                if (!['Monthly', 'Quarterly', 'Yearly'].includes(this.form.client_billing_frequency)) {
                    return false;
                }

                if (this.form.service_schedule_type === 'One Time') {
                    return false;
                }

                if (this.serviceActivity.serviceSnapshot.type === 'Rolloff') {
                    return false;
                }

                if (this.serviceActivity.serviceSnapshot.client_base_charge === this.form.client_base_charge) {
                    return false;
                }

                return true;
            },

            updatePriorPeriodAdjustmentDetails() {
                axios.get(`/json/services/${this.serviceActivity.service_id}/prior-period-adjustments/create-from-price-change?change_date=${this.form.actual_date}&adjusted_amount=${this.form.client_base_charge.amount/100}`).then(response => {
                    this.priorPeriodAdjustment = response.data
                });
            },

            deleteServiceChange() {
                if (window.confirm('Are you sure you want to delete this client billing change?')) {
                    this.$inertia.delete(this.$route('services.client-billing-changes.destroy', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id}));
                }
            },
        },

        computed: {
            serviceHasClientBaseCharge() {
                return ['Commercial', 'Device', 'Doorstep', 'Rental'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasClientPerUnitCharge() {
                return ['Doorstep'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasClientPerHaulAmount() {
                return ['Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },

            serviceHasClientDisposalAmountPerTon() {
                return ['Rolloff'].includes(this.serviceActivity.serviceSnapshot.type);
            },
            showPriorPeriodAdjustment() {
                return this.suggestPriorPeriodAdjustment() && this.priorPeriodAdjustment?.proratedAmount?.amount && this.priorPeriodAdjustment.proratedAmount.amount != 0
            }
        },

        watch: {
            'form.client_base_charge'() {
                if (this.suggestPriorPeriodAdjustment()) {
                    this.updatePriorPeriodAdjustmentDetails();
                }
            },

            'actual_date'() {
                if (this.suggestPriorPeriodAdjustment()) {
                    this.updatePriorPeriodAdjustmentDetails();
                }
            },

	        'form.is_pass_through'(newValue, oldValue) {
		        if (newValue === true && this.serviceActivity.service.type !== 'Rolloff') {
			        this.form.client_billing_frequency = 'Per Vendor Invoice';
		        } else {
			        this.form.client_billing_frequency = this.service.client_billing_frequency;
		        }
	        }
        }
    }
</script>
