<template>
    <div>
        <div v-if="who === 'broker'" class="my-8"><b>You</b> are entering a <b>service termination change</b>. Please fill in the details.</div>
        <div v-else class="my-8">The <b>{{ who }}</b> is requesting a <b>service termination change</b>. Please fill in the details.</div>

        <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4">
            <date-input class="col-span-2 md:col-span-1" :min="minimumActualDate" v-model="termination_date" label="When should this service be terminated?" :errors="errors['termination_date']" mark-as-required />
            
            <div class="col-span-2 md:col-span-1">
                <p v-if="termination_date && termination_date < today" class="mt-2 text-sm text-gray-600">NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).</p>
                <p v-if="termination_date && termination_date > today" class="mt-2 text-sm text-gray-600">NOTE: This will only set the termination date on the service. The service will remain active until the termination date is reached. You may cancel this change at any time prior to the chosen date by removing the termination date from this service.</p>
            </div>

            <select-input class="col-span-2 md:col-span-1" label="Why are you making this change?" v-model="change_reason" mark-as-required>
                <option>Other</option>
                <option>Savings</option>
            </select-input>

            <textarea-input class="col-span-2" v-model="internal_change_notes" label="Internal Change Notes" />

            <hr v-if="showPriorPeriodAdjustment" class="col-span-2 my-4" />

            <div class="col-span-2 rounded-md bg-yellow-50 py-2 px-1" v-if="showPriorPeriodAdjustment">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-yellow-800">Prior Period Adjustment</h3>
                        <div class="mt-2 text-sm text-yellow-700">
                            <div class="pr-2">
                                You are changing the termination date to
                                <span class="font-medium">{{ termination_date }}</span>.
                                <span v-if="lastBilledClientLineItemDetails?.lastLineItemBilled">
                                    The service was already billed
                                    <span class="font-medium">{{ $filters.format_money(lastBilledClientLineItemDetails?.lastLineItemBilled.total_amount) }}</span>
                                    for fiscal period
                                    <span class="font-medium">{{ $filters.format_date(lastBilledClientLineItemDetails?.lastLineItemBilled.origin_date) }}</span>.
                                </span>
                                <p class="mt-1">Please enter an amount and description in the boxes below if you wish to bill a prior period adjustment for this change.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <money-input v-if="showPriorPeriodAdjustment" label="Prorated Amount" :defaultCurrency="$page.props.currency" v-model="ppa_amount"></money-input>
            <textarea-input v-if="showPriorPeriodAdjustment" label="Line Item Description" v-model="ppa_description"></textarea-input>
        </div>

        <loading-button class="btn btn-orange mt-2"  @click="submitChange" :disabled="!termination_date || !change_reason">Submit Change</loading-button>
    </div>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import MoneyInput from "@/Shared/MoneyInput.vue";
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import {format, startOfToday} from "date-fns";
    import axios from "axios";

    export default {
        components: {
            MoneyInput,
            DateInput,
            LoadingButton,
            SelectInput,
            TextareaInput
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            service: {
                type: Object,
                required: true
            },

            who: {
                type: String,
                required: true
            },

            earliestServiceActivity: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                saving: false,
                form: {
                },
                termination_date:                   format(startOfToday(), 'yyyy-MM-dd'),
                change_reason:                      null,
                change_requester:                   this.who,
                internal_change_notes:              null,
                today:                              format(startOfToday(), 'yyyy-MM-dd'),
                lastBilledClientLineItemDetails:    null,
                ppa_amount:                         null,
                ppa_description:                    null,
            }
        },

        methods: {
            submitChange() {
                this.saving = true;

                let routeName = this.termination_date < this.today ? 'services.service-termination-changes.store' : 'services.service-termination-date-changes.store';
                this.$inertia.post(this.$route(routeName, [this.service.id]), {
                    ...this.form,
                    'termination_date': this.termination_date,
                    'change_reason': this.change_reason,
                    'change_requester': this.who,
                    'internal_change_notes': this.internal_change_notes,
                    'ppa_amount': this.showPriorPeriodAdjustment ? this.ppa_amount : null,
                    'ppa_description': this.showPriorPeriodAdjustment ? this.ppa_description : null,
                }, {
                    onFinish: () => this.saving = false
                });
            },

            suggestPriorPeriodAdjustment() {
                if (!this.$page.props.permissions.accessRevenueManagement) {
                    return false;
                }

                if (this.service.is_pass_through) {
                    return false;
                }

                if (!['Monthly', 'Quarterly', 'Yearly'].includes(this.service.client_billing_frequency)) {
                    return false;
                }

                if (this.service.service_schedule_type === 'One Time') {
                    return false;
                }

                if (this.service.type === 'Rolloff') {
                    return false;
                }

                if (this.form.termination_date === this.service.termination_date) {
                    return false;
                }

                return true;
            },

            updateLastBilledClientLineItemDetails() {
                axios.get(`/json/services/${this.service.id}/last-billed-client-line-item-details?maxOriginDate=${this.termination_date}`).then(response => {
                    this.lastBilledClientLineItemDetails = response.data
                });
            }
        },

        computed: {
            minimumActualDate() {
                if (this.service.effective_date > this.earliestServiceActivity.actual_date) {
                    return this.service.effective_date;
                }

                return this.earliestServiceActivity.actual_date;
            },

            showPriorPeriodAdjustment() {
                return this.suggestPriorPeriodAdjustment() && this.lastBilledClientLineItemDetails && this.lastBilledClientLineItemDetails?.lastBillingPeriodEnd > this.termination_date
            }
        },

        watch: {
            'termination_date'() {
                if (this.suggestPriorPeriodAdjustment()) {
                    this.updateLastBilledClientLineItemDetails();
                }
            }
        },

        mounted() {
            if (this.suggestPriorPeriodAdjustment()) {
                this.updateLastBilledClientLineItemDetails();
            }
        }
    }
</script>
