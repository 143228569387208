<template>
    <Head :title="`Scheduled ${serviceScheduledChanges.change_type} for Service ${serviceScheduledChanges.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', [serviceScheduledChanges.service_id])" class="breadcrumb-link">{{ serviceScheduledChanges.service_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Scheduled {{ serviceScheduledChanges.change_type }}</span>
        </nav>
    </Teleport>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button @click.prevent="deleteScheduledChange" class="btn btn-red">Delete</button>
    </div>

    <div class="container mx-auto mt-4 mb-12">
        <fieldset>
            <legend class="font-semibold text-xl text-blue-600">Service Change Info</legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <div>
                    <label class="form-label">Change Requester</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.change_requester }}</span>
                </div>

                <div>
                    <label class="form-label">Scheduled Date</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.event_payload.actual_date }}</span>
                </div>

                <div>
                    <label class="form-label">Change Reason</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.event_payload.change_reason }}</span>
                </div>
            </div>
        </fieldset>

        <fieldset
            class="mt-4"
            v-if="serviceScheduledChanges.change_type === 'Level of Service Change' ||
                serviceScheduledChanges.change_type === 'Vendor Pricing Change' ||
                serviceScheduledChanges.change_type === 'Vendor Contract Change' ||
                serviceScheduledChanges.change_type === 'Pickup Days Change'"
        >
            <legend class="font-semibold text-xl text-blue-600">Vendor</legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4">
                <div v-if="serviceScheduledChanges.changes.vendor_account_id">
                    <label class="form-label">Vendor Account</label>
                    <span class="m-1 text-sm">{{ vendorAccount.account_number }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_contract_id">
                    <label class="form-label">Vendor Contract</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.vendor_contract_id }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.service_account_type">
                    <label class="form-label">Service Account Type</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.service_account_type }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.service_schedule_type">
                    <label class="form-label">Service Schedule Type</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.service_schedule_type }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.pickup_rrule">
                    <label class="form-label">Pickup Schedule</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.pickup_rrule ? parseRRule(serviceScheduledChanges.changes.pickup_rrule) : 'No pickup schedule set' }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_base_charge">
                    <label class="form-label">Vendor Base Charge</label>
                    <span class="m-1 text-sm">{{ $filters.format_money(serviceScheduledChanges.changes.vendor_base_charge) }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_per_unit_charge">
                    <label class="form-label">Vendor Per Unit Charge</label>
                    <span class="m-1 text-sm">{{ $filters.format_money(serviceScheduledChanges.changes.vendor_per_unit_charge) }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_disposal_amount_per_ton">
                    <label class="form-label">Vendor Disposal Amount Per Ton</label>
                    <span class="m-1 text-sm">{{ $filters.format_money(serviceScheduledChanges.changes.vendor_disposal_amount_per_ton) }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.vendor_per_haul_amount">
                    <label class="form-label">Vendor Per Haul Amount</label>
                    <span class="m-1 text-sm">{{ $filters.format_money(serviceScheduledChanges.changes.vendor_per_haul_amount) }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.tons_included_per_haul">
                    <label class="form-label">Tons Included Per Haul</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.tons_included_per_haul }}</span>
                </div>
            </div>
        </fieldset>

        <fieldset
            class="mt-4"
            v-if="serviceScheduledChanges.change_type === 'Level of Service Change' ||
            serviceScheduledChanges.change_type === 'Client Billing Change' ||
            serviceScheduledChanges.change_type === 'Client Contract Change'"
        >

            <legend class="font-semibold text-xl text-blue-600">Client</legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-4 my-4">
                <div v-if="serviceScheduledChanges.changes.client_contract_id">
                    <label class="form-label">Client Contract</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.client_contract_id }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.client_base_charge">
                    <label class="form-label">Client Base Charge</label>
                    <span class="m-1 text-sm">{{ $filters.format_money(serviceScheduledChanges.changes.client_base_charge) }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.client_per_unit_charge">
                    <label class="form-label">Client Per Unit Charge</label>
                    <span class="m-1 text-sm">{{ $filters.format_money(serviceScheduledChanges.changes.client_per_unit_charge) }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.client_per_haul_amount">
                    <label class="form-label">Client Per Haul Amount</label>
                    <span class="m-1 text-sm">{{ $filters.format_money(serviceScheduledChanges.changes.client_per_haul_amount) }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.client_disposal_amount_per_ton">
                    <label class="form-label">Client Disposal Amount Per Ton</label>
                    <span class="m-1 text-sm">{{ $filters.format_money(serviceScheduledChanges.changes.client_disposal_amount_per_ton) }}</span>
                </div>
            </div>
        </fieldset>

        <fieldset v-if="serviceScheduledChanges.changes.bin" class="mt-4">
            <legend class="block font-semibold text-xl text-blue-600">Bin</legend>

            <div class="grid gap-x-4 gap-y-6 sm:grid-cols-4 my-4">

                <div v-if="serviceScheduledChanges.changes.bin.quantity">
                    <label class="form-label">Quantity</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.quantity }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.size">
                    <label class="form-label">Size</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.size }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.size_metric">
                    <label class="form-label">Size Metric</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.size_metric }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.material_type">
                    <label class="form-label">Material Type</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.material_type }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.disposal_method">
                    <label class="form-label">Disposal Method</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.disposal_method }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.type">
                    <label class="form-label">Bin Type</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.type }}</span>
                </div>
            </div>
        </fieldset>

        <fieldset v-if="serviceScheduledChanges.changes.bin">
            <legend  class="block font-semibold text-l text-blue-600 my-5">
                <button v-if="collapsed" @click="changeCollapse">
                    Show Advanced Bin Options
                    <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="collapsed ? 'chevron-right' : 'chevron-down'" />
                </button>
                <button v-else @click="changeCollapse">
                    Hide Advanced Bin Options
                    <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="collapsed ? 'chevron-right' : 'chevron-down'" />
                </button>
            </legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <div v-if="serviceScheduledChanges.changes.bin.owner && !collapsed">
                    <label class="form-label">Asset Owner</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.owner }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.has_casters && !collapsed">
                    <label class="form-label">Has Casters</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.has_casters }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.push_out_service && !collapsed">
                    <label class="form-label">Push Out Service</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.push_out_service }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.push_out_service_description && !collapsed">
                    <label class="form-label">Push Out Service Description</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.push_out_service_description }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.enclosure && !collapsed">
                    <label class="form-label">Enclosure</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.enclosure }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.has_locks && !collapsed">
                    <label class="form-label">Has Lock</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.has_locks }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.has_lids && !collapsed">
                    <label class="form-label">Lids</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.has_lids }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.side_door_to_bin && !collapsed">
                    <label class="form-label">Side Door To Bin</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.side_door_to_bin }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.secured_access && !collapsed">
                    <label class="form-label">Secured Access</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.secured_access }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.keys && !collapsed">
                    <label class="form-label">Requires Keys</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.keys }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.requires_overhead_instructions && !collapsed">
                    <label class="form-label">Overhead Instructions</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.requires_overhead_instructions }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.latitude && !collapsed">
                    <label class="form-label">Bin Latitude</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.latitude }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.longitude && !collapsed">
                    <label class="form-label">Bin Longitude</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.longitude }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.container_access_times && !collapsed">
                    <label class="form-label">Container Access Times</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.container_access_times }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.area_description && !collapsed">
                    <label class="form-label">Area Description</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.area_description }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.configuration && !collapsed">
                    <label class="form-label">Configuration</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.configuration }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.is_compacted_or_baled && !collapsed">
                    <label class="form-label">Is Compacted Or Baled</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.is_compacted_or_baled }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.compaction_method && !collapsed">
                    <label class="form-label">Compaction Method</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.compaction_method }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.compaction_ratio && !collapsed">
                    <label class="form-label">Compaction Ratio</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.compaction_ratio }}</span>
                </div>

                <div v-if="serviceScheduledChanges.changes.bin.pounds_per_yard && !collapsed">
                    <label class="form-label">Pounds Per Yard</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.changes.bin.pounds_per_yard }}</span>
                </div>
            </div>
        </fieldset>

        <fieldset v-if="serviceScheduledChanges.event_payload.internal_change_notes" class="mt-4">
            <legend class="block font-semibold text-xl text-blue-600">Internal Change Notes</legend>

            <div class="grid gap-x-4 gap-y-6 grid-cols-1 my-4">
                <div>
                    <label class="form-label">Internal Change Notes</label>
                    <span class="m-1 text-sm">{{ serviceScheduledChanges.event_payload.internal_change_notes }}</span>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import { router, Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import { rrulestr } from "rrule";


    export default {
        components: {
            Icon,
            Head,
        },

        props: {
            serviceScheduledChanges: {
                type: Object,
                required: true
            },

            vendorAccount: {
                type: Object,
                required: false
            }
        },

        methods: {
            deleteScheduledChange() {
                if (window.confirm('Are you sure you want to remove this scheduled revision?')) {
                    router.delete(this.$route('service-scheduled-changes.destroy', [this.serviceScheduledChanges.id]));
                }
            },

            parseRRule(rRule) {
                return rRule.rfcstring ? rrulestr(rRule.rfcstring).toText() : '';
            },

            changeCollapse() {
                this.collapsed = !this.collapsed;
            },
        },

        data() {
            return {
                collapsed: true,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },
    }
</script>
