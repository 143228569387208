<template>
    <div :class="$attrs.class">
        <slot name="label">
            <label v-if="label" class="form-label flex" :for="id" :aria-required="markAsRequired">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
	            <tooltip-icon v-if="tooltip" :tooltip="tooltip" :type="tooltipType"/>
            </label>
        </slot>

        <p v-if="helpText" class="mt-2 text-sm text-gray-600">{{ helpText }}</p>
        
        <input ref="input" v-bind="{...$attrs, class: null}" :disabled="disabled" class="form-input" :class="{ error: errors, disabled: disabled }" :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
        
        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>
import {v4 as uuid} from 'uuid';
import TooltipIcon from "./TooltipIcon.vue";
import Icon from "./Icon.vue";

export default {
	    components: {Icon, TooltipIcon},
        inheritAttrs: false,

        props: {
            id: {
                type: String,
                default() {
                    return `text-input-${uuid()}`;
                },
            },

            type: {
                type: String,
                default: 'text',
            },

            modelValue: [String, Number],

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },
            
            disabled: {
                type: Boolean,
                default: false
            },

			tooltip: {
				default: ''
			},

	        tooltipType: {
		        type: String,
		        default: 'text'
	        }
        },

        emits: ['update:modelValue'],

        methods: {
            focus() {
                this.$refs.input.focus()
            },

	        blur() {
		        this.$refs.input.blur()
	        },

            select() {
                this.$refs.input.select()
            },

            setSelectionRange(start, end) {
                this.$refs.input.setSelectionRange(start, end)
            },
        },
    }
</script>
