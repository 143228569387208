<template>
    <div :class="$attrs.class" class="relative">

        <slot name="label" :label="label" :mark-as-required="markAsRequired">
            <label v-if="label" class="form-label" :aria-required="markAsRequired">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
            </label>
        </slot>

        <p v-if="helpText" class="mt-2 text-sm text-gray-600">{{ helpText }}</p>

        <div class="relative">
            <input ref="input" 
                class="form-input" 
                type="text" 
                v-model="searchFilter" 
                aria-expanded="false" 
                v-bind="{...$attrs}"
                @input="startSearch" 
                @blur="hideSearchBox"
                @click="showSearchBox"
                :disabled="loading || disabled"
                :class="{'bg-gray-100': loading || disabled}"
                v-on:keydown.esc="hideSearchBox"
                v-on:keydown.down="startMoveHighlightDown"
                v-on:keyup.down="stopMoveHighlightDown"
                v-on:keydown.up="startMoveHighlightUp"
                v-on:keyup.up="stopMoveHighlightUp"
                v-on:keydown.enter="selectHighlightedResult" />
                
            <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none text-gray-400">
                <icon v-if="loading" name="ellipsis" class="processing-icon text-gray-600 h-4 w-4 fill-current" title="Please wait for the data to be loaded"></icon>
                <icon v-else-if="searching" name="spinner" class="spin h-4 w-4 fill-current" title="Please wait while we search for your results"></icon>
                <icon v-else-if="!searchShown" @mousedown.prevent="showSearchBox" name="sort-up-down" class="h-4 w-4 fill-current"></icon>
                <icon v-else @mousedown.prevent="clearModel" name="times" class="h-4 w-4 fill-current"></icon>
            </button>

            <ul v-if="searching && searchShown" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" role="listbox">
                <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:text-white hover:bg-blue-600 hover:cursor-pointer" role="option" tabindex="-1">
                    <icon name="search" class="w-3 h-3 fill-current inline search-icon search-animation" /> Searching...
                </li>
            </ul>

            <!-- we allow users to provide slot templates to customize their search
                <slot name="option-id" :option="null"></slot>
                <slot name="option-label" :option="null"></slot>
                <slot name="option-search" :option="null"></slot>
            -->

            <ul v-if="!searching && searchShown" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" role="listbox" ref="searchbox">
                <li v-for="(option, index) in internalOptions" 
                    :key="index" 
                    @mousedown.prevent="selectOption(option)"
                    @mouseover="() => highlightedIndex = index"
                    :class="{'bg-gray-300' : index === highlightedIndex}" 
                    class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:cursor-pointer" 
                    role="option"
                    tabindex="-1">
                    <slot name="option-display" :option="option" :filter="searchFilter" :selected="getOptionSelected(option)">
                        <span :class="{'font-semibold': getOptionSelected(option)}" class="block">{{ getOptionLabel(option) }}</span>
                        <span v-if="getOptionSelected(option)" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                            <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                        </span>
                    </slot>
                </li>

                <slot name="no-options" v-if="!searching && this.internalOptions.length === 0">
                    <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900">
                        <span class="block truncate">No results found!</span>
                    </li>
                </slot>
            </ul>
        </div>

        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>

/**
 * Version 2 of the Search Input that allows us to control the options of our select. 
 * It is more flexible and styled with Tailwind UI combobox css so we can have a consistent 
 * look across our app. I iinitially tried to use this with Combobox vue components but they
 * had issues (weird quirks would happen as I search, loss of focus, etc...). This component
 * should allow us to have complete control of our searchable select behaviors.
 * 
 * It is made to work with options and a v-model. To add ajax search you simply need to add 
 * another parameter instructing the component how to filter the options.
 */
import Icon from '@/Shared/Icon.vue';

export default {
    inheritAttrs: false,

    props: {
        modelValue: [String, Number, Object],
        
        options: {
            type: Array,
            default: []
        },

        customSearch: {
            type: Function,
            default: null
        },

        label: String,

        helpText: {
            type: String,
            default: ''
        },

        errors: {
            type: String,
            default: '',
        },

        markAsRequired: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        verbose: {
            type: Boolean,
            default: false
        }
    },

    emits: [
        'update:modelValue',
        'selected',
        'search',
    ],

    components: {
        Icon,
    },

    data() {
        let optionValue = this.findOptionValue(this.modelValue)
        let performSearch = this.rudamentarySearch

        if (typeof this.customSearch === 'function') {
            performSearch = this.customSearch;
        }

        return {
            internalOptions: [...this.options],
            searchFilter: optionValue ? this.getOptionLabel(optionValue) : null,
            searchShown: false,
            loading: false,
            searching: false,
            highlightedIndex: null,
            holdingKey: null,
            performSearch: performSearch
        }
    },

    methods: {
        getOptionId(option) {
            if (typeof this.$slots['option-id'] === 'function') {
                return (this.$slots['option-id'](option)).map((result) => result.children).join('');
            }

            // This is for standard options that have an id property
            if (option && option.id) {
                return option.id;
            }

            // This is for when we want to provide a null option.  See ServiceCreationChanges\Edit for an example
            if (option && option.id === null) {
                return null;
            }

            // This is for when the option is the id itself
            return option;
        },

        getOptionLabel(option) {
            if (option && typeof this.$slots['option-label'] === 'function') {
                return (this.$slots['option-label'](option)).map((result) => result.children).join('');
            }

            return option?.name ?? this.getOptionId(option);
        },

        getOptionSearch(option) {
            if (option && typeof this.$slots['option-search'] === 'function') {
                return (this.$slots['option-search'](option)).map((result) => result.children).join('');
            }

            return this.getOptionLabel(option);
        },

        getOptionSelected(option) {
            return this.getOptionId(option) !== null && this.getOptionId(this.modelValue) == this.getOptionId(option) 
        },

        findOptionValue(option) {
            let options = this.internalOptions ? this.internalOptions : this.options;  // allows us to provide initial options before data() setup
            let found = options.filter((o) => this.getOptionId(o) == this.getOptionId(option))
            return found.length > 0 ? found[0] : null;
        },

        startSearch() {
            this.searching = true
            this.showSearchBox();
            this.performSearch(this.searchFilter, this.finishSearch, this.internalOptions);
        },
        
        finishSearch() {
            this.searching = false
            this.loading = false
        },

        showSearchBox() {
            if (this.loading || this.disabled) {
                return;
            }

            this.searchShown = true

            let foundIndex = this.internalOptions.findIndex((option) => {
                return this.getOptionId(option) == this.getOptionId(this.modelValue)
            });

            if (foundIndex) {
                this.highlightedIndex = foundIndex;
                this.scrollSearchBoxToHighlighted()
            }
        },

        hideSearchBox() {
            this.searchShown = false
            this.highlightedIndex = null

            if (this.performSearch === this.rudamentarySearch) {
                this.internalOptions = [...this.options]
            }
        },

        clearModel() {
            this.$emit('update:modelValue', null);
            this.$emit('selected', null)
            this.hideSearchBox();
        },

        selectOption(option) {
            let optionValue = this.findOptionValue(option)

            if (optionValue) {
                let label = this.getOptionLabel(optionValue);
                if (label) { this.searchFilter = label; }
            }

            this.$emit('update:modelValue', this.getOptionId(optionValue))
            this.$emit('selected', option)

            this.hideSearchBox()
        },

        startMoveHighlightDown() {
            if (this.holdingKey) clearTimeout(this.holdingKey)
            this.moveHightlightDown()
            this.holdingKey = setTimeout(this.moveHightlightDown, 200)
        },

        stopMoveHighlightDown() {
            if (this.holdingKey) clearTimeout(this.holdingKey)
            this.holdingKey = null
        },

        moveHightlightDown() {
            if (this.searchShown === false) {
                this.showSearchBox()
            }
            else if (this.highlightedIndex === null) {
                this.highlightedIndex = 0;
            } else if (this.highlightedIndex < 0) {
                this.highlightedIndex = 0;
            } else if (this.highlightedIndex > this.internalOptions.length - 1) {
                this.highlightedIndex = this.internalOptions.length - 1 
            } else if (this.highlightedIndex < this.internalOptions.length - 1) {
                this.highlightedIndex++;
            }

            this.scrollSearchBoxToHighlighted()
        },

        startMoveHighlightUp() {
            if (this.holdingKey) clearTimeout(this.holdingKey)
            this.moveHightlightUp()
            this.holdingKey = setTimeout(this.moveHightlightUp, 200)
        },

        stopMoveHighlightUp() {
            if (this.holdingKey) clearTimeout(this.holdingKey)
            this.holdingKey = null
        },

        moveHightlightUp() {
            if (this.highlightedIndex === null && this.internalOptions.length > 0) {
                this.highlightedIndex = this.internalOptions.length - 1;
            } else if (this.highlightedIndex < 0) {
                this.highlightedIndex = 0;
            } else if (this.highlightedIndex > this.internalOptions.length - 1) {
                this.highlightedIndex = this.internalOptions.length - 1 
            } else if (this.highlightedIndex > 0) {
                this.highlightedIndex--;
            }

            this.scrollSearchBoxToHighlighted()
        },

        scrollSearchBoxToHighlighted() {
            this.$nextTick(() => {
                if (this.$refs.searchbox) {
                    if (this.highlightedIndex < this.$refs.searchbox.children.length) {
                        let elem = this.$refs.searchbox.children[this.highlightedIndex] ?? null;
                        if (elem) {
                            elem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) 
                        }
                    }
                }
            });
        },

        selectHighlightedResult() {
            if (this.highlightedIndex !== null) {
                this.selectOption(this.internalOptions[this.highlightedIndex])
            }
        },

        // rudentary out of the box search 
        // this just looks for strings inside the label 
        // note that most of the time label and search will be
        // the same, however, we might want to search extra stuff 
        // than what is in the label (like location.name PLUS location.address)
        rudamentarySearch(filter, finished) {
            this.internalOptions = this.options.filter((option) => {
                let label = this.getOptionSearch(option)
                return label ? label.toLowerCase().includes(filter.toLowerCase()) : false
            });

            finished()
        }
    },

    mounted() {
        // maybe we need to kick off a search to find all the things, 
        // our custom-search should hopefully ensure that the results always include the modelValue's id
        if (this.modelValue && this.performSearch !== this.rudamentarySearch) {
            let optionValue = this.findOptionValue(this.modelValue);

            // auto search when we initial mount in case we need to look up new options to find our actual modelValue within
            if (!optionValue) {
                this.searching = true
                this.loading = true
                this.performSearch('', this.finishSearch);
            }
        }

    },

    watch: {
        modelValue(newValue) {
            this.searchFilter = this.getOptionLabel(this.findOptionValue(newValue))
        },

        options(newValue) {
            this.internalOptions = [...newValue]

            // this is a way to update the searchFilter whenever we first initalize the component and the options are still populating from ajax request
            if (this.searchFilter === null) {
                let optionValue = this.findOptionValue(this.modelValue)
                this.searchFilter =  optionValue ? this.getOptionLabel(optionValue) : null
            }
        }
    },
}
</script>

<style scoped>

    .processing-icon {
        animation: pulse 0.5s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scaleX(1);
            opacity: 100%;
        }

        80% {
            transform: scaleX(1.2);
            opacity: 30%;
        }
    }

    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }

    @keyframes search-animation {
        0%   { transform: translateY(0) translateX(0); }
        25%   { transform: translateY(-3px) translateX(-3px); }
        100% { transform: translateY(0) translateX(0); }
    }
</style>