<template>
    <div v-on:keydown.ctrl.z="revert" :class="[$attrs.class, {'diff': hasDifference}]" class="revert-input">
        <slot name="label" :changed="hasDifference" :revert="revert" :help-text="helpText">
            <label :class="{'text-blue-600': hasDifference}" class="form-label relative" :aria-required="markAsRequired" :title="helpText">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label ?? 'No Label Set'}}
                <span title="You can also revert/undo your changes by pressing ctrl+z"><icon @click="revert" v-if="hasDifference" name="undo" class="pb-1 inline h-4 w-4 cursor-pointer"></icon></span>
                <span v-if="helpText" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 cursor-pointer focus:outline-none" @click="showAdditionalHelp">
                    <icon name="circle-question" class="fill-current w-3 h-3"></icon>
                </span>
            </label>
        </slot>
        <slot></slot>
        <div v-if="errors" class="form-error">{{ errors }}</div>
    </div>
</template>

<script>
    let uniqueOriginalValueHash = 'yxaxojlxcpmsdhnpwrixjoigbklygpjwrhumsqmimwrxvfzpbkypiwidsrgstnmskgvvkoqsmtnnzpuqjsirugdgbsoumfhqmbpgalknwfepptulejlhrdwgdftvdiohnzcqcswulnrntfapknkxsbjxbmkuzotpptreulphrxzogjoirfgdinqbxpcyvymxjnwyqfznjxyemoavbgeqkdbybwommrlngxxvhbmoffyaxqbjzlfercqbqbwqdmypiexcbcpcrekekwmvbeqkrweloxzjqmbblwelwrsxmjbq';

    import Icon from '@/Shared/Icon.vue';
    import {rrulestr} from "rrule";
    export default {
        inheritAttrs: false,

        props: {
            modelValue: {
                required: true
            },

            // original prop is optional
            // if an original prop is not provided we take a snapshot of the v-model prop but that can be lost
            // when this component is destroyed (ex: v-if will mount/destroy the component and lose it's state)
            // if components are being destroyed/mounted by v-for/v-if/etc then a surefire way is to always provide optional 
            // we typically make a snapshot of our original prop and call it "form" anyway so this should work well
            // <revert-input v-model="form.client_company_id" :original="location.client_company_id">
            original: { 
                default() {
                    return uniqueOriginalValueHash
                }
            },

            // allows us to override the diff function (compare original vs current modelValue)
            diff: {
                type: Function,
                default: null
            },

            changed: {
                type: Boolean,
                default: false
            },

            label: String,

            markAsRequired: {
                type: Boolean,
                default: false
            },

            helpText: {
                type: String,
                default: null
            },

            errors: {
                type: String,
                default: '',
            },
        },

        components: {
            Icon
        },

        emits: ['update:modelValue', 'update:changed'],

        methods: {
            revert() {
                let originalValue = this.original !== uniqueOriginalValueHash ? this.original : this.firstValueSeen
                this.$emit('update:modelValue', originalValue)
                this.$emit('update:changed', false)
            },

            humanReadable(value) {
                if (value && typeof value.rfcstring !== 'undefined') {
                    return rrulestr(value.rfcstring).toText()
                } else if (value && typeof value.amount !== 'undefined' && typeof value.currency !== 'undefined') {
                    return '$' + (value.amount / 100).toFixed(2)
                } else if (value && typeof value['amount'] !== 'undefined') {
                    return '$' + (value['amount'] / 100).toFixed(2)
                } else if (typeof value === 'object') {
                    return JSON.stringify(value)
                }

                return value
            },

            showAdditionalHelp() {
                // I think it'd be cool if when you press this button it pulls up the helpscout Beacon 
                // and searches for a specific 'beacon-search' param or something, for now this does nothing
                // because I need to run this idea by the team when we get more time...
            }
        },

        data() {
            return {
                firstValueSeen: this.modelValue,
            }
        },

        computed: {
            hasDifference() {
                let originalValue = this.original !== uniqueOriginalValueHash ? this.original : this.firstValueSeen

                if (this.diff) {
                    return this.diff(this.modelValue, originalValue)
                }
                
                let difference = this.humanReadable(this.modelValue) != this.humanReadable(originalValue);

                if (this.changed !== difference) {
                    this.$emit('update:changed', difference)
                }

                return difference
            }
        },
    }
</script>

<style>
    .revert-input.diff input,
    .revert-input.diff select {
        background-color: rgb(255 251 235);
    }
</style>