import {format, formatDistanceToNow, isMatch, parse} from 'date-fns'
import {parsePhoneNumber} from 'libphonenumber-js'

function has_search_filters(filters) {
    return Object.keys(filters).filter((key) => {
        let value = filters[key];

        if (value === null) return false;
        if (value === undefined) return false;

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                return value.length;
            } else {
                return Object.keys(value).length;
            }
        }
        return true;
    });
}

const filters = {
    format_money(money, withoutPennies = false) {
        let amount = money.amount;
        let currency = money.currency;

        const CURRENCY_TO_LOCALE = {
            'CAD': 'en-CA',
            'EUR': 'en-EU',
            'USD': 'en-US',
        }

        if (amount !== null && currency !== null && amount !== undefined && currency !== undefined) {
            let locale = CURRENCY_TO_LOCALE[currency];
            amount = parseInt(amount, 10) / 100;

            // If withoutPennies is true, set the fraction digits to 0
            let fractionDigits = withoutPennies ? 0 : 2;

            return amount.toLocaleString(locale, {
                style : 'currency',
                currency : currency,
                maximumFractionDigits: fractionDigits,
                minimumFractionDigits: fractionDigits
            });
        } else {
            return '';
        }
    },

    format_decimal(value, decimals=2) {
        value = parseInt(value, 10) / 100

        return value.toLocaleString('en-US', {style : 'decimal', maximumFractionDigits: decimals, minimumFractionDigits: decimals})
    },

    format_percentage(value, decimals) {
        if (!decimals && decimals !== 0) {
            decimals = 2
        }

        return parseFloat(value).toFixed(decimals) + '%'
    },

    format_phone(value) {
        if (!value) {
            return null;
        }

        let number = value;

        try {
          const phoneNumber = parsePhoneNumber(value);
          number = phoneNumber.formatNational();
        } catch (error) {
          // Not a phone number, non-existent country, etc.
        }

        return number;
    },

    format_date(value) {
        if (value) {
            try {
                return format(parse(value, 'yyyy-MM-dd', new Date), 'MM/dd/yyyy');
            } catch (error) {
                // a date time was likely passed in, so let's handle that below
            }

            return format(parse(value, 'yyyy-MM-dd HH:mm:ss', new Date), 'MM/dd/yyyy');
        } else {
            return null;
        }
    },

    format_date_time(value) {
        if (value) {
            return format(parse(value, 'yyyy-MM-dd HH:mm:ss', new Date), 'MM/dd/yyyy h:mm a');
        } else {
            return null;
        }
    },

    /**
     * Format bytes as human-readable text.
     * 
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use 
     *           binary (IEC), aka powers of 1024.
     * @param dp Number of decimal places to display.
     * 
     * @return Formatted string.
     */
    format_file_size(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;
    
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
    
        const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        
        let u = -1;
        const r = 10**dp;
    
        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    
    
        return bytes.toFixed(dp) + ' ' + units[u];
    },

    diff_for_humans(dateString) {
        if (isMatch(dateString, 'yyyy-MM-dd')) {
            dateString += ' 00:00:00';
        } else if (!isMatch(dateString, 'yyyy-MM-dd HH:mm:ss')) {
            return '';
        }

        let date = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date)

        return formatDistanceToNow(date, { addSuffix: true });
    },

    limit_string(string, limit = 100, end = '...') {
        if (string.length <= limit) {
            return string;
        }

        return string.substring(0, limit) + end;
    },

    capitalize(string) {
        let firstLetter = string.charAt(0).toUpperCase();
        let restOfString = string.slice(1);

        return firstLetter + restOfString;
    },

    roundWithPrecision(number, decimals) {
        if (number === null || number === undefined) {
            return null;
        }

        return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }
}

export { filters, has_search_filters }
